import {FC, useEffect, useState} from 'react'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {debouceTimeout, KTIcon, useDebounce} from '../../../../../_metronic/helpers'
import MajorSelect from '../../../majors/components/MajorSelect'
import CourseSelect from '../../../courses/components/CourseSelect'

type Props = {
  searchTerm: string
  majorId?: number
  courseId?: number
}

const ReferenceListSearchArchive: FC<Props> = ({searchTerm, majorId, courseId}) => {
  const {updateState, state} = useQueryRequest()

  const debouncedSearchTerm = useDebounce(searchTerm, debouceTimeout)

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      updateState({...state, keyword: debouncedSearchTerm})
    }
  }, [debouncedSearchTerm])

  useEffect(() => {
    // @ts-ignore
    updateState({...state, majorId: majorId})
  }, [majorId])

  useEffect(() => {
    // @ts-ignore
    updateState({...state, courseId: courseId})
  }, [courseId])

  return (
    <>
        {/*<div className="d-flex flex-wrap flex-stack">*/}
        {/*  <div className="d-flex">*/}
        {/*    <div className="d-flex align-items-center position-relative me-4">*/}
        {/*      <KTIcon iconName="magnifier" className="fs-3 position-absolute ms-3" />*/}
        {/*      <input*/}
        {/*        type="text"*/}
        {/*        id="kt_filter_search"*/}
        {/*        className="form-control form-control-white form-control w-250px ps-9"*/}
        {/*        placeholder="Search"*/}
        {/*        value={searchTerm}*/}
        {/*        onChange={(e) => setSearchTerm(e.target.value)}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className="d-flex me-4">*/}
        {/*    <MajorSelect*/}
        {/*      isClearable={true}*/}
        {/*      placeHolder="Search majors"*/}
        {/*      isUseFormik={false}*/}
        {/*      onChange={(e) => {*/}
        {/*        if (e) {*/}
        {/*          setMajorId(e.value)*/}
        {/*        } else {*/}
        {/*          setMajorId(undefined)*/}
        {/*        }*/}
        {/*      }}*/}
        {/*      className="w-250px"*/}
        {/*      selectedValue={majorId}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*  <div className="d-flex me-4">*/}
        {/*    <CourseSelect*/}
        {/*      placeHolder="Search courses"*/}
        {/*      isUseFormik={false}*/}
        {/*      onChange={(e) => {*/}
        {/*        if (e) {*/}
        {/*          setCourseId(e.value)*/}
        {/*        } else {*/}
        {/*          setCourseId(undefined)*/}
        {/*        }*/}
        {/*      }}*/}
        {/*      className="w-250px"*/}
        {/*      selectedValue={courseId}*/}
        {/*      isClearable={true}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}
    </>)
}

export {ReferenceListSearchArchive}
