import {Link} from 'react-router-dom'
import {ImagePicker} from '../../../../app/components/ImagePicker'
import {useAuth} from '../../../../app/modules/auth'
import {AvatarImage, getUserImageThumbnailUrl, getUserImageUrl} from '../../../../app/utils/img'
import {KTIcon} from '../../../helpers'
import MyQrModal from '../../../../app/modules/profile/components/MyQrModal'
import {useState} from 'react'
import SurveyNotificationModal from '../../../../app/modules/survey/components/SurveyNotificationModal'
import RoleEnum from '../../../../app/enums/RoleEnum'
import {getShowSurveyNotif} from '../../../../app/modules/survey/core/_requests'
import {logoutAuth} from '../../../../app/modules/auth/core/_requests'

function SidebarUserInfo() {
  const {currentUser, logout} = useAuth()
  const [showQr, setShowQr] = useState(false)
  const [showSurveyNotif, setShowSurveyNotif] = useState(false)

  const checkShowQr = () => {
    if (currentUser?.roleId == RoleEnum.Student) {
      getShowSurveyNotif().then((resp) => {
        if (resp.data) {
          setShowSurveyNotif(resp.data ?? false)
        } else {
          setShowQr(true)
        }
      })
    } else {
      setShowQr(true)
    }
  }

  const handleLogout = async () => {
    var response = await logoutAuth()
    if (response.code === '0000') {
      logout()
    }
  }

  return (
    <div className='p-5'>
      <div className='card sidebar-userinfo__container'>
        <div className='sidebar-userinfo__container-top'>
          <div className='sidebar-userinfo__icon'>
            <span>
              <ImagePicker
                className='w-100'
                initialValue={`${getUserImageThumbnailUrl(currentUser?.id)}`}
                showEdit={false}
              />
            </span>
          </div>
          <div className='sidebar-userinfo__info animation'>
            <div className='sidebar-userinfo__role'>{currentUser?.name}</div>
          </div>
        </div>
        <div className='sidebar-userinfo__blockinfo'>
          {currentUser?.roleName === 'Student' && (
            <div className='mt-4'>
              <div className='sidebar-userinfo__major w-100 text-center'>
                {currentUser?.degreeName} - {currentUser?.majorName}
              </div>
            </div>
          )}
          {(currentUser?.roleName === 'Administrator' || currentUser?.roleName === 'Lecturer') && (
            <>
              <div className='sidebar-userinfo__major'>{currentUser?.roleName}</div>
            </>
          )}
        </div>
        <div className='sidebar-userinfo__action'>
          <div className='sidebar-userinfo__action-item'>
            <Link to={'/profile'}>
              <button
                type='button'
                className='btn btn-default btn-rounded btn-sm w-100 text-white'
                style={{backgroundColor: 'var(--dark-primary-color)'}}
              >
                <i className='fas fa-user-alt text-white'></i> Profile
              </button>
            </Link>
          </div>
          {(currentUser?.roleName === 'Lecturer' || currentUser?.roleName === 'Student') && (
            <div className='sidebar-userinfo__action-item'>
              <button
                onClick={() => checkShowQr()}
                type='button'
                className='btn btn-default btn-rounded btn-sm w-100 text-white'
                style={{backgroundColor: 'var(--dark-primary-color)'}}
              >
                <i className='fas fa-qrcode  text-white'></i> QR
              </button>
            </div>
          )}
          <div className='sidebar-userinfo__action-item'>
            <button
              onClick={handleLogout}
              type='button'
              className='btn btn-default btn-rounded btn-sm w-100 text-white'
              style={{backgroundColor: 'var(--dark-primary-color)'}}
            >
              <i className='fas fa-sign-out-alt text-white'></i> Logout
            </button>
          </div>
        </div>
      </div>
      {showQr && <MyQrModal show={showQr} onClose={() => setShowQr(false)} />}
      <SurveyNotificationModal show={showSurveyNotif} onClose={() => setShowSurveyNotif(false)} />
    </div>
  )
}

export default SidebarUserInfo
