// CustomSelect.js
import React, {FC, useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import Select from 'react-select'
import clsx from 'clsx'
import {stringifyRequestQuery, useDebounce} from '../../../../_metronic/helpers'
import {getCourseSelect} from '../course-list/core/_requests'

interface Props {
  field?: any
  form?: any
  className?: string
  defaultOption?: any
  initialOptions?: any[]
  disabled?: boolean
  isUseFormik?: boolean
  onChange?(selected: any): void
  selectedValue?: number | null
  placeHolder?: string
  isClearable?: boolean
  majorId?: number
}

const CourseSelect: FC<Props> = ({
  isUseFormik = true,
  field,
  form,
  className = '',
  defaultOption,
  initialOptions = [],
  disabled = false,
  onChange,
  selectedValue,
  placeHolder="Type to search...",
  isClearable = false,
  majorId,
  ...rest
}) => {
  const [keyword, setKeyword] = useState<string | undefined>(undefined)
  const debouncedSearchTerm = useDebounce(keyword, 150)

  const {isLoading, data, refetch} = useQuery(
    ['course-select', majorId],
    async () => {
      try {
        const response = await getCourseSelect(
          stringifyRequestQuery({
            keyword: debouncedSearchTerm,
            filter: {
              majorId: majorId || null,
            }
          })
        )
        // @ts-ignore
        return response.data?.records
      } catch (ex) {
        console.log(ex)
      }
    },
    {enabled: true}
  )

  useEffect(() => {
    if (majorId) {
      handleChange(undefined)
    }
  }, [majorId])

  const handleChange = (selectedOption) => {
    if (isUseFormik) {
      form.setFieldValue(field.name, selectedOption.value)
    }

    if (onChange) {
      onChange(selectedOption)
    }
  }

  const options =
    keyword === undefined
      ? initialOptions
      : data?.some((item) => item.id !== null)
      ? data?.map((option) => ({
          value: option.id,
          label: `${option.degreeName} - ${option.subjectName} (${option.semesterName})`,
          degreeId: option.degreeId,
        }))
      : []

  const handleInputChange = (value) => {
    setKeyword(value)
  }

  useEffect(() => {
    if (debouncedSearchTerm === undefined) return
    refetch()
  }, [debouncedSearchTerm])

  const formatLabel = (option) => {
    return (
      <article className='custom-option text-wrap' style={{lineHeight: 1.1}}>
        {option.label}
      </article>
    )
  }

  return isUseFormik ? (
    <Select
      isClearable={isClearable}
      placeholder={placeHolder}
      {...rest}
      name={field.name}
      isLoading={isLoading}
      options={options}
      onChange={handleChange}
      value={options.find((option) => option.value === field.value)} // Set the initial selected value
      classNamePrefix='react-select'
      className={clsx(
        'react-select',
        className,
        {'is-invalid': !isUseFormik ? false : form.touched[field.name] && form.errors[field.name]},
        {'is-valid': !isUseFormik ? false : form.touched[field.name] && !form.errors[field.name]}
      )}
      onInputChange={handleInputChange}
      onMenuOpen={() => handleInputChange('')}
      styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
      menuPortalTarget={document.body}
      menuShouldScrollIntoView={false}
      isDisabled={disabled}
      formatOptionLabel={formatLabel}
    />
  ) : (
    <Select
      placeholder={placeHolder}
      {...rest}
      isClearable={isClearable}
      isLoading={isLoading}
      options={options}
      onChange={handleChange}
      value={selectedValue ? options.find((option) => option.value === selectedValue) : null}
      classNamePrefix='react-select'
      className={clsx(
        'react-select',
        className,
        {'is-invalid': !isUseFormik ? false : form.touched[field.name] && form.errors[field.name]},
        {'is-valid': !isUseFormik ? false : form.touched[field.name] && !form.errors[field.name]}
      )}
      onInputChange={handleInputChange}
      onMenuOpen={() => handleInputChange('')}
      styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
      menuPortalTarget={document.body}
      menuShouldScrollIntoView={false}
      isDisabled={disabled}
      formatOptionLabel={formatLabel}
    />
  )
}

export default CourseSelect
