import {useQuery} from 'react-query'
import {getUrlWithToken} from './core/_request'
import BlockLoading from '../../components/BlockLoading'

const ArticlePage: React.FC = () => {
  const {data, isError, isLoading, error} = useQuery(
    ['get-url-wp-with-token'],
    () => getUrlWithToken(),
    {
      select: (d) => d.data,
    }
  )

  if (isError && !isLoading) {
    // @ts-ignore
    return <p>{error.message}</p>
  }

  return isLoading ? (
    <BlockLoading />
  ) : (
    <iframe src={data} title='article wordpress' width={'100%'} height={800} allowFullScreen />
  )
}

export default ArticlePage
