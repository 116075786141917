import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../../_metronic/helpers'
import { Room, RoomQueryResponse } from './_models'

const API_URL = process.env.REACT_APP_BASE_API_URL
const ROOM_URL = `${API_URL}/rooms`
const GET_ROOM_URL = `${API_URL}/rooms`

const getRoom = (query: string): Promise<RoomQueryResponse> => {
  return axios
    .get(`${GET_ROOM_URL}?${query}`)
    .then((d: AxiosResponse<RoomQueryResponse>) => d.data)
}

const getRoomSelect = (query: string): Promise<RoomQueryResponse> => {
  return axios
    .get(`${GET_ROOM_URL}/select?${query}`)
    .then((d: AxiosResponse<RoomQueryResponse>) => d.data)
}

const getRoomById = (id: ID): Promise<Room | undefined> => {
  return axios
    .get(`${ROOM_URL}/${id}`)
    .then((response: AxiosResponse<Response<Room>>) => response.data)
    .then((response: Response<Room>) => response.data)
}

const createRoom = (major: Room): Promise<Room | undefined> => {
  return axios
    .post(ROOM_URL, major)
    .then((response: AxiosResponse<Response<Room>>) => response.data)
    .then((response: Response<Room>) => response.data)
}

const updateRoom = (major: Room): Promise<Room | undefined> => {
  return axios
    .put(`${ROOM_URL}/${major.id}`, major)
    .then((response: AxiosResponse<Response<Room>>) => response.data)
    .then((response: Response<Room>) => response.data)
}

const deleteRoom = (majorId: ID): Promise<void> => {
  return axios.delete(`${ROOM_URL}/${majorId}`).then(() => { })
}

const deleteSelectedRoom = (majorIds: Array<ID>): Promise<void> => {
  const requests = majorIds.map((id) => axios.delete(`${ROOM_URL}/${id}`))
  return axios.all(requests).then(() => { })
}

export { getRoom, deleteRoom, deleteSelectedRoom, getRoomById, createRoom, updateRoom, getRoomSelect }
