import {useEffect, useMemo, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {courseColumns} from './columns/_columns'
import {Course} from '../core/_models'
import {CourseListLoading} from '../components/loading/CourseListLoading'
import {CourseListPagination} from '../components/pagination/CourseListPagination'
import {KTCardBody} from '../../../../../_metronic/helpers'
import {CourseClassModal} from '../course-class-modal/CourseClassModal'

const CourseTable = () => {
  const course = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => course, [course])
  const columns = useMemo(() => courseColumns, [])
  const [selectedId, setSelectedId] = useState<number | null>(null)

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data: data?.records ?? [],
  })

  const hashManage = (hash: string) => {
    if (!hash) {
      setSelectedId(null)
      return
    }
    try {
      const h = +hash.replace('#', '')
      {
        if (h > 0) setSelectedId(h)
      }
    } catch (e: any) {}
  }

  useEffect(() => {
    const handleHashChange = () => {
      hashManage(window.location.hash)
    }

    hashManage(window.location.hash)
    window.addEventListener('hashchange', handleHashChange)
    return () => {
      window.removeEventListener('hashchange', handleHashChange)
    }
  }, [hashManage])

  const removeHashFromURL = () => {
    if (window.location.hash) {
      // Construct the new URL without the hash
      const newUrl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        window.location.search

      // Update the URL using replaceState
      window.history.replaceState(null, document.title, newUrl)
    }
  }

  return (
    <>
      <CourseClassModal
        name={''}
        id={selectedId}
        show={selectedId !== null}
        handleClose={() => {
          removeHashFromURL()
          hashManage('')
        }}
      />
      <KTCardBody className='py-4'>
        <div className='table-responsive'>
          <table
            id='kt_table_course'
            className='table table-striped table-rounded dataTable align-middle table-row-dashed no-footer gs-7 fs-6 gy-5'
            {...getTableProps()}
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<Course>) => (
                  <CustomHeaderColumn key={column.id} column={column} />
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<Course>, i) => {
                  prepareRow(row)
                  return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                })
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      {isLoading ? '' : ' No matching records found'}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <CourseListPagination
          totalPages={data.totalPages}
          currentPage={data?.pageNumber ?? 1}
          onPageChange={() => console.log('a')}
        />
        {isLoading && <CourseListLoading />}
      </KTCardBody>
    </>
  )
}

export {CourseTable}
