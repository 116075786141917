import {KTIcon} from '../../_metronic/helpers'
import {Children, cloneElement, FC, isValidElement} from 'react'

const LoadMore = ({isLoading}) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '10px',
      backgroundColor: '#f9f9f9',
    }}
  >
    {!isLoading ? <>
      <KTIcon iconName="arrow-down" className="me-2" />
      Scroll to Load More
    </> : <>
      <span className="spinner-border spinner-border-sm align-middle me-2"></span> Loading...
    </>}
  </div>
)

type Props = {
  hasMore: boolean,
  children: React.ReactNode
  isLoading: boolean
}

const SelectWithMoreWrapper: FC<Props> = ({hasMore, isLoading, children}) => {
  const formatLabel = (option, format) => {
    return (
      !option.isLoadMore ?
        (format ? format(option) : option.label) :
        <LoadMore isLoading={isLoading} />
    )
  }

  const getExtendedOptions = (options) => (
    hasMore && options.length > 0 ? [...options, {
      value: 'load-more',
      label: 'Scroll to Load More',
      isLoadMore: true,
    }] : options
  )

  const modifiedChildren = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(
        child,
        {
          formatOptionLabel: (data) => formatLabel(data, child.props.formatOptionLabel),
          options: getExtendedOptions(child.props.options || []),
          onChange: (option) => {
            if (!option?.isLoadMore && child.props.onChange) {
              child.props.onChange(option)
            }
          },
          captureMenuScroll: true,
        },
        child.props.children,
      )
    }

    return child
  })

  return <>{modifiedChildren}</>
}

export {LoadMore, SelectWithMoreWrapper}