import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useLocation} from 'react-router-dom'
import * as Yup from 'yup'
import {isNotEmpty} from '../../../../../_metronic/helpers'
import RichEditor from '../../../../components/RichEditor'
import {MenuModel, getMenuAccess, useAuth} from '../../../auth'
import {CourseListLoading} from '../../course-list/components/loading/CourseListLoading'
import {CourseQuiz, initialCourseQuiz} from '../../course-list/core/_models'
import {createCourseQuiz, updateCourseQuiz} from '../../course-list/core/_requests'
import LoadingButtonWrapper from '../../../../components/LoadingButtonWrapper'
import {showError} from '../../../../utils/toast'

type Props = {
  show: boolean
  quiz: CourseQuiz | null
  id: number
  onClose: any
}

let yupValidation = {
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(250, 'Maximum 250 symbols')
    .required('Name is required'),
  duration: Yup.number().min(0, 'Min 0').required('Duration is required'),
  description: Yup.mixed().required('Description is required'),
  examTypeId: Yup.number().when('isExam', {
    is: (val) => {
      return val
    },
    then: () => Yup.number().required('Type Exam is required'),
  }),
}

const addcourseQuizSchema = Yup.object().shape({
  ...yupValidation,
})
const editcourseQuizSchema = Yup.object().shape(yupValidation)

const CourseFormQuizModal: FC<Props> = ({id, show, quiz, onClose}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [courseQuizForEdit, setCourseQuizForEdit] = useState<CourseQuiz>({
    ...initialCourseQuiz,
  })
  const {pathname} = useLocation()
  const {currentMenu} = useAuth()
  const [menuAccess, setMenuAccess] = useState<MenuModel | undefined>(undefined)
  useEffect(() => {
    setMenuAccess(getMenuAccess(currentMenu ?? [], pathname))
  }, [currentMenu])

  useEffect(() => {
    if (show) {
      formik.resetForm()
      setCourseQuizForEdit({
        id: quiz?.id || initialCourseQuiz.id,
        name: quiz?.name || initialCourseQuiz.name,
        description: quiz?.description || initialCourseQuiz.description,
        duration: quiz?.duration || initialCourseQuiz.duration,
        isExam: quiz?.isExam != undefined ? quiz.isExam : initialCourseQuiz.isExam,
        autoPublish:
          quiz?.autoPublish != undefined ? quiz.autoPublish : initialCourseQuiz.autoPublish,
        autoSubmit: quiz?.autoSubmit != undefined ? quiz.autoSubmit : initialCourseQuiz.autoSubmit,
        outputNumber: quiz?.outputNumber || initialCourseQuiz.outputNumber,
        randomize: quiz?.randomize != undefined ? quiz.randomize : initialCourseQuiz.randomize,
        examTypeId: quiz?.examTypeId != undefined ? quiz.examTypeId : initialCourseQuiz.examTypeId,
        isCreateMeeting:
          quiz?.isCreateMeeting != undefined
            ? quiz.isCreateMeeting
            : initialCourseQuiz.isCreateMeeting,
        isDurationOnStart:
          quiz?.isDurationOnStart != undefined
            ? quiz.isDurationOnStart
            : initialCourseQuiz.isDurationOnStart,
        useCamera: quiz?.useCamera != undefined ? quiz.useCamera : initialCourseQuiz.useCamera,
        maxFraud: quiz?.maxFraud != undefined ? quiz.maxFraud : initialCourseQuiz.maxFraud,
        isPrevButtonDisabled:
          quiz?.isPrevButtonDisabled != undefined
            ? quiz.isPrevButtonDisabled
            : initialCourseQuiz.isPrevButtonDisabled,
        useTimerPerQuestion:
          quiz?.useTimerPerQuestion != undefined
            ? quiz.useTimerPerQuestion
            : initialCourseQuiz.useTimerPerQuestion,
      })
    }
  }, [show])

  const formik = useFormik({
    initialValues: courseQuizForEdit,
    enableReinitialize: true,
    validationSchema: isNotEmpty(quiz?.id) ? editcourseQuizSchema : addcourseQuizSchema,
    onSubmit: async (values, {setSubmitting, setStatus}) => {
      setStatus(null)
      const models = {...values, courseId: id}
      if (models.isPrevButtonDisabled === false && models.useTimerPerQuestion === true) {
        showError(
          'To use the "Timer per Question" feature, please also enable the "Disable Previous Button" feature.'
        )

        return
      }
      setSubmitting(true)
      let result: any
      try {
        if (isNotEmpty(quiz?.id)) {
          result = await updateCourseQuiz(models)
        } else {
          result = await createCourseQuiz(models)
        }
        if (result) {
          onClose(!isNotEmpty(quiz?.id))
          setSubmitting(true)
        } else {
          setStatus('Error')
        }
      } catch (ex: any) {
        console.error(ex)
        setStatus(ex.message)
      } finally {
      }
    },
  })

  const handleClose = () => {
    onClose()
  }

  return (
    <Modal show={show} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Add Quiz</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='p-4'>
          <form className='form' onSubmit={formik.handleSubmit} noValidate>
            <fieldset disabled={!menuAccess?.canCreate}>
              <div className='d-flex flex-column'>
                {formik.status ? (
                  <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                  </div>
                ) : (
                  ''
                )}

                <div className='row mb-7'>
                  <div className='col-12 col-lg-12'>
                    <label className='required fw-bold fs-6 mb-2'>Name</label>
                    <input
                      placeholder='Name'
                      {...formik.getFieldProps('name')}
                      type='text'
                      name='name'
                      className={clsx(
                        'form-control mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.name && formik.errors.name},
                        {
                          'is-valid': formik.touched.name && !formik.errors.name,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting || isLoading}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.name}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row mb-7'>
                  <div className='col-12 col-lg-4'>
                    <label className='required fw-bold fs-6 mb-2'>
                      Duration <small>(minutes)</small>
                    </label>
                    <input
                      placeholder='Duration'
                      {...formik.getFieldProps('duration')}
                      type='number'
                      name='duration'
                      className={clsx(
                        'form-control mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.duration && formik.errors.duration},
                        {
                          'is-valid': formik.touched.duration && !formik.errors.duration,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting || isLoading}
                    />
                    {formik.touched.duration && formik.errors.duration && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.duration}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-12 col-lg-4'>
                    <label className='fw-bold fs-6 mb-2'>Output Number</label>
                    <input
                      placeholder='Output Number'
                      {...formik.getFieldProps('outputNumber')}
                      type='number'
                      name='outputNumber'
                      className={clsx(
                        'form-control mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.outputNumber && formik.errors.outputNumber},
                        {
                          'is-valid': formik.touched.outputNumber && !formik.errors.outputNumber,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting || isLoading}
                    />
                    {formik.touched.outputNumber && formik.errors.outputNumber && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.outputNumber}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-12 col-lg-4'>
                    <label className='fw-bold fs-6 mb-2'>Max Fraud</label>
                    <input
                      placeholder='Max Fraud'
                      {...formik.getFieldProps('maxFraud')}
                      type='number'
                      name='maxFraud'
                      className={clsx(
                        'form-control mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.maxFraud && formik.errors.maxFraud},
                        {
                          'is-valid': formik.touched.maxFraud && !formik.errors.maxFraud,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting || isLoading}
                    />
                    {formik.touched.maxFraud && formik.errors.maxFraud && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.maxFraud}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-4'>
                  <div className='col-12'>
                    <div className='fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>Description</label>
                      <RichEditor
                        onChange={(val) => formik.setFieldValue('description', val)}
                        value={formik.values.description}
                        disabled={!menuAccess?.canCreate}
                      />

                      {formik.touched.description && formik.errors.description && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.description}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-4'>
                    <div className='row'>
                      <div className='col-12'>
                        <div className='fv-row mb-7'>
                          <div className='form-check form-check-custom form-check-solid mb-4 fw-bold'>
                            <input
                              id='autoPublish'
                              className='form-check-input'
                              type='checkbox'
                              {...formik.getFieldProps('autoPublish')}
                              checked={formik.values.autoPublish}
                            />
                            <label htmlFor='autoPublish' className='ms-2 cursor-pointer'>
                              Auto Publish
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='fv-row mb-7'>
                          <div className='form-check form-check-custom form-check-solid mb-4 fw-bold'>
                            <input
                              id='autoSubmit'
                              className='form-check-input'
                              type='checkbox'
                              {...formik.getFieldProps('autoSubmit')}
                              checked={formik.values.autoSubmit}
                            />
                            <label htmlFor='autoSubmit' className='ms-2 cursor-pointer'>
                              Auto Submit
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='fv-row mb-7'>
                          <div className='form-check form-check-custom form-check-solid mb-4 fw-bold'>
                            <input
                              id='randomize'
                              className='form-check-input'
                              type='checkbox'
                              {...formik.getFieldProps('randomize')}
                              checked={formik.values.randomize}
                            />
                            <label htmlFor='randomize' className='ms-2 cursor-pointer'>
                              Randomize
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4'>
                    <div className='row'>
                      <div className='fv-row'>
                        <div className='form-check form-check-custom form-check-solid mb-4 fw-bold'>
                          <input
                            id='isExam'
                            className='form-check-input'
                            type='checkbox'
                            {...formik.getFieldProps('isExam')}
                            checked={formik.values.isExam}
                          />
                          <label htmlFor='isExam' className='ms-2 cursor-pointer'>
                            Is Exam
                          </label>
                        </div>
                      </div>

                      <div className='ms-8 mb-3'>
                        <div className='d-flex form-check me-4 mb-3'>
                          <input
                            {...formik.getFieldProps('examTypeId')}
                            type='radio'
                            name='examTypeId'
                            className='form-check-input'
                            value={1}
                            checked={formik.values.examTypeId == 1}
                            disabled={formik.isSubmitting || isLoading || !formik.values.isExam}
                            id='midExam'
                          />
                          <label className='form-check-label ms-2' htmlFor='midExam'>
                            Mid Exam
                          </label>
                        </div>
                        <div className='d-flex form-check me-4'>
                          <input
                            {...formik.getFieldProps('examTypeId')}
                            type='radio'
                            name='examTypeId'
                            className='form-check-input'
                            value={2}
                            checked={formik.values.examTypeId == 2}
                            disabled={formik.isSubmitting || isLoading || !formik.values.isExam}
                            id='finalExam'
                          />
                          <label className='form-check-label ms-2' htmlFor='finalExam'>
                            Final Exam
                          </label>
                        </div>

                        {formik.touched.examTypeId && formik.errors.examTypeId && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.examTypeId}</span>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className='fv-row'>
                        <div className='form-check form-check-custom form-check-solid mb-4 fw-bold'>
                          <input
                            id='isCreateMeeting'
                            className='form-check-input'
                            type='checkbox'
                            {...formik.getFieldProps('isCreateMeeting')}
                            checked={formik.values.isCreateMeeting}
                          />
                          <label htmlFor='isCreateMeeting' className='ms-2 cursor-pointer'>
                            Create Meeting
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4'>
                    <div className='row'>
                      <div className='fv-row'>
                        <div className='form-check form-check-custom form-check-solid mb-4 fw-bold'>
                          <input
                            id='isDurationOnStart'
                            className='form-check-input'
                            type='checkbox'
                            {...formik.getFieldProps('isDurationOnStart')}
                            checked={formik.values.isDurationOnStart}
                          />
                          <label htmlFor='isDurationOnStart' className='ms-2 cursor-pointer'>
                            Duration On Start
                          </label>
                        </div>
                      </div>

                      <div className='fv-row'>
                        <div className='form-check form-check-custom form-check-solid mb-4 fw-bold'>
                          <input
                            id='useCamera'
                            className='form-check-input'
                            type='checkbox'
                            {...formik.getFieldProps('useCamera')}
                            checked={formik.values.useCamera}
                          />
                          <label htmlFor='useCamera' className='ms-2 cursor-pointer'>
                            Use Camera
                          </label>
                        </div>
                      </div>

                      <div className='fv-row'>
                        <div className='form-check form-check-custom form-check-solid mb-4 fw-bold'>
                          <input
                            id='isPrevButtonDisabled'
                            className='form-check-input'
                            type='checkbox'
                            {...formik.getFieldProps('isPrevButtonDisabled')}
                            checked={formik.values.isPrevButtonDisabled}
                          />
                          <label htmlFor='isPrevButtonDisabled' className='ms-2 cursor-pointer'>
                            Disable Prev Button
                          </label>
                        </div>
                      </div>

                      <div className='fv-row'>
                        <div className='form-check form-check-custom form-check-solid mb-4 fw-bold'>
                          <input
                            id='useTimerPerQuestion'
                            className='form-check-input'
                            type='checkbox'
                            {...formik.getFieldProps('useTimerPerQuestion')}
                            checked={formik.values.useTimerPerQuestion}
                          />
                          <label htmlFor='useTimerPerQuestion' className='ms-2 cursor-pointer'>
                            Use Timer Per Question
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {menuAccess?.canCreate && (
                <div className='text-end pt-15'>
                  <LoadingButtonWrapper isLoading={formik.isSubmitting || isLoading}>
                    <button
                      type='submit'
                      className='btn btn-warning'
                      disabled={formik.isSubmitting || isLoading}
                    >
                      <span className='indicator-label'>Submit</span>
                    </button>
                  </LoadingButtonWrapper>
                </div>
              )}
              {/* end::Actions */}
            </fieldset>
          </form>
          {(formik.isSubmitting || isLoading) && <CourseListLoading />}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export {CourseFormQuizModal}
