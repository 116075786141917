/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {ID, KTIcon, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {Confirm} from '../../../../../components/Confirm'
import {DownloadRequestStatus} from '../../../../download-request/download-request-list/core/_models'
import clsx from 'clsx'
import {OverlayTooltip} from '../../../../../components/OverlayTooltip'

type Props = {
  allowRequest: boolean
  allowDownload: boolean
  statusId: number
}

const ReferenceStatusCell: FC<Props> = ({allowRequest, allowDownload, statusId}) => {
  const [icon, setIcon] = useState('')
  const [color, setColor] = useState('')
  const [tooltip, setTooltip] = useState('')

  useEffect(() => {
    if (allowRequest) {
      setIcon('')
      setColor('')
      setTooltip('')
      return
    }

    if (statusId === DownloadRequestStatus.requested) {
      setIcon('watch')
      setColor('info')
      setTooltip('File on Request')
      console.log(tooltip)
      return
    }

    if (allowDownload) {
      setIcon('check-circle')
      setColor('success')
      setTooltip('Ready to Download')
      console.log(tooltip)
      return
    }
  }, [allowRequest, allowDownload, statusId])

  return (
    <>
      {icon && (
        <div className='text-center'>
          <OverlayTooltip title={tooltip}>
            <span>
              <KTIcon iconName={icon} className={`text-${color} fs-2`}></KTIcon>
            </span>
          </OverlayTooltip>
        </div>
      )}
    </>
  )
}

export {ReferenceStatusCell}
