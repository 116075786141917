import { useEffect, useMemo, useState } from 'react'
import { KTIcon, stringifyRequestQuery } from '../../../../../../../_metronic/helpers'
import { downloadFile } from '../../../../../../utils/file'
import { showError } from '../../../../../../utils/toast'
import { useQueryRequest } from '../../core/QueryRequestProvider'

const StudentAttendanceSummaryListToolbar = () => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const [loadingExcel, setLoadingExcel] = useState<boolean>(false)
  const [loadingPdf, setLoadingPdf] = useState<boolean>(false)
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  const url = 'reports/students/student-attendance-summary'

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const excel = async () => {
    try {
      setLoadingExcel(true)
      await downloadFile(
        `${url}-excel?${query}`,
        `Report Student Attendance Summary.xlsx`
      )
    } catch (err: any) {
      showError(err.message)
    } finally {
      setLoadingExcel(false)
    }
  }

  const pdf = async () => {
    try {
      setLoadingPdf(true)
      await downloadFile(
        `${url}-pdf?${query}`,
        `Report Student Attendance Summary.pdf`
      )
    } catch (err: any) {
      showError(err.message)
    } finally {
      setLoadingPdf(false)
    }
  }

  return (
    <div className='d-flex justify-content-end' data-kt-student-table-toolbar='base'>
      <button
        type='button'
        className='btn btn-light-success me-3'
        onClick={() => excel()}
        disabled={loadingExcel}
      >
        {!loadingExcel && (
          <>
            <KTIcon iconName='exit-up' className='fs-2' />
            Export
          </>
        )}
        {loadingExcel && (
          <span className='indicator-progress' style={{display: 'block'}}>
            Please wait...
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>

      <button
        type='button'
        className='btn btn-light-danger me-3'
        onClick={() => pdf()}
        disabled={loadingPdf}
      >
        {!loadingPdf && (
          <>
            <KTIcon iconName='file' className='fs-2' />
            PDF
          </>
        )}
        {loadingPdf && (
          <span className='indicator-progress' style={{display: 'block'}}>
            Please wait...
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>
    </div>
  )
}

export { StudentAttendanceSummaryListToolbar }

