const setSavedState = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value))
}

const getSavedState = (key: string): any => {
  const item = localStorage.getItem(key)

  return item ? JSON.parse(item) : null
}

export {setSavedState, getSavedState}
