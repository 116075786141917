import {ITimestamp} from './ITimestamp'
import {ID} from '../../_metronic/helpers'

export interface IClassMeet extends ITimestamp {
  id?: number | undefined
  typeId?: number | undefined
  classId?: number
  startDate?: string
  status?: number
  classMeetAttendances?: IClassMeetAttendance[]
  classLecturerAttendances?: IClassLecturerAttendance[]
  isLoading?: boolean
  attendCount?: number
  absentCount?: number
  description?: string
  roomName?: string
  roomId?: number | null
  duration?: number
  link?: string | undefined
  typeName?: string | undefined
  zoomId?: ID
  // allowUpdate?: boolean | undefined
  // allowDelete?: boolean | undefined
  passCode?: string | undefined
  isPassCodeVisible?: boolean | undefined
  isSyncAttendance?: boolean | undefined
  summary?: string
  endDate?: string
  actualStartTime?: string
  actualEndTime?: string
  lecturerId?: number
  categoryId?: number | undefined
  categoryName?: string | undefined
  havePendingSurvey?: boolean | undefined
  lastSendEmail?: string
  allowAttendance?: boolean | undefined
  isInputLink?: boolean | undefined
  isNeedSurvey: boolean
  isBlastEmail: boolean
  classLecturerId?: number
  lecturerName?: string
  classLecturers?: any[]
  isCreatedBySystem?: boolean | undefined
}

export interface IClassMeetAttendance {
  id: number
  classMeetId?: number
  statusId?: number
  classStudentId?: number
  studentName?: string
  studentNim?: string
  studentMajorId?: number
  joinTime?: string
  surveyEmailDate?: string
  surveyDate?: string
  statusTypeId?: number
  statusTypeNote?: string
}

export const initialClassMeet: IClassMeet = {
  id: undefined,
  classId: undefined,
  startDate: '',
  typeId: 1,
  roomId: undefined,
  description: '',
  duration: 0,
  roomName: '',
  isLoading: false,
  attendCount: 0,
  absentCount: 0,
  lecturerId: 0,
  categoryId: 1,
  isInputLink: false,
  link: '',
  zoomId: '',
  isNeedSurvey: true,
  isBlastEmail: false,
}

export interface IClassLecturerAttendance {
  id: number
  classMeetId?: number
  lecturerId?: number
  lecturerName?: string
  lecturerNim?: string
  lecturerMajorId?: number
  attendTime?: string
}

export interface IClassMeetLecturer {
  primaryLecturerId?: number | null
  primaryLecturerName?: string | null
  primaryLecturerNIP?: string | null
  primaryLecturerAttendanceAt?: any
  secondaryLecturerId?: number | null
  secondaryLecturerName?: string | null
  secondaryLecturerNIP?: string | null
  secondaryLecturerAttendanceAt?: any
}
