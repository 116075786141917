import {formatDateTime, formatTime} from '../../../utils/date'

const SurveyClassInfo = ({data}) => {
  return (
    data && (
      <>
        <div className='row border-bottom pb-8 mb-8'>
          <div className='col-12 col-lg-6 mb-8 mb-lg-0'>
            <h4>
              {data?.courseName} ({data?.className})
            </h4>
            <div></div>
            <div>
              {formatDateTime(data?.startDate)}-{formatTime(data?.endDate)}
            </div>
          </div>
          <div className='col-12 col-lg-6'>
            <h4>Lecturers</h4>
            <div>{data.lecturers.map((m) => m.name)}</div>
          </div>
        </div>
      </>
    )
  )
}

export default SurveyClassInfo
