import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react'
import {useInfiniteQuery} from 'react-query'
import Select from 'react-select'
import clsx from 'clsx'
import {stringifyRequestQuery, useDebounce} from '../../../../_metronic/helpers'
import {ISubject} from '../../../models/ISubject'
import {getSubjectSelect} from '../../classes/core/_request'
import {SelectWithMoreWrapper} from '../../../components/LoadMore'
import {ISemester} from '../../../models/ISemester'

interface SubjectSelectProps {
  selected?: ISubject
  setSelected?: Dispatch<SetStateAction<ISubject | undefined>>
  className?: string
  defaultOption?: any
  initialOptions?: any[]
  isClearable?: boolean
  portal?: any
  semester?: ISemester
  degreeId?: number | null
  refetchCallback?: (refetch: (newDegreeId?: number | null) => void) => void // Updated refetchCallback type
}

const SubjectSelect: FC<SubjectSelectProps> = ({
  selected,
  isClearable = false,
  className = '',
  defaultOption,
  initialOptions = [],
  portal,
  setSelected,
  semester,
  degreeId,
  refetchCallback,
  ...rest
}) => {
  const [keyword, setKeyword] = useState<string | undefined>(undefined)
  const debouncedSearchTerm = useDebounce(keyword, 150)
  const [options, setOptions] = useState<any[]>([...initialOptions])
  const [currentDegreeId, setCurrentDegreeId] = useState(degreeId) // Store the degreeId

  const fetchData = async ({pageParam = 1}) => {
    try {
      const response = await getSubjectSelect(
        stringifyRequestQuery({
          keyword: debouncedSearchTerm,
          pageSize: 10,
          pageNumber: pageParam,
          filter: {
            semesterId: semester?.id || null,
            degreeId: currentDegreeId, // Use the stored degreeId
          },
        })
      )
      return response.data
    } catch (ex) {
      console.log(ex)
      return undefined
    }
  }

  const {isLoading, data, refetch, fetchNextPage, hasNextPage, isFetching} = useInfiniteQuery(
    ['subject-select', semester?.id, currentDegreeId], // Include currentDegreeId in the query key
    fetchData,
    {
      enabled: true,
      getNextPageParam: (lastPage) => {
        if (!lastPage?.totalPages) return undefined

        const hasNext = lastPage.totalPages > lastPage.pageNumber
        if (hasNext) {
          return lastPage.pageNumber + 1
        } else {
          return undefined
        }
      },
      cacheTime: 0,
    }
  )

  useEffect(() => {
    if (refetchCallback) {
      refetchCallback((newDegreeId) => {
        setCurrentDegreeId(newDegreeId)
        refetch()
      })
    }
  }, [refetch])

  useEffect(() => {
    console.log(semester)

    if (semester) {
      handleChange(undefined)
      console.log(semester)
    }
  }, [semester])

  const handleChange = (selectedOption) => {
    if (setSelected) {
      if (selectedOption) {
        let selected = {
          id: selectedOption.value,
          name: selectedOption.label,
        }
        setSelected(selected)
      } else {
        setSelected(undefined)
      }
    }
  }

  useEffect(() => {
    let list = data?.pages?.flatMap((q) => q?.records) || []
    list = list.filter((q) => q !== undefined)
    const result = list.map((q) => {
      return {
        value: q?.id,
        label: `${q?.degreeName} - ${q?.name}`,
      }
    })

    setOptions(result)
  }, [data])

  const handleInputChange = (value) => {
    setKeyword(value)
  }

  useEffect(() => {
    if (debouncedSearchTerm === undefined) return
    refetch()
  }, [debouncedSearchTerm])

  const handleScrollToBottom = () => {
    if (hasNextPage) {
      fetchNextPage()
    }
  }

  const formatLabel = (option) => {
    return (
      <article className='custom-option text-wrap' style={{lineHeight: 1.1}}>
        {option.label}
      </article>
    )
  }

  return (
    <SelectWithMoreWrapper hasMore={hasNextPage || false} isLoading={isFetching}>
      <Select
        {...rest}
        name='subject-select'
        isLoading={isLoading}
        options={options}
        onChange={handleChange}
        value={selected ? {value: selected.id, label: selected.name} : null}
        classNamePrefix='react-select'
        className={clsx('react-select', className)}
        placeholder='Type to search...'
        isClearable={isClearable}
        onInputChange={handleInputChange}
        onMenuOpen={() => handleInputChange('')}
        styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
        menuPortalTarget={document.body}
        menuShouldScrollIntoView={false}
        onMenuScrollToBottom={handleScrollToBottom}
        formatOptionLabel={(data) => formatLabel(data)}
      />
    </SelectWithMoreWrapper>
  )
}

export default SubjectSelect
