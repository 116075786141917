// @ts-nocheck
import {Column} from 'react-table'
import {CourseActionsCell} from './CourseActionsCell'
import {CourseCustomHeader} from './CourseCustomHeader'
import {Course} from '../../core/_models'

const courseColumns: ReadonlyArray<Column<Course>> = [
  {
    Header: (props) => (
      <CourseCustomHeader
        tableProps={props}
        title='Subject'
        className='min-w-125px'
        enableSort={true}
      />
    ),
    accessor: 'subjectName',
    id: 'subjectName',
  },
  {
    Header: (props) => (
      <CourseCustomHeader
        tableProps={props}
        title='Semester'
        className='min-w-125px'
        enableSort={true}
      />
    ),
    accessor: 'semesterName',
    id: 'semesterName',
  },
  // {
  //   Header: (props) => (
  //     <CourseCustomHeader
  //       tableProps={props}
  //       title='Degree'
  //       className='min-w-125px'
  //       enableSort={true}
  //     />
  //   ),
  //   accessor: 'degreeName',
  //   id: 'degreeId',
  // },
  {
    Header: (props) => (
      <CourseCustomHeader
        tableProps={props}
        title='Major'
        className='min-w-125px'
        enableSort={true}
      />
    ),
    accessor: 'customMajorName',
    id: 'customMajorName',
  },
  {
    Header: (props) => (
      <CourseCustomHeader
        tableProps={props}
        title='Status'
        className='min-w-125px'
        enableSort={true}
      />
    ),
    accessor: 'dataStatusName',
    id: 'dataStatusId',
  },
  {
    Header: (props) => (
      <CourseCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <CourseActionsCell
        id={props.data[props.row.index].id}
        name={props.data[props.row.index].subjectName + ' - ' + props.data[props.row.index].semesterName}
        degreeId={props.data[props.row.index].degreeId}
        status={props.data[props.row.index].dataStatusId}
      />
    ),
  },
]

export {courseColumns}
