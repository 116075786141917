import {FC, useState, createContext, useContext} from 'react'
import {
  QueryState,
  QueryRequestContextProps,
  initialQueryRequest,
  WithChildren,
  initialQueryState,
} from '../../../../../_metronic/helpers'
import {getSavedState, setSavedState} from '../../../../utils/saved-state'

const storageKey = 'course-table'
const getInitQuery = () => {
  return getSavedState(storageKey) || {
    ...initialQueryState,
    filter: {
      statusId: 1
    }}
}

const QueryRequestContext = createContext<QueryRequestContextProps>(initialQueryRequest)

const QueryRequestProvider: FC<WithChildren> = ({children}) => {
  const init = getInitQuery()
  const [state, setState] = useState<QueryState>(init)

  const updateState = (updates: Partial<QueryState>) => {
    const updatedState = {...state, ...updates} as QueryState
    setState(updatedState)
    setSavedState(storageKey, updatedState)
  }

  return (
    <QueryRequestContext.Provider value={{state, updateState}}>
      {children}
    </QueryRequestContext.Provider>
  )
}

const useQueryRequest = () => useContext(QueryRequestContext)

export {QueryRequestProvider, useQueryRequest, getInitQuery}
