import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {Paging} from '../../../models/Response'
import {ISemester} from '../../../models/ISemester'
import {IClass} from '../../../models/IClass'
import {Class} from '../../courses/class-list/core/_models'
import {IClassSessionMaterial} from '../../../models/IClassSessionMaterial'
import {CourseAssignment} from '../../courses/course-list/core/_models'
import {IClassMeet} from '../../../models/IClassMeet'
import {IClassAssignmentAnswer} from '../../../models/IClassAssignmentAnswer'
import {
  IAutoSubmitQuiz,
  IClassQuizAnswer,
  IClassQuizQuestion,
} from '../../../models/IClassQuizAnswer'
import {IBaseResponse, IInfiteScrollData} from '../../auth'
import {ISubject} from '../../../models/ISubject'
import {IClassAnnouncement} from '../../../models/IAnnouncement'
import moment from 'moment'
import {showError} from '../../../utils/toast'
import {IClassQuiz, IClassQuizLive} from '../../../models/IClassQuiz'
import {an} from '@fullcalendar/core/internal-common'
import getFingerprint from '../../../utils/fingerprint'
import {IClassSession} from '../../../models/IClassSession'
import {IClassAssignment} from '../../../models/IClassAssignment'
import {IClassStudent} from '../../../models/IClassStudent'

const API_URL = process.env.REACT_APP_BASE_API_URL
const SEMESTER_URL = `${API_URL}/semesters`
const SUBJECT_URL = `${API_URL}/subjects`
const CLASSES_URL = `${API_URL}/classes`
const CLASS_MATERIAL_URL = `${API_URL}/class-session-materials`
const CLASS_ATTENDANCE_URL = `${API_URL}/attendances`
const CLASS_ASSIGNMENT_ANSWER_URL = `${API_URL}/class-assignment-answer`
const CLASS_QUIZ_URL = `${API_URL}/class-quiz`
const CLASS_QUIZ_ANSWER_URL = `${API_URL}/class-quiz-answer`
const CLASS_ANNOUNCEMENT_URL = `${API_URL}/class-announcements`
const CLASS_MEET_URL = `${API_URL}/class-meets`

const getSemesterSelect = (query: string): Promise<Response<Paging<ISemester>>> => {
  return axios
    .get(`${SEMESTER_URL}/lov?${query}`)
    .then((d: AxiosResponse<Response<Paging<ISemester>>>) => d.data)
}
const getSubjectSelect = (query: string): Promise<Response<IInfiteScrollData<ISubject>>> => {
  return axios
    .get(`${SUBJECT_URL}/select?${query}`)
    .then((d: AxiosResponse<Response<IInfiteScrollData<ISubject>>>) => d.data)
}
const getLatestSemester = (): Promise<Response<ISemester | null>> => {
  return axios
    .get(`${SEMESTER_URL}/latest`)
    .then((d: AxiosResponse<Response<ISemester | null>>) => d.data)
}
const getClasses = (
  semesterId: number,
  query: string
): Promise<Response<IInfiteScrollData<IClass>>> => {
  return axios
    .get(`${CLASSES_URL}/semester/${semesterId}?${query}`)
    .then((d: AxiosResponse<Response<IInfiteScrollData<IClass>>>) => d.data)
}

const getCurrentClasses = (query: string): Promise<Response<IInfiteScrollData<IClass>>> => {
  return axios
    .get(`${CLASSES_URL}/current-semester?${query}`)
    .then((d: AxiosResponse<Response<IInfiteScrollData<IClass>>>) => d.data)
}

const getCurrentClassesByOperator = (
  query: string
): Promise<Response<IInfiteScrollData<IClass>>> => {
  return axios
    .get(`${CLASSES_URL}/current-semester-operator?${query}`)
    .then((d: AxiosResponse<Response<IInfiteScrollData<IClass>>>) => d.data)
}

const getClassOperatorById = (id: ID): Promise<IBaseResponse<IClass | undefined>> => {
  return axios
    .get(`${CLASSES_URL}/by-operator/${id}`)
    .then((response: AxiosResponse<IBaseResponse<IClass>>) => response.data)
}

const getClassById = (id: ID): Promise<IClass | undefined> => {
  return axios
    .get(`${CLASSES_URL}/learning/${id}`)
    .then((response: AxiosResponse<Response<IClass>>) => response.data)
    .then((response: Response<IClass>) => response.data)
}

const updateSessionMaterial = (
  id: ID,
  data: IClassSessionMaterial
): Promise<number | undefined> => {
  return axios
    .put(`${CLASS_MATERIAL_URL}/${id}`, data)
    .then((response: AxiosResponse<Response<number>>) => response.data)
    .then((response: Response<number>) => response.data)
}

const deleteSessionMaterial = (id: ID): Promise<number | undefined> => {
  return axios
    .delete(`${CLASS_MATERIAL_URL}/${id}`)
    .then((response: AxiosResponse<Response<number>>) => response.data)
    .then((response: Response<number>) => response.data)
}

const uploadSessionMaterial = (
  material: IClassSessionMaterial
): Promise<IClassSessionMaterial | undefined> => {
  const formData = new FormData()
  formData.append('materialFile', material.materialFile ?? '') // Add your course session fields here
  formData.append('sessionId', material.classSessionId + '')

  return axios
    .post(CLASS_MATERIAL_URL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Set the content type to multipart
      },
    })
    .then((response: AxiosResponse<Response<IClassSessionMaterial>>) => response.data)
    .then((response: Response<IClassSessionMaterial>) => response.data)
    .catch((error: any) => {
      // Handle error
      console.error('Error creating class session material:', error)
      return undefined
    })
}

const createSessionAttendances = (sessionId: ID): Promise<IClassMeet | undefined> => {
  return axios
    .post(`${CLASS_ATTENDANCE_URL}/${sessionId}`)
    .then((response: AxiosResponse<Response<IClassMeet>>) => response.data)
    .then((response: Response<IClassMeet>) => response.data)
}

const updateSessionAttendances = (sessionId: ID, body: IClassMeet): Promise<number | undefined> => {
  return axios
    .put(`${CLASS_ATTENDANCE_URL}/${sessionId}`, body)
    .then((response: AxiosResponse<Response<number>>) => response.data)
    .then((response: Response<number>) => response.data)
}

const uploadAssignment = (
  answer: IClassAssignmentAnswer
): Promise<IClassAssignmentAnswer | undefined> => {
  const formData = new FormData()
  formData.append('assignmentFile', answer.assignmentFile ?? '') // Add your course session fields here
  formData.append('assignmentId', answer.assignmentId + '')
  formData.append('classId', answer.classId + '')

  return axios
    .post(CLASS_ASSIGNMENT_ANSWER_URL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Set the content type to multipart
      },
    })
    .then((response: AxiosResponse<Response<IClassAssignmentAnswer>>) => response.data)
    .then((response: Response<IClassAssignmentAnswer>) => response.data)
    .catch((error: any) => {
      // Handle error
      console.error('Error creating class session material:', error)
      return undefined
    })
}

const updateAnswerScore = (id: ID, data: IClassAssignmentAnswer): Promise<number | undefined> => {
  return axios
    .put(`${CLASS_ASSIGNMENT_ANSWER_URL}/score/${id}`, data)
    .then((response: AxiosResponse<Response<number>>) => response.data)
    .then((response: Response<number>) => response.data)
}

const startQuiz = (
  quizId: ID,
  userId: number,
  lastQuestionId: number | null = null
): Promise<IClassQuizAnswer | undefined> => {
  return axios
    .post(`${CLASS_QUIZ_ANSWER_URL}/start`, {
      quizId,
      lastQuestionId,
      browserId: getFingerprint(userId),
    })
    .then((response: AxiosResponse<Response<IClassQuizAnswer>>) => response.data)
    .then((response: Response<IClassQuizAnswer>) => response.data)
}

const startQuizWithPicture = (
  quizId: ID,
  image: File | Blob,
  userId: number
): Promise<IClassQuizAnswer | undefined> => {
  const formData = new FormData()
  formData.append('quizId', quizId + '')
  formData.append('image', image)
  formData.append('browserId', getFingerprint(userId))
  return axios
    .post(`${CLASS_QUIZ_ANSWER_URL}/start/form`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response: AxiosResponse<Response<IClassQuizAnswer>>) => response.data)
    .then((response: Response<IClassQuizAnswer>) => response.data)
}

const answerQuiz = (body: IClassQuizQuestion): Promise<IClassQuizQuestion | undefined> => {
  return axios
    .post(`${CLASS_QUIZ_ANSWER_URL}`, body)
    .then((response: AxiosResponse<Response<IClassQuizQuestion>>) => response.data)
    .then((response: Response<IClassQuizQuestion>) => response.data)
}

const endQuiz = (quizId: ID): Promise<IClassQuizAnswer | undefined> => {
  return axios
    .post(`${CLASS_QUIZ_ANSWER_URL}/end`, {quizId})
    .then((response: AxiosResponse<Response<IClassQuizAnswer>>) => response.data)
    .then((response: Response<IClassQuizAnswer>) => response.data)
}

const autoEndQuiz = (body: any): Promise<IAutoSubmitQuiz | undefined> => {
  return axios
    .post(`${CLASS_QUIZ_ANSWER_URL}/auto-end`, body)
    .then((response: AxiosResponse<Response<IAutoSubmitQuiz>>) => response.data)
    .then((response: Response<IAutoSubmitQuiz>) => response.data)
}

const getQuiz = (quizId: ID): Promise<IClassQuizAnswer | undefined> => {
  return axios
    .get(`${CLASS_QUIZ_ANSWER_URL}/byQuiz/${quizId}`)
    .then((response: AxiosResponse<Response<IClassQuizAnswer>>) => response.data)
    .then((response: Response<IClassQuizAnswer>) => response.data)
}

const getQuizByAnswerId = (
  answerId: ID,
  view: string | null
): Promise<IClassQuizAnswer | undefined> => {
  return axios
    .get(`${CLASS_QUIZ_ANSWER_URL}/${answerId}?view=${view}`)
    .then((response: AxiosResponse<Response<IClassQuizAnswer>>) => response.data)
    .then((response: Response<IClassQuizAnswer>) => response.data)
}

const correctionQuiz = (body: IClassQuizQuestion): Promise<IClassQuizQuestion | undefined> => {
  return axios
    .post(`${CLASS_QUIZ_ANSWER_URL}/correction`, body)
    .then((response: AxiosResponse<Response<IClassQuizQuestion>>) => response.data)
    .then((response: Response<IClassQuizQuestion>) => response.data)
}

const endCorrectionQuiz = (answerId: ID): Promise<IClassQuizAnswer | undefined> => {
  return axios
    .post(`${CLASS_QUIZ_ANSWER_URL}/end-correction`, {answerId})
    .then((response: AxiosResponse<Response<IClassQuizAnswer>>) => response.data)
    .then((response: Response<IClassQuizAnswer>) => response.data)
}

const updateClassStudentScore = (_class: IClass): Promise<IClass | undefined> => {
  return axios
    .put(`${CLASSES_URL}/score/${_class.id}`, _class)
    .then((response: AxiosResponse<Response<IClass>>) => response.data)
    .then((response: Response<IClass>) => response.data)
}

const getActiveAnnouncement = async (id: number): Promise<IBaseResponse<IClassAnnouncement[]>> => {
  const response = await axios.get(`${CLASS_ANNOUNCEMENT_URL}/active/${id}`)
  return response.data
}

const deleteClassAnnouncement = async (id: number): Promise<IBaseResponse<any[]>> => {
  const response = await axios.delete(`${CLASS_ANNOUNCEMENT_URL}/${id}`)
  return response.data
}

const createAnnouncement = (data: IClassAnnouncement): Promise<IClassAnnouncement | undefined> => {
  const formData = new FormData()
  if (data.fileCover) {
    formData.append('fileCover', data.fileCover ?? '')
  }
  if (data.fileAttachment) {
    formData.append('fileAttachment', data.fileAttachment ?? '')
  }
  formData.append('title', data.title + '')
  formData.append('date', data.date?.toISOString() + '')
  formData.append('shortDescription', data.shortDescription + '')
  formData.append('body', data.body + '')
  formData.append('startDate', moment(data.startDate).startOf('day').toISOString() + '')
  formData.append('endDate', moment(data.endDate).endOf('day').toISOString() + '')
  formData.append('classId', data.classId.toString())
  formData.append('isSendEmail', (data.isSendEmail ?? false).toString())

  return axios
    .post(CLASS_ANNOUNCEMENT_URL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response: AxiosResponse<Response<IClassAnnouncement>>) => response.data)
    .then((response: Response<IClassAnnouncement>) => response.data)
}

const updateSyncZoomAttendances = (classMeeId: ID): Promise<number | undefined> => {
  return axios
    .put(`${CLASS_ATTENDANCE_URL}/sync-zoom/${classMeeId}`)
    .then((response: AxiosResponse<Response<number>>) => response.data)
    .then((response: Response<number>) => response.data)
}

const getAttendance = (classMeeId: ID): Promise<any | undefined> => {
  return axios
    .get(`${CLASS_ATTENDANCE_URL}/${classMeeId}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const generateToken = (classMeeId: ID): Promise<any | undefined> => {
  return axios
    .post(`${CLASS_MEET_URL}/generate-token/${classMeeId}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const getClassQuizzes = (id: number, query: string): Promise<Response<Paging<IClassQuiz>>> => {
  return axios
    .get(`${CLASSES_URL}/${id}/quizzes?${query}`)
    .then((d: AxiosResponse<Response<Paging<IClassQuiz>>>) => d.data)
}

const getClassQuizAnswer = (query: string): Promise<Response<Paging<IClassQuizAnswer>>> => {
  return axios
    .get(`${CLASS_QUIZ_ANSWER_URL}?${query}`)
    .then((d: AxiosResponse<Response<Paging<IClassQuizAnswer>>>) => d.data)
}

const publishClassQuiz = (id: ID): Promise<Response<number>> => {
  return axios
    .post(`${CLASSES_URL}/publish-quiz/${id}`, {})
    .then((d: AxiosResponse<Response<number>>) => d.data)
}

const unpublishClassQuiz = (id: ID): Promise<Response<number>> => {
  return axios
    .post(`${CLASSES_URL}/unpublish-quiz/${id}`, {})
    .then((d: AxiosResponse<Response<number>>) => d.data)
}

const finishAllClassQuiz = (id: ID): Promise<Response<number>> => {
  return axios
    .put(`${CLASS_QUIZ_ANSWER_URL}/finish-all/${id}`, {})
    .then((d: AxiosResponse<Response<number>>) => d.data)
}

const finishClassQuiz = (answerId: ID): Promise<Response<number>> => {
  return axios
    .put(`${CLASS_QUIZ_ANSWER_URL}/force-finish/${answerId}`, {})
    .then((d: AxiosResponse<Response<number>>) => d.data)
}

const getClassAssignmentAnswer = (
  query: string
): Promise<Response<Paging<IClassAssignmentAnswer>>> => {
  return axios
    .get(`${CLASS_ASSIGNMENT_ANSWER_URL}?${query}`)
    .then((d: AxiosResponse<Response<Paging<IClassQuizAnswer>>>) => d.data)
}

const getClassMeetById = (id: ID): Promise<IClassMeet | undefined> => {
  return axios
    .get(`${CLASS_MEET_URL}/${id}`)
    .then((response: AxiosResponse<Response<IClassMeet>>) => response.data)
    .then((response: Response<IClassMeet>) => response.data)
}

const updateSummaryClassMeet = (id: ID, data: any): Promise<IClassMeet | undefined> => {
  return axios
    .put(`${CLASS_MEET_URL}/summary/${id}`, data)
    .then((response: AxiosResponse<Response<IClassMeet>>) => response.data)
    .then((response: Response<IClassMeet>) => response.data)
}

const getByTaskSemester = (semesterId: number): Promise<Response<IClass[]>> => {
  return axios
    .get(`${CLASSES_URL}/by-task-semester/${semesterId}`)
    .then((d: AxiosResponse<Response<IClass[]>>) => d.data)
}

const quizInfo = (quizId: ID): Promise<IClassQuiz | undefined> => {
  return axios
    .post(`${CLASS_QUIZ_ANSWER_URL}/info`, {quizId})
    .then((response: AxiosResponse<Response<IClassQuiz>>) => response.data)
    .then((response: Response<IClassQuiz>) => response.data)
}

const createFraud = (quizId: ID, questionId: number, type: number): Promise<any> => {
  return axios
    .post(`${CLASS_QUIZ_ANSWER_URL}/fraud/${quizId}`, {type, questionId})
    .then((response: AxiosResponse<Response<IClassQuiz>>) => response.data)
}

const updateEndFraud = (quizId: ID): Promise<any> => {
  return axios
    .put(`${CLASS_QUIZ_ANSWER_URL}/fraud/${quizId}`, {})
    .then((response: AxiosResponse<Response<IClassQuiz>>) => response.data)
}

const refreshQuiz = (classId: ID): Promise<Response<IClassQuiz[] | undefined>> => {
  return axios
    .get(`${CLASS_QUIZ_URL}/refresh/${classId}`)
    .then((response: AxiosResponse<Response<IClassQuiz[] | undefined>>) => response.data)
}

const getRemaining = (answerId: ID): Promise<Response<IClassQuizLive>> => {
  return axios
    .get(`${CLASS_QUIZ_ANSWER_URL}/remaining/${answerId}`)
    .then((response: AxiosResponse<Response<IClassQuizLive>>) => response.data)
}

const updateQuizAnswer = (answerId: ID, data: IClassQuizAnswer): Promise<Response<number>> => {
  return axios
    .put(`${CLASS_QUIZ_ANSWER_URL}/add-extra-time/${answerId}`, data)
    .then((response: AxiosResponse<Response<number>>) => response.data)
}

const validateFraudPasscode = (quizId, passcode: string): Promise<Response<boolean>> => {
  return axios
    .post(`${CLASS_QUIZ_ANSWER_URL}/fraud/validate-passcode/${quizId}`, {passcode})
    .then((response: AxiosResponse<Response<boolean>>) => response.data)
}

const getQuizPictures = (answerId: ID): Promise<Response<string[]>> => {
  return axios
    .get(`${CLASS_QUIZ_ANSWER_URL}/show-pictures/${answerId}`)
    .then((d: AxiosResponse<Response<string[]>>) => d.data)
}

const getClassesArchive = (
  semesterId: number,
  query: string
): Promise<Response<IInfiteScrollData<IClass>>> => {
  return axios
    .get(`${CLASSES_URL}/archive/${semesterId}?${query}`)
    .then((d: AxiosResponse<Response<IInfiteScrollData<IClass>>>) => d.data)
}

const unlockAnswer = (answerId: ID): Promise<Response<number>> => {
  return axios
    .put(`${CLASS_QUIZ_ANSWER_URL}/unlock/${answerId}`, {})
    .then((d: AxiosResponse<Response<number>>) => d.data)
}

const deactivate = (answerId: ID, statusReason: string): Promise<Response<number>> => {
  return axios
    .put(`${CLASS_QUIZ_ANSWER_URL}/deactivate/${answerId}`, {statusReason})
    .then((d: AxiosResponse<Response<number>>) => d.data)
}

const activate = (answerId: ID): Promise<Response<number>> => {
  return axios
    .put(`${CLASS_QUIZ_ANSWER_URL}/activate/${answerId}`, {})
    .then((d: AxiosResponse<Response<number>>) => d.data)
}

const resetFraud = (answerId: ID): Promise<Response<number>> => {
  return axios
    .put(`${CLASS_QUIZ_ANSWER_URL}/reset-fraud/${answerId}`, {})
    .then((d: AxiosResponse<Response<number>>) => d.data)
}

const getSessionByClassId = (classId: ID): Promise<Response<IClassSession[]>> => {
  return axios
    .get(`${CLASSES_URL}/learning/sessions/${classId}`)
    .then((resp: AxiosResponse<Response<IClassSession[]>>) => resp.data)
}

const getAssignmentByClassId = (classId: ID): Promise<Response<IClassAssignment[]>> => {
  return axios
    .get(`${CLASSES_URL}/learning/assignments/${classId}`)
    .then((resp: AxiosResponse<Response<IClassAssignment[]>>) => resp.data)
}

const getQuizByClassId = (classId: ID): Promise<Response<IClassQuiz[]>> => {
  return axios
    .get(`${CLASSES_URL}/learning/quizzes/${classId}`)
    .then((resp: AxiosResponse<Response<IClassQuiz[]>>) => resp.data)
}

const getPeopleByClassId = (classId: ID): Promise<Response<IClassStudent[]>> => {
  return axios
    .get(`${CLASSES_URL}/learning/people/${classId}`)
    .then((resp: AxiosResponse<Response<IClassStudent[]>>) => resp.data)
}

const getMeetsByClassId = (classId: ID): Promise<Response<IClassMeet[]>> => {
  return axios
    .get(`${CLASSES_URL}/learning/meets/${classId}`)
    .then((resp: AxiosResponse<Response<IClassMeet[]>>) => resp.data)
}

const startQuestionAnswer = (data: any): Promise<number | undefined> => {
  return axios
    .post(`${CLASS_QUIZ_ANSWER_URL}/start-question`, data)
    .then((response: AxiosResponse<Response<IClassQuizQuestion>>) => response.data)
    .then((response: Response<any>) => response.data)
}

export {
  getLatestSemester,
  getSemesterSelect,
  getSubjectSelect,
  getClasses,
  getClassById,
  updateSessionMaterial,
  uploadSessionMaterial,
  deleteSessionMaterial,
  createSessionAttendances,
  updateSessionAttendances,
  uploadAssignment,
  updateAnswerScore,
  startQuiz,
  answerQuiz,
  endQuiz,
  getQuiz,
  getQuizByAnswerId,
  correctionQuiz,
  endCorrectionQuiz,
  updateClassStudentScore,
  getActiveAnnouncement,
  createAnnouncement,
  updateSyncZoomAttendances,
  getAttendance,
  getCurrentClasses,
  deleteClassAnnouncement,
  generateToken,
  getClassQuizzes,
  getClassQuizAnswer,
  getClassAssignmentAnswer,
  getClassMeetById,
  updateSummaryClassMeet,
  getCurrentClassesByOperator,
  getClassOperatorById,
  getByTaskSemester,
  publishClassQuiz,
  unpublishClassQuiz,
  quizInfo,
  createFraud,
  updateEndFraud,
  refreshQuiz,
  autoEndQuiz,
  getRemaining,
  updateQuizAnswer,
  validateFraudPasscode,
  finishAllClassQuiz,
  finishClassQuiz,
  startQuizWithPicture,
  getQuizPictures,
  getClassesArchive,
  unlockAnswer,
  deactivate,
  activate,
  resetFraud,
  getSessionByClassId,
  getAssignmentByClassId,
  getQuizByClassId,
  getPeopleByClassId,
  getMeetsByClassId,
  startQuestionAnswer,
}
