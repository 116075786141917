import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import LecturerReportTab from './components/LecturerReportTab'
import {LecturerAttendanceListWrapper} from './lecturer-attendance-list/LecturerAttendanceList'
import {LecturerNotAttendWrapper} from './lecturer-not-attend/LecturerNotAttend'
import {LecturerSkillListWrapper} from './lecturer-skill-list/LecturerSkillList'
import {LecturerSubjectListWrapper} from './lecturer-subject-list/LecturerSubjectList'
const LecturerReportPage = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <LecturerReportTab />
            <Outlet />
          </>
        }
      >
        <Route index element={<Navigate to='/reports/lecturers/lecturer-subject' />} />
        <Route path='lecturer-subject' element={<LecturerSubjectListWrapper />} />
        <Route path='lecturer-skill' element={<LecturerSkillListWrapper />} />
        <Route path='lecturer-attendance' element={<LecturerAttendanceListWrapper />} />
        <Route path='lecturer-not-attend' element={<LecturerNotAttendWrapper />} />
      </Route>
    </Routes>
  )
}

export default LecturerReportPage
