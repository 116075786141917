import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTIcon, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'

type Props = {
  index: number
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  isExt?: boolean
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
  index,
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  isExt = false,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname ?? '', to)
  const {config} = useLayout()
  const {app} = config

  // console.log(index)

  const Wrapper = ({children, isExt = false}) => {
    return isExt ? (
      <a className={clsx('menu-link without-sub', {active: isActive})} href={to} target='_blank'>
        {children}
      </a>
    ) : (
      <Link className={clsx('menu-link without-sub', {active: isActive})} to={to}>
        {children}
      </Link>
    )
  }

  return (
    <div key={index} className='menu-item'>
      <Wrapper isExt={isExt}>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className='menu-icon' style={{color: '#e80f27'}}>
            <KTIcon iconName={icon} className='fs-2' />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
          <i className={clsx('bi fs-3', fontIcon)}></i>
        )}
        <span className='menu-title'>{title}</span>
      </Wrapper>
      {children}
    </div>
  )
}

export {SidebarMenuItem}
