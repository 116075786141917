/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {Outlet, Link, useParams} from 'react-router-dom'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {getMeetByToken} from '../core/_request'
import {MeetAttendView} from '../core/_model'
import {formatDate, formatDateTime, formatTime} from '../../../utils/date'

const AttendanceStudentLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid flex-lg-row'
      style={{
        backgroundImage: `url('${toAbsoluteUrl('/media/bg-7.webp')}')`,
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundColor: '#390d0a',
        alignItems: 'center',
      }}
    >
      <div className='d-flex flex-column flex-column-fluid flex-lg-row h-100 w-100 justify-content-center'>
        <div className='d-flex align-items-center p-8 p-md-20 justify-content-center'>
          <div className='bg-body d-flex flex-column align-items-stretch flex-center rounded-4 w-500px overflow-hidden'>
            <div
              className='flex-center'
              style={{
                display: 'flex',
                padding: '24px 32px',

                backgroundColor: 'rgb(156 29 26)',
                alignItems: 'center',
              }}
            >
              <div
                className='d-flex flex-row flex-center'
                style={{
                  borderRadius: 12,
                  height: '100%',
                }}
              >
                <img alt='Logo' src='/media/logos/logo-dark.webp' style={{width: 65}} />
                <div className='text-center ms-5'>
                  <h1 className='text-white fw-bold m-0' style={{fontSize: '2.4rem'}}>
                    STIK - PTIK
                  </h1>
                  <h5 className='text-white fw-normal m-0'>Learning Management System</h5>
                </div>
              </div>
            </div>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AttendanceStudentLayout
