import {FC} from 'react'

const ReferenceNotFound: FC = () => {
  return (
    <div className='card h-100'>
      <div className='card-body'>
        <div className='text-center'>
          <div className='card-body'>
            <h5 className='card-title'>
              <i className='bi bi-file-earmark-excel-fill fs-3x'></i>
            </h5>
            <p className='card-text text-muted fs-5 mt-1'>No Files</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReferenceNotFound
