// CustomSelect.js
import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import Select from 'react-select'
import axios, {HttpStatusCode} from 'axios'
import clsx from 'clsx'
import {stringifyRequestQuery, useDebounce} from '../../../../_metronic/helpers'
import {ISemester} from '../../../models/ISemester'
import {getSemesterSelect} from '../core/_request'

interface SemesterSelectProps {
  selected?: ISemester
  setSelected?: Dispatch<SetStateAction<ISemester | undefined>>
  className?: string
  defaultOption?: any
  initialOptions?: any[]
  isClearable?: boolean
  portal?: any
  labelWithMajor?: boolean
  disabled?: boolean
  majorId?: null | number
  studentId?: null | number
}

const API_URL = process.env.REACT_APP_BASE_API_URL
const URL = `${API_URL}/semesters`

const SemesterSelect: FC<SemesterSelectProps> = ({
  selected,
  isClearable = false,
  className = '',
  defaultOption,
  initialOptions = [],
  portal,
  setSelected,
  labelWithMajor = true,
  disabled = false,
  majorId = null,
  studentId = null,
  ...rest
}) => {
  const [keyword, setKeyword] = useState<string | undefined>(undefined)
  const debouncedSearchTerm = useDebounce(keyword, 150)

  const {isLoading, data, refetch} = useQuery(
    'semester-select',
    async () => {
      try {
        const response = await getSemesterSelect(
          stringifyRequestQuery({
            keyword: debouncedSearchTerm,
            // filter: majorId ? {majorId} : {},
            filter: {
              majorId: majorId,
              studentId: studentId,
            },
          })
        )
        return response.data?.records ?? []
      } catch (ex) {
        console.log(ex)
      }
    },
    {enabled: false}
  )

  useEffect(() => {
    refetch()
  }, [majorId])

  const handleChange = (selectedOption) => {
    if (setSelected) {
      if (selectedOption) {
        let selected = {
          id: selectedOption.value,
          name: selectedOption.label,
        }
        setSelected(selected)
      } else {
        setSelected(undefined)
      }
    }
  }

  const options =
    keyword === undefined
      ? initialOptions
      : data?.some((item) => item.id !== null)
      ? data?.map((option) => ({
          value: option.id,
          label: labelWithMajor ? `${option.majorName} - ${option.name}` : option.name,
        }))
      : []

  const handleInputChange = (value) => {
    setKeyword(value)
  }

  useEffect(() => {
    if (debouncedSearchTerm === undefined) return
    refetch()
  }, [debouncedSearchTerm])

  const formatLabel = (option) => {
    return (
      <article className='custom-option text-wrap' style={{lineHeight: 1.1}}>
        {option.label}
      </article>
    )
  }

  return (
    <Select
      {...rest}
      name='semester-select'
      isLoading={isLoading}
      options={options}
      onChange={handleChange}
      value={selected ? options.find((option) => option.value === selected?.id) : null} // Set the initial selected value
      classNamePrefix='react-select'
      className={clsx('react-select', className)}
      placeholder='Type to search...'
      isClearable={isClearable}
      onInputChange={handleInputChange}
      onMenuOpen={() => handleInputChange('')}
      styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
      menuPortalTarget={document.body}
      menuShouldScrollIntoView={false}
      formatOptionLabel={(data) => formatLabel(data)}
      isDisabled={disabled}
    />
  )
}

export default SemesterSelect
