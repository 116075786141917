import {FieldArray, Form, Formik} from 'formik'
import {useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {useLocation} from 'react-router-dom'
import {KTCard} from '../../../../../_metronic/helpers'
import DatePickerField from '../../../../components/DatePickerField'
import {MenuModel, getMenuAccess, useAuth} from '../../../auth'
import {ClassListLoading} from '../../class-list/components/loading/ClassListLoading'
import {Class, ClassSession, initialClassSession} from '../../class-list/core/_models'
import {getClassById, updateClass} from '../../class-list/core/_requests'
import LoadingButtonWrapper from '../../../../components/LoadingButtonWrapper'
import {getClassSessions, updateClassSessions} from '../core/_request'

function ClassFormSession({id, data}) {
  const [isLoading, setIsLoading] = useState(false)
  const [classForEdit, setClassForEdit] = useState<Class>(data)
  // const [session, setSessionForEdit] = useState<ClassSession>({...initialClassSession})
  const {pathname} = useLocation()
  const {currentMenu} = useAuth()
  const [menuAccess, setMenuAccess] = useState<MenuModel | undefined>(undefined)
  useEffect(() => {
    setMenuAccess(getMenuAccess(currentMenu ?? [], pathname))
  }, [currentMenu])

  useEffect(() => {
    refetch()
  }, [])

  const {refetch} = useQuery(
    'class-session',
    async () => {
      try {
        const response = await getClassSessions(id)
        if (response.code === '0000') {
          setClassForEdit((prev) => {
            const newInstance = {...prev}
            newInstance.classSessions = response.data
            return newInstance
          })
        } else {
          setClassForEdit((prev) => {
            const newInstance = {...prev}
            newInstance.classSessions = []
            return newInstance
          })
        }
      } catch (ex) {
        console.log(ex)
      }
    },
    {enabled: false}
  )

  return (
    <KTCard>
      <div className='px-10 py-8'>
        <Formik
          initialValues={classForEdit}
          enableReinitialize={true}
          onSubmit={async (values, actions) => {
            // const models = {...values}
            const models = values.classSessions || []
            actions.setSubmitting(true)
            try {
              await updateClassSessions(id, models)
              actions.setSubmitting(true)
              refetch()
            } catch (ex: any) {
              console.error(ex)
              actions.setStatus(ex.message)
            } finally {
              actions.setSubmitting(false)
            }
          }}
        >
          {({values, isSubmitting, status}) => (
            <Form>
              {status ? (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{status}</div>
                </div>
              ) : (
                ''
              )}

              <FieldArray name='classSessions'>
                {({push, remove}) => (
                  <div className='table-responsive'>
                    <table className='table table-striped table-row-gray-300 align-middle gs-0 gy-4'>
                      <thead>
                        <tr className='fw-bold text-muted'>
                          <th style={{minWidth: 60}} className='text-center'>
                            No
                          </th>
                          <th className='min-w-150px'>Name</th>
                          <th className='min-w-120px'>Start Date</th>
                          <th className='min-w-120px'>End Date</th>
                        </tr>
                      </thead>
                      {values?.classSessions?.length != 0 ? (
                        <tbody>
                          {values?.classSessions?.map((item, index) => (
                            <tr key={index}>
                              <td className='text-center'>{index + 1}</td>
                              <td>Session {index + 1}</td>
                              <td>
                                <div>
                                  <DatePickerField
                                    label='Select Date'
                                    name={`classSessions.${index}.startDate`}
                                    enableTime={true}
                                    dateFormat='Y-m-d H:i'
                                    labelFormat='d/m/Y H:i'
                                  />
                                </div>
                              </td>
                              <td>
                                <div>
                                  <DatePickerField
                                    label='Select Date'
                                    name={`classSessions.${index}.endDate`}
                                    enableTime={true}
                                    dateFormat='Y-m-d H:i'
                                    labelFormat='d/m/Y H:i'
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody className='text-gray-600 fw-bold'>
                          <tr>
                            <td colSpan={5}>
                              <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                {isLoading ? '' : ' No matching records found'}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                )}
              </FieldArray>
              <div className='text-end pt-15'>
                {menuAccess?.canCreate && (
                <LoadingButtonWrapper isLoading={isSubmitting || isLoading}>
                  <button
                    type='submit'
                    className='btn btn-warning'
                    disabled={isSubmitting || isLoading || values?.classSessions?.length == 0}
                  >
                    <span className='indicator-label'>Submit</span>
                  </button>
                </LoadingButtonWrapper>
                )}
              </div>
              {(isSubmitting || isLoading) && <ClassListLoading />}
            </Form>
          )}
        </Formik>
      </div>
    </KTCard>
  )
}

export default ClassFormSession
