import axios, {AxiosResponse} from 'axios'
import {MeetAttendView} from './_model'
import {Response} from '../../../../_metronic/helpers'
import {IAuthRequest, IBaseResponse} from '../../auth'

const API_URL = process.env.REACT_APP_BASE_API_URL
const URL = `${API_URL}/class-meets`

const getMeetByToken = (token: string): Promise<Response<MeetAttendView>> => {
  return axios
    .get(`${URL}/by-token/${token}`)
    .then((d: AxiosResponse<Response<MeetAttendView>>) => d.data)
}

async function attend(token: string, request: IAuthRequest): Promise<IBaseResponse<string>> {
  const formData = new URLSearchParams()
  formData.append('username', request.username)
  formData.append('password', request.password)
  formData.append('grantType', 'password')

  const response = await axios.post<IBaseResponse<string>>(`${URL}/attend/${token}`, formData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
  return response.data
}

export {getMeetByToken, attend}
