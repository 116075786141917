import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {IUser} from '../../../models/IUser'
import {IBaseResponse} from '../../auth'
import {IAnnouncement} from '../../../models/IAnnouncement'
import {ChangePasswordType, MyCourseModel, QrTokenResponse} from './_model'
import {IClassStudent, IClassStudentScore} from '../../../models/IClassStudent'
import {LibraryUser} from '../../students/student-list/core/_models'

const API_URL = process.env.REACT_APP_BASE_API_URL
const GET_ANNOUNCEMENT = `${API_URL}/announcements`
const UPLOAD_AVATAR_URL = `${API_URL}/admin/users/upload-avatar`
const GET_PROFILE_URL = `${API_URL}/users/me`
const GET_QR_URL = `${API_URL}/users/my-qr`
const MY_COURSES = `${API_URL}/dashboards/my-course`
const CHANGE_PASSWORD = `${API_URL}/users/password`
const MY_SCORE = `${API_URL}/classes/gpa`
const LIBRARY_USER = `${API_URL}/libraryUsers`

const uploadAvatar = (file: File): Promise<any | undefined> => {
  const formData = new FormData()
  formData.append('file', file)
  const reader = new FileReader()

  return axios
    .post(`${UPLOAD_AVATAR_URL}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const getProfile = async (): Promise<IBaseResponse<IUser>> => {
  const response = await axios.get<IBaseResponse<IUser>>(GET_PROFILE_URL)
  return response.data
}

const getAnnouncement = async (): Promise<IBaseResponse<IAnnouncement>> => {
  const response = await axios.get<IBaseResponse<IAnnouncement>>(GET_ANNOUNCEMENT)
  return response.data
}

const getMyCourses = async (): Promise<IBaseResponse<MyCourseModel[]>> => {
  const response = await axios.get<IBaseResponse<MyCourseModel[]>>(MY_COURSES)
  return response.data
}

const changePassword = async (data: ChangePasswordType): Promise<IBaseResponse<string>> => {
  const response = await axios.put<IBaseResponse<string>>(CHANGE_PASSWORD, data)
  return response.data
}

const getMyScore = async (semesterId: number): Promise<IBaseResponse<IClassStudentScore>> => {
  const response = await axios.get<IBaseResponse<IClassStudentScore>>(
    `${MY_SCORE}/semester/${semesterId}`
  )
  return response.data
}

const getGPA = async (): Promise<IBaseResponse<IClassStudentScore>> => {
  const response = await axios.get<IBaseResponse<IClassStudentScore>>(`${MY_SCORE}`)
  return response.data
}

const getQrToken = async (): Promise<IBaseResponse<QrTokenResponse>> => {
  const response = await axios.get<IBaseResponse<QrTokenResponse>>(`${GET_QR_URL}`)
  return response.data
}

const getScoresById = async (
  studentMajorId: number
): Promise<IBaseResponse<IClassStudentScore>> => {
  const response = await axios.get<IBaseResponse<IClassStudentScore>>(
    `${MY_SCORE}/student/${studentMajorId}`
  )
  return response.data
}

const getLibraryUserByUserId = async (id: ID): Promise<Response<LibraryUser>> => {
  return axios.get(`${LIBRARY_USER}/user/${id}`).then((response) => response.data)
}

const updateLibraryUser = (id: ID, data: LibraryUser | undefined): Promise<Response<number>> => {
  return axios.put(`${LIBRARY_USER}/${id}`, data).then((response) => response.data)
}

const addLibraryUser = (data: LibraryUser): Promise<Response<LibraryUser>> => {
  return axios.post(`${LIBRARY_USER}`, data).then((response) => response.data)
}

export {
  uploadAvatar,
  getProfile,
  getAnnouncement,
  getMyCourses,
  changePassword,
  getMyScore,
  getGPA,
  getQrToken,
  getScoresById,
  getLibraryUserByUserId,
  updateLibraryUser,
  addLibraryUser,
}
