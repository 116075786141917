import {CKEditor} from '@ckeditor/ckeditor5-react'
import {UploadAdapter, FileLoader} from '@ckeditor/ckeditor5-upload/src/filerepository'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
// import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
import axios from 'axios'

const API_URL = process.env.REACT_APP_BASE_API_URL

const imageConfiguration = {
  resizeOptions: [
    {
      name: 'resizeImage:original',
      value: null,
      label: 'Original',
    },
    {
      name: 'resizeImage:40',
      value: '40',
      label: '40%',
    },
    {
      name: 'resizeImage:60',
      value: '60',
      label: '60%',
    },
  ],
  toolbar: ['resizeImage' /* ... */],
}

function uploadAdapter(loader: FileLoader): UploadAdapter {
  return {
    upload: () => {
      return new Promise(async (resolve, reject) => {
        try {
          const file = await loader.file
          const response = await axios.request({
            method: 'POST',
            url: `${API_URL}/uploadfiles`,
            data: {
              files: file,
            },
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          resolve({
            default: `${API_URL}/uploadfiles/${response.data.data.uniqueId}`,
          })
        } catch (error) {
          reject('Hello')
        }
      })
    },
    abort: () => {},
  }
}
function uploadPlugin(editor: Editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return uploadAdapter(loader)
  }
}

const RichEditor = ({onChange, value, disabled = false}) => {
  return (
    <CKEditor
      editor={Editor}
      config={{
        // @ts-ignore
        extraPlugins: [uploadPlugin],
      }}
      onReady={(editor) => {}}
      onBlur={(event, editor) => {}}
      onFocus={(event, editor) => {}}
      onChange={(event, editor) => {
        onChange(editor.getData())
      }}
      data={value}
      disabled={disabled}
    />
  )
}

export default RichEditor
