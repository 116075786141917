const QUERIES = {
  USERS_LIST: 'users-list',
  STUDENTS_LIST: 'students-list',
  LECTURERS_LIST: 'lecturers-list',
  STUDENT_MAJORS_LIST: 'student-majors-list',
  STAFF_LIST: 'staff-list',
  SEMESTER_LIST: 'semester-list',
  COURSE_LIST: 'courses-list',
  COURSE_CLASS_LIST: 'courses-class-list',
  SUBJECT_LIST: 'subject-list',
  ANNOUNCEMENT_LIST: 'announcement-list',
  STUDENT_SEMESTER_LIST: 'student-semester-list',
  MATERIAL_LIST: 'material-list',
  ACADEMIC_CALENDAR_LIST: 'academic-calendar-list',
  REPORT_STUDENT_SEMESTER: 'report-student-semester',
  REPORT_CLASSMEET_SEMESTER: 'report-classmeet-semester',
  REPORT_LECTURER_SUBJECT: 'report-lecturer-subject',
  REPORT_LECTURER_SKILL: 'report-lectuer-skill',
  REPORT_MEET_ATTENDANCE: 'report-meet-attendance',
  REPORT_STUDENT_EXAM: 'report-student-exam',
  STUDENT_SURVEY: 'student-survey',
  REPORT_SURVEY_RESULT: 'report-survey-result',
  REPORT_SURVEY_STUDENT_INFO: 'report-survey-student-info',
  REPORT_LECTURER_NOT_ATTEND: 'report-lectuer-not-attend',
  LOGIN_HISTORY_LIST: 'login-history-list',
  REPORT_RESULT_QUIZ: 'report-result-quiz',
  MAJOR_LIST: 'major-list',
  REFERENCE_LIST: 'reference-list',
  USER_SESSION_LIST: 'user-session-list',
  BIBLIOGRAPHY_LIST: 'bibliography-list',
  DOWNLOAD_REQUEST_LIST: 'download-request-list',
  USER_REGISTRATION_LIST: 'user-registration-list',
  PUBLIC_USERS_LIST: 'public-users-list',
  GENERAL_MAX_FILE_SIZE: 'general-max-file-size',
  EXTERNAL_LINK: 'external-link',
  EXAM_PICTURES: 'exam-pictures',
  CLASS_SESSIONS: 'class-sessions',
  CLASS_PEOPLE: 'class-people',
  CLASS_ASSIGNMENTS: 'class-assignments',
  CLASS_QUIZZES: 'class-quizzes',
  CLASS_MEETS: 'class-meets',
  SCHEDULES: 'schedules',
}

const CONSTANTS = {
  FAVICON_URL: 'https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&size=128&url=',
  FAVICON_URL_16: 'https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&size=16&url='
}

export {QUERIES, CONSTANTS}
