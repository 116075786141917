import {FC} from 'react'
import Skeleton from 'react-loading-skeleton'

type Props = {}

const ReferenceCardLoading: FC<Props> = () => {
  return (
    <div className='row g-6 g-xl-9 mb-6 mb-xl-9'>
      {Array.from({length: 4}, (_, i) => (
        <div className='col-12 col-sm-12 col-xl' key={i}>
          <div className='card h-100'>
            <div className='card-body d-flex justify-content-center text-center flex-column p-8'>
              <Skeleton count={6} />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export {ReferenceCardLoading}
