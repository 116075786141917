enum RoleEnum {
  Admin = 6,
  Staff = 7,
  Student = 8,
  Lecturer = 9,
  StaffAdmin = 10,
  Operator = 11,
  Reference = 12,
  Rectorate = 13,
}

export default RoleEnum
