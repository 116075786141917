// @ts-nocheck
import {Column} from 'react-table'
import {StudentAttendanceType} from '../../core/_model'
import {StudentAttendanceCustomHeader} from './StudentAttendanceCustomHeader'

const studentAttendanceColumns: ReadonlyArray<Column<StudentAttendanceType>> = [
  {
    Header: (props) => (
      <StudentAttendanceCustomHeader
        tableProps={props}
        title='Name'
        className='min-w-125px'
        enableSort={true}
      />
    ),
    accessor: 'name',
    id: 'name',
  },
  {
    Header: (props) => (
      <StudentAttendanceCustomHeader
        tableProps={props}
        title='Total Student'
        className='min-w-125px'
        enableSort={true}
      />
    ),
    accessor: 'total',
    id: 'total',
  },
]

export {studentAttendanceColumns}
