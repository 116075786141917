import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../_metronic/helpers'
import {ISurvey} from '../../../models/ISurvey'

const API_URL = process.env.REACT_APP_BASE_API_URL
const SURVEYS_URL = `${API_URL}/surveys`
const USER_URL = `${API_URL}/users`

const getSurveyDefault = (): Promise<Response<ISurvey>> => {
  return axios.get(`${SURVEYS_URL}/default`).then((d: AxiosResponse<Response<ISurvey>>) => d.data)
}
const answerSurvey = (surveyId: number, survey: ISurvey): Promise<Response<number>> => {
  return axios
    .post(`${SURVEYS_URL}/answer/${surveyId}`, survey)
    .then((response: AxiosResponse<Response<number>>) => response.data)
}
const answerSurveyByToken = (token: string, survey: ISurvey): Promise<Response<number>> => {
  return axios
    .post(`${SURVEYS_URL}/answer-by-token/${token}`, survey)
    .then((response: AxiosResponse<Response<number>>) => response.data)
}
const validateToken = (token: string): Promise<Response<ISurvey>> => {
  return axios
    .post(`${SURVEYS_URL}/validate-token/${token}`, {})
    .then((response: AxiosResponse<Response<ISurvey>>) => response.data)
}

const getShowSurveyNotif = (): Promise<Response<boolean>> => {
  return axios
    .get(`${USER_URL}/show-notif-survey`)
    .then((d: AxiosResponse<Response<boolean>>) => d.data)
}
const getClassInfoByMeetingId = (meetingId) => {
  return axios
    .get(`${SURVEYS_URL}/class-info/${meetingId}`)
    .then((d: AxiosResponse<Response<any>>) => d.data)
}

export {
  getSurveyDefault,
  answerSurvey,
  answerSurveyByToken,
  validateToken,
  getShowSurveyNotif,
  getClassInfoByMeetingId,
}
