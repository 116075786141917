import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../../_metronic/helpers'
import { Major, MajorQueryResponse } from './_models'

const API_URL = process.env.REACT_APP_BASE_API_URL
const MAJOR_URL = `${API_URL}/majors`
const GET_MAJOR_URL = `${API_URL}/majors`
const UPLOAD_AVATAR_URL = `${API_URL}/majors/upload-avatar`
const GET_CATEGORY_SELECT = `${API_URL}/category/select`

const getMajor = (query: string): Promise<MajorQueryResponse> => {
  return axios
    .get(`${GET_MAJOR_URL}?${query}`)
    .then((d: AxiosResponse<MajorQueryResponse>) => d.data)
}

const getMajorSelect = (query: string): Promise<MajorQueryResponse> => {
  return axios
    .get(`${GET_MAJOR_URL}/select?${query}`)
    .then((d: AxiosResponse<MajorQueryResponse>) => d.data)
}

const getMajorSelectPost = (query: string, excludeIds: ID[]): Promise<MajorQueryResponse> => {
  return axios
    .post(`${GET_MAJOR_URL}/select?${query}`, excludeIds)
    .then((d: AxiosResponse<MajorQueryResponse>) => d.data)
}

const getMajorById = (id: ID): Promise<Major | undefined> => {
  return axios
    .get(`${MAJOR_URL}/${id}`)
    .then((response: AxiosResponse<Response<Major>>) => response.data)
    .then((response: Response<Major>) => response.data)
}

const createMajor = (major: Major): Promise<Major | undefined> => {
  return axios
    .post(MAJOR_URL, major)
    .then((response: AxiosResponse<Response<Major>>) => response.data)
    .then((response: Response<Major>) => response.data)
}


const updateMajor = (major: Major): Promise<Major | undefined> => {
  return axios
    .put(`${MAJOR_URL}/${major.id}`, major)
    .then((response: AxiosResponse<Response<Major>>) => response.data)
    .then((response: Response<Major>) => response.data)
}

const deleteMajor = (majorId: ID): Promise<void> => {
  return axios.delete(`${MAJOR_URL}/${majorId}`).then(() => { })
}

const deleteSelectedMajor = (majorIds: Array<ID>): Promise<void> => {
  const requests = majorIds.map((id) => axios.delete(`${MAJOR_URL}/${id}`))
  return axios.all(requests).then(() => { })
}

export { getMajor, deleteMajor, deleteSelectedMajor, getMajorById, createMajor, updateMajor, getMajorSelect, getMajorSelectPost }
