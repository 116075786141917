import {useEffect, useState} from 'react'
import {Dropdown} from 'react-bootstrap'
import {useMutation, useQuery} from 'react-query'
import {useLocation} from 'react-router-dom'
import {KTCard, stringifyRequestQuery} from '../../../../../_metronic/helpers'
import {Confirm} from '../../../../components/Confirm'
import {PreviewPdf} from '../../../../components/PreviewPdf'
import {downloadFile, viewFile} from '../../../../utils/file'
import {showError} from '../../../../utils/toast'
import {MenuModel, getMenuAccess, useAuth} from '../../../auth'
import {deleteCourseAssignment, getCourseAssignment} from '../../course-list/core/_requests'
import {CourseFormAssignmentModal} from './CourseFormAssignmentModal'

function CourseFormAssignment({id, initialAssignments}) {
  const [showModal, setShowModal] = useState(false)
  const [assignments, setAssignments] = useState(initialAssignments)
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)
  const [loadingButton, setLoading] = useState<boolean>(false)
  const [loadingIdx, setLoadingIdx] = useState<number | undefined>()
  const [showPreviewPdf, setShowPreviewPdf] = useState(false)
  const handleClosePreviewPdf = () => setShowPreviewPdf(false)
  const {pathname} = useLocation()
  const {currentMenu} = useAuth()
  const [menuAccess, setMenuAccess] = useState<MenuModel | undefined>(undefined)
  useEffect(() => {
    setMenuAccess(getMenuAccess(currentMenu ?? [], pathname))
  }, [currentMenu])

  useEffect(() => {
    if (initialAssignments) setAssignments(initialAssignments)
  }, [initialAssignments])

  const openModalHandler = () => {
    setSelectedIndex(null)
    setShowModal(true)
  }

  const editHandler = (index: number) => {
    setShowModal(true)
    setSelectedIndex(index)
  }

  const closeHandler = () => {
    setShowModal(false)
    setSelectedIndex(null)
    refetch()
  }

  const downloadHandler = async (file: any, index: number) => {
    try {
      setLoading(true)
      setLoadingIdx(index)
      await downloadFile('course-assignments/download/' + file?.fileUniqueId, file.name)
    } catch (e) {
      showError('File cannot be downloaded')
      console.log(e)
    } finally {
      setLoading(false)
      setLoadingIdx(undefined)
    }
  }

  const handleView = async (assignment: any, index: number) => {
    setSelectedIndex(index)
    let type = assignment.fileName.split('.').pop()
    if (type != 'pdf') {
      try {
        await viewFile(`course-assignments/download/${assignment.fileUniqueId}`)
      } catch (err) {
        // @ts-ignore
        showError(err?.message)
      }
    } else {
      setShowPreviewPdf(true)
    }
  }

  const {refetch, isLoading} = useQuery(
    'course-assignment',
    async () => {
      try {
        const response = await getCourseAssignment(
          stringifyRequestQuery({
            pageSize: 100,
            filter: {
              courseId: id,
            },
          })
        )
        setAssignments(response.data?.records)
      } catch (ex) {
        console.log(ex)
      }
    },
    {enabled: false}
  )

  const [showDeleteAssignment, setShowDeleteAssignment] = useState(false)
  const [assignmentSelectedId, setAssignmentSelectedId] = useState<number | null>(null)

  const handleCloseDelete = () => setShowDeleteAssignment(false)

  const handleDeleteAction = async () => {
    try {
      await deleteAssignment.mutateAsync()
    } catch (err: any) {
      console.log(err)
    }
  }

  const deleteAssignment = useMutation(() => deleteCourseAssignment(assignmentSelectedId), {
    onSuccess: () => {
      setAssignmentSelectedId(null)
      handleCloseDelete()
      refetch()
    },
    onError: (err: any) => {},
  })

  const handleOpenConfirmation = (id) => {
    setAssignmentSelectedId(id)
    setShowDeleteAssignment(true)
  }

  return (
    <KTCard className='p-8'>
      <div className='mb-8'>
        {menuAccess?.canCreate && (
          <button type='button' className='btn btn-warning' onClick={openModalHandler}>
            Add Assignment
          </button>
        )}
      </div>
      <CourseFormAssignmentModal
        id={id}
        show={showModal}
        assignment={selectedIndex !== null ? assignments[selectedIndex] : null}
        onClose={closeHandler}
      />
      <div className='table-responsive'>
        <table className='table table-striped align-middle fs-6 gy-5 dataTable no-footer'>
          <thead>
            <tr>
              <th
                style={{minWidth: 50}}
                className='text-center text-muted fw-bolder fs-7 text-uppercase gs-0'
              >
                No
              </th>
              <th
                style={{minWidth: 100, width: '100%'}}
                className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'
              >
                Name
              </th>
              <th
                style={{minWidth: 120, maxWidth: 120}}
                className='text-center text-muted fw-bolder fs-7 text-uppercase gs-0'
              >
                File
              </th>
              <th
                style={{minWidth: 120, maxWidth: 120}}
                className='text-center text-muted fw-bolder fs-7 text-uppercase gs-0'
              >
                Actions
              </th>
            </tr>
          </thead>

          {assignments?.length > 0 ? (
            <tbody className='text-gray-600 fw-bold'>
              {assignments?.map((m, index) => (
                <tr key={index}>
                  <td className='text-center'>{index + 1}</td>
                  <td>{m.name}</td>
                  <td className='text-center'>
                    <span className='me-5' role='button' onClick={() => handleView(m, index)}>
                      <i className='fas fa-eye'></i>
                    </span>
                    {loadingButton && loadingIdx === index ? (
                      <span className='spinner-border spinner-border-sm' key={index}></span>
                    ) : (
                      <span onClick={() => downloadHandler(m, index)} role='button'>
                        <i className='fas fa-download'></i>
                      </span>
                    )}
                  </td>
                  <td className='text-center'>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant='light'
                        id='dropdown-basic'
                        className='btn btn-outline btn-outline-primary btn-active-light-primary btn-sm'
                      >
                        Actions
                      </Dropdown.Toggle>

                      <Dropdown.Menu
                        popperConfig={{
                          strategy: 'fixed',
                          onFirstUpdate: () => window.dispatchEvent(new CustomEvent('scroll')),
                        }}
                        className='menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                      >
                        <Dropdown.Item onClick={() => editHandler(index)}>
                          {menuAccess?.canCreate ? 'Edit' : 'View'}
                        </Dropdown.Item>
                        {menuAccess?.canDelete && (
                          <Dropdown.Item onClick={() => handleOpenConfirmation(m.id)}>
                            Delete
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody className='text-gray-600 fw-bold'>
              <tr>
                <td colSpan={4}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    {isLoading ? '' : ' No matching records found'}
                  </div>
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>

      <Confirm
        name={null}
        show={showDeleteAssignment}
        handleClose={handleCloseDelete}
        handleConfirm={handleDeleteAction}
      />

      <PreviewPdf
        name={selectedIndex !== null ? assignments[selectedIndex].fileName : null}
        url={`course-assignments/download/${
          selectedIndex !== null ? assignments[selectedIndex].fileUniqueId : null
        }`}
        show={showPreviewPdf}
        handleClose={handleClosePreviewPdf}
      />
    </KTCard>
  )
}

export default CourseFormAssignment
