import {AxiosError, AxiosResponse} from 'axios'
import {AuthModel, IBaseResponse, MenuModel, UserModel} from './_models'
import {toast} from 'react-toastify'
import moment from 'moment'
import {findItemInArray} from '../../../utils/array'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const OTP_LOCAL_STORAGE_KEY = 'kt-passcode-react-v'

export type OtpRequest = {
  guidToken: string
  otp: string
  captchaToken: string
  userId: number
}

export type OtpLocalStorage = {
  guid: string
  userId: number
  currentUser: UserModel
  apiToken: string
  captchaToken?: string
}

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const isOtpValid = () => {
  const useOtp = process.env.REACT_APP_USE_OTP?.toString() == 'true'

  if (!useOtp) return true

  const otp: string | null = localStorage.getItem(OTP_LOCAL_STORAGE_KEY)
  // console.log(otp)

  if (otp) return true

  return false
}

const getOtp = (): OtpLocalStorage | null => {
  if (!localStorage) return null

  const data = localStorage.getItem(OTP_LOCAL_STORAGE_KEY) ?? ''

  if (!data || data === '') return null

  return JSON.parse(data) as OtpLocalStorage
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const setOtp = (otp: string) => {
  if (!localStorage) return

  try {
    localStorage.setItem(OTP_LOCAL_STORAGE_KEY, otp)
  } catch (error) {
    console.error('Error while set OTP to storage', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.clear()
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
    localStorage.removeItem(OTP_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

function canAccessMenu(menu: MenuModel, path: string) {
  const pattern = new RegExp(menu.route)
  if (pattern.test(path)) {
    return true
  }

  if (menu.children) {
    return menu.children.some((child) => canAccessMenu(child, path))
  }

  return false
}

const getMenuAccess = (menuData: MenuModel[], path: string) => {
  // const menu = menuData.find((item) => canAccessMenu(item, path))
  const menu = findItemInArray(menuData, (item) => {
    if (item.route) {
      const pattern = new RegExp(item.route)
      return pattern.test(path)
    }
    return false
  })
  return menu
}

const showToastError = (message: string) => {
  toast.error(message, {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
    theme: 'dark',
  })
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string; 'X-OFFSET': number}}) => {
      const auth = getAuth()
      if (auth && auth.api_token) {
        config.headers.Authorization = `Bearer ${auth.api_token}`
      }

      config.headers['X-OFFSET'] = moment().utcOffset() / 60

      return config
    },
    (err: any) => {
      Promise.reject(err)
    }
  )
  axios.interceptors.response.use(
    (response: AxiosResponse<IBaseResponse<any>, any>) => {
      const contentType = response.headers['content-type']
      if (contentType && contentType.includes('application/json')) {
        if (response.data.code !== '0000') {
          return Promise.reject(response.data)
        }
      }
      return response
    },
    (error: AxiosError<IBaseResponse<any>>) => {
      if (error.response?.status === 401 && !window.location.pathname.includes('auth')) {
        removeAuth()
        return (window.location.href = '/auth')
      }

      if (error.response?.data) {
        return Promise.reject(error.response.data)
      }

      if (error.code == 'ERR_NETWORK') {
        showToastError(error.message)
      } else {
        showToastError('Terjadi kendala, mohon coba sesaat lagi')
      }

      return Promise.reject(error)
    }
  )
}

export {
  getAuth,
  setAuth,
  removeAuth,
  setOtp,
  isOtpValid,
  getOtp,
  AUTH_LOCAL_STORAGE_KEY,
  OTP_LOCAL_STORAGE_KEY,
  getMenuAccess,
  showToastError,
}
