import {useQuery} from 'react-query'
import {ClassEditModalForm} from './ClassEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getClassById} from '../core/_requests'
import React from 'react'

type props = {
  name?: string
}

const ClassEditModalFormWrapper: React.FC<props> = ({name}) => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: _class,
    error,
  } = useQuery(
    `${QUERIES.COURSE_LIST}-class-${itemIdForUpdate}`,
    () => {
      return getClassById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <ClassEditModalForm isLoading={isLoading} _class={{id: undefined}} name={name} />
  }

  if (!isLoading && !error && _class) {
    return <ClassEditModalForm isLoading={isLoading} _class={_class} name={undefined} />
  }

  return null
}

export {ClassEditModalFormWrapper}
