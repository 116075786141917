import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {ISchedule, ScheduleQueryPageResponse, ScheduleQueryResponse} from './_model'
import { IBaseResponse, IInfiteScrollData } from '../../../auth'

const API_URL = process.env.REACT_APP_BASE_API_URL
const URL = `${API_URL}/schedules`

const getSchedule = (query: string): Promise<ScheduleQueryResponse> => {
  return axios.get(`${URL}?${query}`).then((d: AxiosResponse<ScheduleQueryResponse>) => d.data)
}

const getClassmeetSchedule = (query: string): Promise<any> => {
  return axios
    .get(`${URL}/classmeet?${query}`)
    .then((d: AxiosResponse<ScheduleQueryResponse>) => d.data)
}

const getSelect = (query: string): Promise<IBaseResponse<IInfiteScrollData<ISchedule>>> => {
  return axios
    .get(`${URL}/select?${query}`)
    .then((d: AxiosResponse<IBaseResponse<IInfiteScrollData<ISchedule>>>) => d.data)
}

export {getSchedule, getClassmeetSchedule, getSelect}
