// @ts-nocheck
import {Column} from 'react-table'
import {ClassMeetType} from '../../core/_mode'
import {LecturerSkillCustomHeader} from './LecturerSkillCustomHeader'

const lecturerSkillColumns: ReadonlyArray<Column<ClassMeetType>> = [
  {
    Header: (props) => (
      <LecturerSkillCustomHeader
        tableProps={props}
        title='Skill'
        className='min-w-125px'
        enableSort={true}
      />
    ),
    accessor: 'skillName',
    id: 'skillName',
  },
  {
    Header: (props) => (
      <LecturerSkillCustomHeader
        tableProps={props}
        title='Total All Lecturers'
        className='min-w-125px'
        enableSort={true}
      />
    ),
    accessor: 'totalLecturer',
    id: 'totalLecturer',
  },
  {
    Header: (props) => (
      <LecturerSkillCustomHeader
        tableProps={props}
        title='Total Permanent Lecturers'
        className='min-w-125px'
        enableSort={true}
      />
    ),
    accessor: 'totalPermanentLecturer',
    id: 'totalPermanentLecturer',
  },
  {
    Header: (props) => (
      <LecturerSkillCustomHeader
        tableProps={props}
        title='Total Non-Permanent Lecturers'
        className='min-w-125px'
        enableSort={true}
      />
    ),
    accessor: 'totalNonPermanentLecturer',
    id: 'totalNonPermanentLecturer',
  },
]

export {lecturerSkillColumns}
