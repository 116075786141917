import {FC, useEffect, useRef, useState} from 'react'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import {fileToBase64} from '../utils/file'

const ImagePicker = ({initialValue, className, loading, onFileChange, showEdit, showDelete}: any) => {
  const defaultImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  const [imgSrc, setImgSrc] = useState(defaultImg)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [imageError, setImageError] = useState(false)

  useEffect(() => {
    setImageError(false)
    if (initialValue) {
      setImgSrc(`${initialValue}`)
    }
  }, [initialValue])

  const clickEditHandler = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const clickDeleteHandler = () => {
    setImgSrc(defaultImg)
    onFileChange()
  }

  const handleImageError = () => {
    setImageError(true) // Set the error state to true
  }

  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0]
    if (selectedFile) {
      try {
        const base64String = 'data:image/jpeg;base64,' + (await fileToBase64(selectedFile))
        onFileChange(event, base64String)
        setImageError(false)
        setImgSrc(base64String)
      } catch (error) {
        console.error('Error converting file to Base64:', error)
      }
    }
  }

  return (
    <div className={`position-relative ${className}`}>
      {loading && (
        <div
          className='position-absolute d-flex align-items-center justify-content-center w-100 h-100'
          style={{backgroundColor: 'rgba(255, 255, 255, 0.7)'}}
        >
          <div className='spinner-border' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      )}

      {imageError ? (
        <img
          src={defaultImg}
          className='w-100'
          style={{maxHeight: 200, objectFit: 'cover'}}
          alt='avatar'
        />
      ) : (
        <img
          src={imgSrc}
          className='w-100'
          style={{maxHeight: 200, objectFit: 'cover'}}
          alt='avatar'
          onError={handleImageError}
        />
      )}

      {showDelete && (
        <button
          type='button'
          className='btn btn-danger p-0 d-flex align-items-center justify-content-center text-center position-absolute'
          style={{
            border: '3px solid #fff',
            width: '32px',
            height: '32px',
            right: 4,
            top: 4,
            // borderRadius: '50%',
          }}
          onClick={clickDeleteHandler}
        >
          <i className='bi bi-trash-fill fs-9 p-0'></i>
        </button>
      )}

      {showEdit && (
        <button
          type='button'
          className='btn btn-circle btn-warning p-0 d-flex align-items-center justify-content-center text-center position-absolute'
          style={{
            border: '3px solid #fff',
            width: '52px',
            height: '52px',
            right: 0,
            bottom: 4,
            borderRadius: '50%',
          }}
          onClick={clickEditHandler}
        >
          <i className='bi bi-pencil-square p-0'></i>
        </button>
      )}
      <input
        type='file'
        ref={fileInputRef}
        onChange={onChange}
        style={{display: 'none'}}
        accept='image/jpeg, image/png'
      />
    </div>
  )
}

type ImageViewerProps = {
  className?: string
  width?: string | number
  height?: string | number
  maxHeight?: number
  imageClassName?: string
  initialValue?: any
  styles?: React.CSSProperties
}

const ImageViewer: FC<ImageViewerProps> = ({
  initialValue,
  className,
  width = '100%',
  height = 'auto',
  maxHeight = 200,
  imageClassName,
  styles,
}: any) => {
  const defaultImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  const [imgSrc, setImgSrc] = useState(defaultImg)
  const [imageError, setImageError] = useState(false)

  useEffect(() => {
    setImageError(false)
    if (initialValue) {
      setImgSrc(`${initialValue}`)
    }
  }, [initialValue])

  const handleImageError = () => {
    setImageError(true) // Set the error state to true
  }

  return (
    <div
      className={`position-relative ${className}`}
    >
      {imageError ? (
        <img
          className={`${imageClassName}`}
          src={defaultImg}
          style={{width, height, maxHeight, objectFit: 'cover', ...styles}}
          alt='avatar'
        />
      ) : (
        <img
          className={`${imageClassName}`}
          src={imgSrc}
          style={{width, height, maxHeight, objectFit: 'cover', ...styles}}
          alt='avatar'
          onError={handleImageError}
          key={imgSrc}
        />
      )}
    </div>
  )
}

export {ImagePicker, ImageViewer}
