import {Outlet, Route, Routes} from 'react-router-dom'
import AttendanceStudent from './components/AttendanceStudent'
import AttendanceStudentSuccess from './components/AttendanceStudentSuccess'
import AttendanceStudentLayout from './components/AttendanceStudentLayout'

const AttendancePage = () => {
  return (
    <Routes>
      <Route element={<AttendanceStudentLayout />}>
        <Route
          path='/success'
          element={
            <>
              <AttendanceStudentSuccess message='You successfully attended this class' />
            </>
          }
        />
        <Route
          path='/:token'
          element={
            <>
              <AttendanceStudent />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default AttendancePage
