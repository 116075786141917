import {useEffect, useRef, useState} from 'react'
import {Class, ClassStudent, initialClass, initialClassStudent} from '../../class-list/core/_models'
import {FieldArray, Form, Formik, FormikValues} from 'formik'
import {
  getClassById,
  getStudentsFromCohort,
  getStudentsFromFilter,
  getStudentsFromGroup,
  getStudentsFromMajor,
  updateClass,
} from '../../class-list/core/_requests'
import {KTCard, KTIcon, stringifyRequestQuery} from '../../../../../_metronic/helpers'
import {ClassListLoading} from '../../class-list/components/loading/ClassListLoading'
import ClassFormStudentSelect from './ClassFormStudentSelect'
import {useQuery} from 'react-query'
import StudentMajorSelect from '../../../students/components/StudentMajorSelect'
import GroupClassSelect from '../../../students/student-major-modal/components/GroupClassSelect'
import {getGroupClassSelect} from '../../../students/student-major-modal/core/_requests'
import {useLocation} from 'react-router-dom'
import {useAuth, MenuModel, getMenuAccess} from '../../../auth'
import LoadingButtonWrapper from '../../../../components/LoadingButtonWrapper'
import {getClassStudent, updateClassStudent} from '../core/_request'
import StudentAddFromModal from './StudentAddFromModal'
import moment from 'moment'
import {generateGUID} from '../../../../utils/string'
import qs from 'qs'
import clsx from 'clsx'
import {toQueryString} from '../../../../../_metronic/helpers/UrlHelper'
import {Confirm} from '../../../../components/Confirm'

function ClassFormStudent({id, data}) {
  const [isLoading, setIsLoading] = useState(false)
  const [classForEdit, setClassForEdit] = useState<Class>({...initialClass, id: id})
  // const [classStudent, setClassStudent] = useState<ClassStudent>(initialClassStudent)
  const containerRef = useRef<any>(null)
  const [group, setGroup] = useState<number>()
  const {pathname} = useLocation()
  const {currentMenu} = useAuth()
  const [menuAccess, setMenuAccess] = useState<MenuModel | undefined>(undefined)
  useEffect(() => {
    setMenuAccess(getMenuAccess(currentMenu ?? [], pathname))
  }, [currentMenu])
  const [showAddFromModal, setShowAddFromModal] = useState(false)
  const [values, setValues] = useState<any>()
  const [push, setPush] = useState<any>()
  const [showConfirm, setShowConfirm] = useState(false)
  const [formValues, setFormValues] = useState<ClassStudent[]>([])
  const formikRef = useRef<any>(null)

  // useEffect(() => {
  //   if (data) {
  //     setClassForEdit(data)
  //     setClassStudent({
  //       classId: id || initialClassStudent.classId,
  //       studentMajorId: initialClassStudent.studentMajorId,
  //       name: initialClassStudent.name,
  //       nim: initialClassStudent.nim,
  //       state: initialClassStudent.state,
  //     })
  //     // console.log(classForEdit.classStudents)
  //   }
  // }, [data])
  useEffect(() => {
    refetch()
  }, [])

  const {refetch} = useQuery(
    'class-student',
    async () => {
      try {
        const response = await getClassStudent(
          id,
          stringifyRequestQuery({
            filter: {
              includeDeleted: true,
            },
          })
        )
        if (response.code === '0000') {
          setClassForEdit((prev) => {
            const newInstance = {...prev}
            for (const item of response.data || []) {
              item.guid = generateGUID()
            }
            newInstance.classStudents = response.data
            return newInstance
          })
        } else {
          setClassForEdit((prev) => {
            const newInstance = {...prev}
            newInstance.classStudents = []
            return newInstance
          })
        }
      } catch (ex) {
        console.log(ex)
      }
    },
    {enabled: true}
  )

  const addNewStudent = () => {
    setClassForEdit((prev) => {
      const newInstance = {...prev}
      let students = [...(newInstance.classStudents || [])]
      students = [{...initialClassStudent}, ...students]
      newInstance.classStudents = students

      return newInstance
    })
  }

  const scrollToBottom = () => {
    setTimeout(() => {
      if (containerRef.current) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight
      }
    }, 100)
  }

  // const handleGroupChange = (selected) => {
  //   setGroup(selected?.value)
  // }

  const inputFromGroup = async (groupId: number) => {
    setIsLoading(true)
    const resp = await getStudentsFromGroup(groupId, data.semesterId)
    if (resp.code === '0000' && resp.data) {
      for (const item of resp.data) {
        if (!values?.classStudents?.find((q) => q.studentMajorId === item.id)) {
          const newItem = {...initialClassStudent}
          newItem.nim = item.nim
          newItem.studentMajorId = item.id
          newItem.name = item.name
          newItem.email = item.email
          newItem.guid = generateGUID()

          push(newItem)
        }
      }

      scrollToBottom()
    }
    setIsLoading(false)
  }

  const inputFromCohort = async (cohort: number) => {
    setIsLoading(true)
    const resp = await getStudentsFromCohort(cohort, data.semesterId)
    if (resp.code === '0000' && resp.data) {
      for (const item of resp.data) {
        if (!values?.classStudents?.find((q) => q.studentMajorId === item.id)) {
          const newItem = {...initialClassStudent}
          newItem.nim = item.nim
          newItem.studentMajorId = item.id
          newItem.name = item.name
          newItem.email = item.email
          newItem.guid = generateGUID()

          push(newItem)
        }
      }

      scrollToBottom()
    }
    setIsLoading(false)
  }

  const inputFromMajor = async () => {
    setIsLoading(true)
    const resp = await getStudentsFromMajor(data.majorId, data.semesterId)
    if (resp.code === '0000' && resp.data) {
      for (const item of resp.data) {
        if (!values?.classStudents?.find((q) => q.studentMajorId === item.id)) {
          const newItem = {...initialClassStudent}
          newItem.nim = item.nim
          newItem.studentMajorId = item.id
          newItem.name = item.name
          newItem.email = item.email
          newItem.guid = generateGUID()

          push(newItem)
        }
      }

      scrollToBottom()
    }
    setIsLoading(false)
  }

  const inputFromFilter = async (groupId?: number, cohort?: number, majorId?: number) => {
    setIsLoading(true)
    const resp = await getStudentsFromFilter(
      qs.stringify(
        {
          semesterId: data.semesterId,
          majorId,
          cohort,
          groupId,
        },
        {skipNulls: true}
      )
    )
    if (resp.code === '0000' && resp.data) {
      for (const item of resp.data) {
        if (!values?.classStudents?.find((q) => q.studentMajorId === item.id)) {
          const newItem = {...initialClassStudent}
          newItem.nim = item.nim
          newItem.studentMajorId = item.id
          newItem.name = item.name
          newItem.email = item.email
          newItem.guid = generateGUID()

          push(newItem)
        }
      }

      scrollToBottom()
    }
    setIsLoading(false)
  }

  // from?: 'group' | 'cohort' | 'major',
  const handleAddFrom = (submit: boolean, groupId?: number, cohort?: number, majorId?: number) => {
    // if (from === 'group' && groupId) {
    //   inputFromGroup(groupId)
    // } else if (from === 'cohort' && cohort) {
    //   inputFromCohort(cohort)
    // } else if (from === 'major') {
    //   inputFromMajor()
    // }

    if (submit) {
      inputFromFilter(groupId, cohort, majorId)
    }

    setShowAddFromModal(false)
  }

  const handleShowAddFrom = (values: any, push: any) => {
    setValues(values)
    setPush(() => push)
    setShowAddFromModal(true)
  }

  const save = async () => {
    formikRef.current.setSubmitting(true)
    try {
      await updateClassStudent(id, formValues)
      refetch()
      formikRef.current.setSubmitting(true)
    } catch (ex: any) {
      console.error(ex)
      formikRef.current.setStatus(ex.message)
    } finally {
      setFormValues([])
      setShowConfirm(false)
      formikRef.current.setSubmitting(false)
    }
  }

  return (
    <KTCard>
      <div className='px-10 py-8'>
        <Formik
          innerRef={formikRef}
          initialValues={classForEdit}
          enableReinitialize={true}
          onSubmit={async (values, actions) => {
            // const models = {...values}
            const models = values.classStudents?.filter((f) => f.studentMajorId) || []
            const data = models.map((m) => ({
              id: m.id,
              studentMajorId: m.studentMajorId,
              classId: m.classId,
              allowDelete: m.allowDelete,
              state: m.state,
              nim: m.nim,
              name: m.name,
              dirty: m.dirty,
            }))

            setFormValues(data ?? [])
            setShowConfirm(true)
          }}
        >
          {({values, isSubmitting, status, setFieldValue, getFieldProps}) => (
            <Form>
              {status ? (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{status}</div>
                </div>
              ) : (
                ''
              )}
              <FieldArray name='classStudents'>
                {({push, remove, unshift, form}) => (
                  <>
                    <div className='mb-8 d-flex'>
                      {menuAccess?.canCreate && (
                        <>
                          <button
                            type='button'
                            className='btn btn-warning'
                            onClick={() => {
                              push({...initialClassStudent})
                              scrollToBottom()
                            }}
                          >
                            Add Student
                          </button>
                          {/*<GroupClassSelect*/}
                          {/*  isUseFormik={false}*/}
                          {/*  selectedValue={group}*/}
                          {/*  className='ms-4 w-250px'*/}
                          {/*  onChange={handleGroupChange}*/}
                          {/*/>*/}
                          <button
                            type='button'
                            className='btn btn-warning ms-2'
                            onClick={() => handleShowAddFrom(values, push)}
                            // disabled={!group}
                          >
                            Add From
                          </button>
                        </>
                      )}
                    </div>
                    <div
                      className='table-responsive scroll-y'
                      style={{height: 'calc(100vh - 452px)'}}
                      ref={containerRef}
                    >
                      <table className='table table-striped table-row-gray-300 align-middle gs-0 gy-4 header-fix'>
                        <thead>
                          <tr className='fw-bold text-muted'>
                            <th className='text-center'>No</th>
                            <th className='min-w-150px'>Student</th>
                            <th className='min-w-140px'>NIM</th>
                            <th className='min-w-120px'>Email</th>
                            <th className='min-w-100px text-center'>Actions</th>
                          </tr>
                        </thead>
                        {values?.classStudents?.length != 0 ? (
                          <tbody>
                            {values?.classStudents?.map((item, index) => {
                              return (
                                <tr
                                  key={index}
                                  className={clsx({
                                    'table-warning': item.isNew,
                                    'table-danger': item.state === 2,
                                    'table-success': item.state === 4,
                                  })}
                                >
                                  <td className='text-center'>{index + 1}</td>
                                  <td>
                                    <div>
                                      <ClassFormStudentSelect
                                        id={`classStudents.${index}.studentMajorId`}
                                        name={`classStudents.${index}.name`}
                                        nim={`classStudents.${index}.nim`}
                                        email={`classStudents.${index}.email`}
                                        majorId={data?.majorId}
                                        majorStatusId={1}
                                        excludedIds={
                                          values.classStudents
                                            ? values.classStudents
                                                ?.filter(
                                                  (q) =>
                                                    q.state !== 2 &&
                                                    q.studentMajorId !==
                                                      values.classStudents?.[index].studentMajorId
                                                )
                                                .map((m) => m.studentMajorId ?? 0)
                                            : []
                                        }
                                        initialOptions={
                                          item && item.studentMajorId
                                            ? [{value: item.studentMajorId, label: item.name}]
                                            : []
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td>{item.nim}</td>
                                  <td>{item.email}</td>
                                  <td width={150}>
                                    {item.state !== 2 && (
                                      <div className='fv-row text-center me-2'>
                                        <div className='d-flex justify-content-center flex-shrink-0'>
                                          <button
                                            type='button'
                                            onClick={() => {
                                              if (item.id) {
                                                setFieldValue(`classStudents.${index}.state`, 2)
                                                setFieldValue(`classStudents.${index}.dirty`, true)
                                              } else {
                                                remove(index)
                                              }
                                            }}
                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                          >
                                            <KTIcon iconName='trash' className='fs-3'></KTIcon>
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                    {item.state === 2 && (
                                      <div className='fv-row text-center me-2'>
                                        <div className='d-flex justify-content-center flex-shrink-0'>
                                          <button
                                            type='button'
                                            onClick={() =>
                                              setFieldValue(`classStudents.${index}.state`, 4)
                                            }
                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                          >
                                            <KTIcon iconName='check' className='fs-3'></KTIcon>
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        ) : (
                          <tbody className='text-gray-600 fw-bold'>
                            <tr>
                              <td colSpan={5}>
                                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                  {isLoading ? '' : ' No matching records found'}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </div>
                  </>
                )}
              </FieldArray>
              <div className='text-end pt-15'>
                {menuAccess?.canCreate && (
                  <LoadingButtonWrapper isLoading={isSubmitting || isLoading}>
                    <button
                      type='submit'
                      className='btn btn-warning'
                      disabled={isSubmitting || isLoading || values?.classStudents?.length == 0}
                    >
                      <span className='indicator-label'>Submit</span>
                    </button>
                  </LoadingButtonWrapper>
                )}
              </div>
              {(isSubmitting || isLoading) && <ClassListLoading />}
            </Form>
          )}
        </Formik>
      </div>
      {data?.semesterId && data?.majorName && data?.degreeId && (
        <StudentAddFromModal
          show={showAddFromModal}
          semesterId={data.semesterId}
          majorId={data.majorId}
          majorName={data.majorName}
          onClose={(submit) => handleAddFrom(submit)}
          degreeId={data.degreeId}
        />
      )}
      <Confirm
        show={showConfirm}
        handleClose={() => setShowConfirm(false)}
        handleConfirm={save}
        name={'Confirm'}
        body={
          <div
            style={{
              maxHeight: 400,
              overflow: 'auto',
            }}
          >
            <div className='fs-5'>Are you sure you want to update this students?</div>
            {formValues.some((f) => f.dirty && f.state === 2) && (
              <div className='mt-6'>
                <div className=' fs-5 mb-2'>You deleted this students:</div>
                <ul>
                  {formValues
                    .filter((f) => f.dirty && f.state === 2)
                    .map((m, index) => (
                      <li key={index}>
                        {m.nim} - {m.name}
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </div>
        }
      />
    </KTCard>
  )
}

export default ClassFormStudent
