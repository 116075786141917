import {FC, useContext, useEffect, useMemo, useState} from 'react'
import {
  PaginationState,
  QUERIES,
  WithChildren,
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  stringifyRequestQuery,
} from '../../../../../../_metronic/helpers'
import {useQueryRequest} from './QueryRequestProvider'
import {useQuery} from 'react-query'
import {SurveyStudentInfo} from './_model'
import {getSurveyStudentInfo} from './_request'

const QueryResponseContext = createResponseContext<SurveyStudentInfo>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  useEffect(() => {
    const q = stringifyRequestQuery(state)
    if (q === query) {
      refetch()
    }
  }, [state])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.REPORT_SURVEY_STUDENT_INFO}-${query}`,
    () => {
      return getSurveyStudentInfo(query)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse()
  if (!response || !response.data) {
    return defaultPaginationState
  }

  const {pageNumber, pageSize, totalRecordsFiltered} = response.data
  const pagination: PaginationState = {
    totalRecordsFiltered: totalRecordsFiltered ?? 0,
    pageNumber: pageNumber ?? 0,
    pageSize: pageSize ?? 0,
    links: [],
  }

  return pagination
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
