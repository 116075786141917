/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {ID, KTIcon, QUERIES} from '../../../../../../_metronic/helpers'
import {MenuModel, getMenuAccess, useAuth} from '../../../../auth'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteCourse, reactiveCourse} from '../../core/_requests'
import {CourseDeleteConfirm} from '../../course-delete-confirm/CourseDeleteConfirm'
import {CourseDuplicateModal} from '../../course-duplicate-modal/CourseDuplicateModal'
import {CourseReactiveConfirm} from '../../course-reactive-confirm/CourseReactiveConfirm'
import {CourseClassModal} from '../../course-class-modal/CourseClassModal'

type Props = {
  id: ID
  degreeId: number
  name: string
  status: number
}

const CourseActionsCell: FC<Props> = ({id, degreeId, name, status}) => {
  const {setItemIdForUpdate} = useListView()
  const {query, refetch} = useQueryResponse()
  const queryClient = useQueryClient()
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const navigate = useNavigate()
  const [showDuplicateModal, setShowDuplicateModal] = useState(false)
  const {pathname} = useLocation()
  const {currentMenu} = useAuth()
  const [menuAccess, setMenuAccess] = useState<MenuModel | undefined>(undefined)
  const [showClasses, setShowClasses] = useState(false)

  useEffect(() => {
    setMenuAccess(getMenuAccess(currentMenu ?? [], pathname))
  }, [currentMenu])

  const handleCloseConfirmation = () => setShowConfirmation(false)

  const handleConfirmAction = async () => {
    // Perform your action here after confirmation
    setIsLoading(true)
    try {
      await deleteItem.mutateAsync()
    } catch (err: any) {
      setError(err.message)
    }
  }

  const handleConfirmReactive = async () => {
    // Perform your action here after confirmation
    setIsLoading(true)
    try {
      await reactiveItem.mutateAsync()
    } catch (err: any) {
      setError(err.message)
    }
  }

  const handleOpenConfirmation = () => {
    setError(null)
    setShowConfirmation(true)
  }

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    // setItemIdForUpdate(id)
    navigate('/courses/' + id)
  }

  const deleteItem = useMutation(() => deleteCourse(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      setIsLoading(false)
      handleCloseConfirmation()
      queryClient.invalidateQueries([`${QUERIES.COURSE_LIST}-${query}`])
    },
    onError: (err: any) => {
      setError(err.message)
      setIsLoading(false)
    },
  })

  const reactiveItem = useMutation(() => reactiveCourse(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      setIsLoading(false)
      handleCloseConfirmation()
      queryClient.invalidateQueries([`${QUERIES.COURSE_LIST}-${query}`])
    },
    onError: (err: any) => {
      setError(err.message)
      setIsLoading(false)
    },
  })

  const openDuplicateCourseModal = () => {
    setShowDuplicateModal(true)
  }
  const handleCloseDuplicateCourse = () => {
    setShowDuplicateModal(false)
  }
  const handleDuplicateSuccess = () => {
    setShowDuplicateModal(false)
    refetch()
  }

  return (
    <>
      <a
        href='#'
        className='btn btn-outline btn-outline-primary btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTIcon iconName='down' className='fs-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            {menuAccess?.canCreate ? 'Edit' : 'View'}
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        {status === 1 && (
          <div className='menu-item px-3'>
            {/* <Link to={`../class/list/${id}`} className='menu-link px-3'>
              Class
            </Link> */}
            <a className='menu-link px-3' data-kt-course-table-filter='delete_row' href={'#' + id}>
              Class
            </a>
          </div>
        )}
        {/* end::Menu item */}

        {/* begin::Menu item */}
        {menuAccess?.canCreate && (
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-course-table-filter='delete_row'
              onClick={openDuplicateCourseModal}
            >
              Duplicate
            </a>
          </div>
        )}
        {/* end::Menu item */}

        {/* begin::Menu item */}
        {menuAccess?.canDelete && (
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-course-table-filter='delete_row'
              // onClick={async () => await deleteItem.mutateAsync()}
              onClick={handleOpenConfirmation}
            >
              {status === 1 ? 'Delete' : 'Reactive'}
            </a>
          </div>
        )}
        {/* end::Menu item */}
      </div>
      <CourseDeleteConfirm
        show={showConfirmation && status === 1}
        handleClose={handleCloseConfirmation}
        handleConfirm={handleConfirmAction}
        name={name}
        isLoading={isLoading}
        error={error}
      />

      <CourseDuplicateModal
        courseId={id}
        degreeId={degreeId}
        show={showDuplicateModal}
        onClose={handleCloseDuplicateCourse}
        onSuccess={handleDuplicateSuccess}
      />

      <CourseReactiveConfirm
        show={showConfirmation && status !== 1}
        handleClose={handleCloseConfirmation}
        handleConfirm={handleConfirmReactive}
        name={name}
        isLoading={isLoading}
        error={error}
      />

      {/* end::Menu */}
    </>
  )
}

export {CourseActionsCell}
