import React, {useEffect, useState} from 'react'
import {Pagination} from 'react-bootstrap'

interface AppPaginationProps {
  totalPages: number
  currentPage: number
  onPageChange: (page: number) => void
}

const AppPagination: React.FC<AppPaginationProps> = ({totalPages, currentPage, onPageChange}) => {
  const [activePage, setActivePage] = useState(currentPage)

  const handlePageChange = (page: number) => {
    setActivePage(page)
    onPageChange(page)
  }

  React.useEffect(() => {
    setActivePage(currentPage)
  }, [currentPage])

  const renderPaginationItems = () => {
    const items: JSX.Element[] = []
    const maxVisiblePages = 5

    if (totalPages <= 1) {
      return <></>
    }

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        items.push(
          <Pagination.Item key={i} active={i === activePage} onClick={() => handlePageChange(i)}>
            {i}
          </Pagination.Item>
        )
      }
    } else {
      const leftEllipsis = activePage > Math.floor(maxVisiblePages / 2) + 1
      const rightEllipsis = activePage < totalPages - Math.floor(maxVisiblePages / 2)

      items.push(<Pagination.First key='first' onClick={() => handlePageChange(1)} />)

      if (leftEllipsis) {
        items.push(<Pagination.Ellipsis key='ellipsis-start' disabled />)
      }

      let startPage = leftEllipsis ? activePage - Math.floor(maxVisiblePages / 2) : activePage - 1
      let endPage = rightEllipsis ? activePage + Math.floor(maxVisiblePages / 2) : activePage + 1

      for (let i = startPage; i <= endPage; i++) {
        if (i > 0 && i <= totalPages) {
          items.push(
            <Pagination.Item key={i} active={i === activePage} onClick={() => handlePageChange(i)}>
              {i}
            </Pagination.Item>
          )
        }
      }

      if (rightEllipsis) {
        items.push(<Pagination.Ellipsis key='ellipsis-end' disabled />)
        items.push(<Pagination.Last key='last' onClick={() => handlePageChange(totalPages)} />)
      }
    }

    return items
  }

  return (
    <div className='my-5'>
      <Pagination>{renderPaginationItems()}</Pagination>
    </div>
  )
}

export default AppPagination
