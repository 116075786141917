import {LecturerSkillSearch} from './LecturerSkillSearch'
import {LecturerSkillToolbar} from './LecturerSkillToolbar'

const LecturerSkillHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <LecturerSkillSearch />
      <div className='card-toolbar'>
        <LecturerSkillToolbar />
      </div>
    </div>
  )
}

export {LecturerSkillHeader}
