import {FC, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAnnouncement} from './announcement-modal/AnnouncementProvider'
import {AnnouncementModal} from './announcement-modal/AnnouncementModal'
import {useQuery} from 'react-query'
import Skeleton from 'react-loading-skeleton'
import {getActiveAnnouncement, getLatestAnnouncement} from '../core/_request'
import {formatDateTime} from '../../../utils/date'
import {Carousel} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'
import clsx from 'clsx'
import {ImageViewer} from '../../../components/ImagePicker'
import {getAnnouncementImageUrl} from '../../../utils/img'

const AnnouncementCard: FC = () => {
  const {isShowed, setShowed} = useAnnouncement()
  const [id, setId] = useState<number | undefined>(0)

  const {data, isError, error, isLoading} = useQuery(
    ['get-latest-announcement'],
    () => getActiveAnnouncement(),
    {
      select: (d) => d.data,
    }
  )

  const styles: React.CSSProperties | any = {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 5,
    WebkitBoxOrient: 'vertical',
    textAlign: 'justify',
  }

  if (isError) {
    // @ts-ignore
    return <p>{error?.message}</p>
  }

  if (isLoading) {
    return <Skeleton count={1} style={{height: 230}} />
  }

  const showDetail = (val: number | undefined) => {
    setShowed(true)
    setId(val)
  }

  return (
    <>
      <h4 className='card-title d-flex align-items-start flex-column mb-5'>
        <span className='card-label fw-bold text-gray-800'>Announcements</span>
      </h4>
      <div className='card overflow-hidden'>
        <Carousel interval={null}>
          {data?.length === 0 && (
            <Carousel.Item
              className='announcement-carousel-item'
              style={{
                height: '100%',
                backgroundColor: '#233260',
                borderRadius: 12,
                cursor: 'pointer',
              }}
            >
              <Carousel.Caption>
                <h3 style={{color: '#fff'}}>No Announcement</h3>
                <p>-</p>
              </Carousel.Caption>
            </Carousel.Item>
          )}

          {data?.map((v, i) => (
            <Carousel.Item
              className='announcement-carousel-item'
              key={i}
              onClick={() => showDetail(v.id)}
              style={{
                height: '100%',
                backgroundColor: '#233260',
                borderRadius: 12,
                cursor: 'pointer',
              }}
            >
              {v.coverUniqueId ? (
                <ImageViewer
                  imageClassName='announcement-carousel-item'
                  className='w-100 announcement-carousel-item'
                  initialValue={getAnnouncementImageUrl(v.coverUniqueId)}
                />
              ) : (
                <Carousel.Caption>
                  <h3 style={{color: '#fff'}}>{v.title}</h3>
                  <p>{v.shortDescription}</p>
                </Carousel.Caption>
              )}
            </Carousel.Item>
          ))}
        </Carousel>

        {/* {data?.map((v, i) => {
          return (
            <div
              className={clsx('d-flex align-items-center me-3 py-8 px-8', {
                'border-bottom': i < data.length - 1,
              })}
            >
              <div className='symbol symbol-40px me-2'>
                <div className='symbol-label fs-2 fw-semibold bg-primary text-inverse-danger'>
                  <i className='fas fa-bullhorn' style={{color: '#fff'}}></i>
                </div>
              </div>
              <div className='flex-grow-1 ms-4'>
                <a href='#' className='text-gray-800 text-hover-primary fs-5 fw-bold lh-0'>
                  {v?.title}
                </a>
                <span className='text-gray-500 fw-semibold d-block fs-6'>
                  {formatDateTime(v.date)}
                </span>
              </div>
              <div>
                <button type='button' className='btn btn-light btn-sm' onClick={() => showDetail(v.id)}>
                  Show Detail
                </button>
              </div>
            </div>
          )
        })} */}
      </div>

      {/* <div className='d-flex justify-content-center flex-column me-3 mb-3'>
        <p className='fs-5 fw-bolder text-gray-700 text-hover-primary me-1 mb-2 mt-4 lh-1'>
          Announcements
        </p>
      </div>
      <div className='card'>
        <div className='card-body'>
          {data?.map((v, i) => {
            return (
              <>
                <span className='card-text fs-5 ms-3'>{formatDateTime(v.date)}</span>
                <p
                  role='button'
                  onClick={() => showDetail(v.id)}
                  className='card-text me-8 ms-3 fs-4 text-decoration-underline'
                  style={styles}
                  key={i}
                >
                  {v?.title}
                </p>
              </>
            )
          })}
          <Link to={''} className='card-link cursor-pointer' style={{float: 'right'}}>
            See More
          </Link>
        </div>
      </div> */}
      {isShowed && <AnnouncementModal data={data?.find((x) => x.id === id)}></AnnouncementModal>}
    </>
  )
}

export default AnnouncementCard
