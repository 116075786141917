import {getDate} from 'date-fns'
import {formatDateTime, getDateFormatted} from '../../../utils/date'
import clsx from 'clsx'
import {FC, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {Button, Modal} from 'react-bootstrap'
import {getInitialString, pascalCaseToSpace} from '../../../utils/string'
import {viewFile} from '../../../utils/file'
import {showError} from '../../../utils/toast'
import {useLocation} from 'react-router-dom'

type Prop = {
  items: any[]
  show: boolean
  date?: string | null
  handleClose(): void
}

const ScheduleCardDetail: FC<Prop> = ({show, date, handleClose, items = []}) => {
  const [isView, setView] = useState<boolean>(false)
  const listType = [
    {
      code: 'Assignment',
      name: 'Assignment',
    },
    {
      code: 'CampusEvent',
      name: 'Campus Event',
    },
    {
      code: 'Meeting',
      name: 'Meeting',
    },
    {
      code: 'PublicHoliday',
      name: 'Public Holiday',
    },
    {
      code: 'Quiz',
      name: 'Quiz',
    },
  ]

  const handleView = async (unique: string) => {
    try {
      setView(true)
      await viewFile(`courses/download/${unique}`)
    } catch (err: any) {
      showError(err?.message)
    } finally {
      setView(false)
    }
  }

  const dateInfo = (schedule) => {
    switch (schedule.typeId) {
      case 2:
      case 3:
        return (
          <li>
            <KTIcon iconName='calendar' /> Deadline: {formatDateTime(schedule.endDate)}
          </li>
        )
      case 4:
      case 5:
        return (
          <li>
            <KTIcon iconName='calendar' /> {schedule.dateInfoStr}
          </li>
        )
      default:
        return (
          <>
            <li>
              <KTIcon iconName='time' /> {getDateFormatted(schedule.startDate, 'HH:mm')} -{' '}
              {getDateFormatted(schedule.endDate, 'HH:mm')}
            </li>
          </>
        )
    }
  }

  const scheduleContent = (schedule) => {
    switch (schedule.typeId) {
      case 6: // class meet
        return classMeeting(schedule)
      case 2: // student-assesment
      case 3:
        return studentAssesment(schedule)
      default:
        return academicCalendar(schedule)
    }
  }

  const classMeeting = (schedule) => {
    return (
      <>
        <div
          className='d-flex justify-content-between'
          role={schedule.classId ? 'button' : undefined}
          onClick={() =>
            schedule.classId ? window.open(`/classes/${schedule.classId}`, '_blank') : () => {}
          }
        >
          <div>
            <h4 className='text-hover-primary'>{schedule.className ?? schedule.courseName}</h4>
            <div className='mb-5'>
              <h5 style={{fontWeight: 500}}>{schedule.classCode}</h5>
            </div>
          </div>
          <div className='schedule-indicator'>
            <div className={clsx(`schedule-type-pill`, `pill-${schedule.typeName}`)}>
              {getInitialString(pascalCaseToSpace(schedule.typeName))}
            </div>
          </div>
        </div>
        <ul className='schedule-info-list'>
          {schedule.semesterName && (
            <li>
              <div>
                <KTIcon iconName='receipt-square' /> {schedule.semesterName}
              </div>
            </li>
          )}
          {/* <li>
            <div>
              <KTIcon iconName='book' /> {schedule.name}
            </div>
          </li> */}
          <li>
            <div>
              <KTIcon iconName='information' /> {schedule.meetingTypeName}
            </div>
          </li>
          <li>
            <div>
              <KTIcon iconName='geolocation-home' /> {schedule.roomName || '-'}
            </div>
          </li>
          <li>
            <div>
              <KTIcon iconName='user-square' />
              <span className='ps-1'>
                {schedule.totalAttendancesStudent || '-'} / {schedule.totalStudents || '-'}
              </span>
            </div>
          </li>
          <li>
            <div>
              <KTIcon iconName='teacher' /> {schedule.lecturerName || '-'}
            </div>
          </li>
          {dateInfo(schedule)}
        </ul>
      </>
    )
  }

  const studentAssesment = (schedule) => {
    return (
      <>
        <div className='d-flex justify-content-between'>
          <div>
            <h4>{schedule.className ?? schedule.courseName}</h4>
            <div className='mb-5'>
              <h5 style={{fontWeight: 500}}>{schedule.classCode}</h5>
            </div>
          </div>
          <div className='schedule-indicator'>
            <div className={clsx(`schedule-type-pill`, `pill-${schedule.typeName}`)}>
              {getInitialString(pascalCaseToSpace(schedule.typeName))}
            </div>
          </div>
        </div>
        <ul className='schedule-info-list'>
          {schedule.semesterName && (
            <li>
              <div>
                <KTIcon iconName='receipt-square' /> {schedule.semesterName}
              </div>
            </li>
          )}
          <li>
            <div>
              <KTIcon iconName='book' /> {schedule.name}
            </div>
          </li>
          <li>
            <div>
              <KTIcon iconName='user-square' />
              <span className='ps-1'>
                {schedule.totalAttendancesStudent || '-'} / {schedule.totalStudents || '-'}
              </span>
            </div>
          </li>
          {dateInfo(schedule)}
        </ul>
      </>
    )
  }

  const academicCalendar = (schedule) => {
    return (
      <>
        <div className='d-flex justify-content-between'>
          <div>
            <h4>{schedule.name}</h4>
            <div className='mb-5'>{schedule.description}</div>
          </div>
          <div className='schedule-indicator'>
            <div
              className={clsx('schedule-type-pill', `pill-${schedule.typeName}`, {
                'mb-6': schedule.uniqueId !== null,
              })}
            >
              {getInitialString(pascalCaseToSpace(schedule.typeName))}
            </div>
          </div>
        </div>
        <ul className='schedule-info-list'>
          {schedule.semesterName && (
            <li>
              <div>
                <KTIcon iconName='receipt-square' /> {schedule.semesterName}
              </div>
            </li>
          )}
          {dateInfo(schedule)}
          {schedule.uniqueId && (
            <div
              className='btn btn-outline btn-outline-primary btn-sm mt-2'
              onClick={async () => {
                if (!isView) {
                  await handleView(schedule.uniqueId ?? '')
                }
              }}
            >
              {!isView ? (
                <>
                  <i className='fas fa-eye fs-6' /> View Attachment
                </>
              ) : (
                <span>Loading...</span>
              )}
            </div>
          )}
        </ul>
      </>
    )
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Schedules {date && getDateFormatted(date, 'DD MMMM Y')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='pt-2 pb-2'>
        <div className='border-bottom'>
          <ul
            className='p-0 mt-1 mb-1'
            style={{listStyleType: 'none', display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)'}}
          >
            {listType.map((m, i) => (
              <li className='d-flex mb-3' key={i}>
                <div className={clsx(`schedule-type-pill`, `pill-${m.code}`)}>
                  {getInitialString(pascalCaseToSpace(m.code))}
                </div>
                <div className='ps-2'>{m.name}</div>
              </li>
            ))}
          </ul>
        </div>
        <div style={{color: '#000', height: 'calc(100vh - 240px)', overflow: 'auto'}}>
          {items?.map((item, index: number) => (
            <div
              key={index}
              className={clsx('d-flex', {'border-bottom': index !== items.length - 1})}
            >
              {/* <div
                className='p-2 pt-4 d-flex flex-column align-items-center border-end mb-5'
                style={{width: 60}}
              >
                <div>{getDateFormatted(item.date, 'ddd')}</div>
                <div>
                  <h1>{getDate(item.date)}</h1>
                </div>
              </div> */}
              <div className='flex-grow-1'>
                {item.items.map((schedule, scheduleIndex) => (
                  <div
                    key={scheduleIndex}
                    className={clsx('ps-12 pe-5 pb-3 pt-5 position-relative', {
                      'border-bottom': scheduleIndex !== item.items.length - 1,
                    })}
                  >
                    {scheduleContent(schedule)}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer> */}
    </Modal>
  )
}

export default ScheduleCardDetail
