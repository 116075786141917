import {FC, useCallback, useEffect, useState} from 'react'
import {getMenuAccess, MenuModel, showToastError, useAuth} from '../../../auth'
import {useLocation} from 'react-router-dom'
import {bytesToSize, downloadFile, viewFile} from '../../../../utils/file'
import {showError, showSuccess} from '../../../../utils/toast'
import {useMutation, useQuery} from 'react-query'
import {
  deleteCourseMaterial,
  getMaterialCourseByCourseId,
  updateCourseMaterial,
  uploadCourseMaterial,
} from '../../course-list/core/_requests'
import Dropzone, {Accept} from 'react-dropzone'
import {TruncateString} from '../../../../utils/string'
import LoadingButtonWrapper from '../../../../components/LoadingButtonWrapper'
import {Confirm} from '../../../../components/Confirm'
import {PreviewPdf} from '../../../../components/PreviewPdf'
import {CourseListLoading} from '../../course-list/components/loading/CourseListLoading'
import {useFormik} from 'formik'
import {KTCard} from '../../../../../_metronic/helpers'
import {CourseMaterial, initialCourseMaterial} from '../../course-list/core/_models'

type CourseFormMaterialProps = {
  id: number | undefined
}

const CourseFormMaterial: FC<CourseFormMaterialProps> = ({id}) => {
  const [isDownload, setDownload] = useState<boolean>(false)
  const [isView, setView] = useState<boolean>(false)
  const [loadingIndex, setLoadingIndex] = useState<number | null>(null)
  const {pathname} = useLocation()
  const {currentMenu, currentUser} = useAuth()
  const [menuAccess, setMenuAccess] = useState<MenuModel | undefined>(undefined)
  const [acceptedFiles, setAcceptedFiles] = useState<CourseMaterial[]>([])
  const [showPreviewPdf, setShowPreviewPdf] = useState(false)
  const handleClosePreviewPdf = () => setShowPreviewPdf(false)
  const [selectedFileIndex, setSelectedIndex] = useState<number>(0)

  useEffect(() => {
    setMenuAccess(getMenuAccess(currentMenu ?? [], pathname))
  }, [currentMenu])

  const {isLoading} = useQuery(
    ['get-course-material-by-id', id],
    async () => {
      try {
        const response = await getMaterialCourseByCourseId(id)
        if (response.code !== '0000') {
          throw new Error(response.message)
        }
        setAcceptedFiles(response.data ?? [])
        return response.data
      } catch (err: any) {
        showError(err.message)
      }
    },
    {enabled: true, refetchOnWindowFocus: false}
  )

  const formik = useFormik({
    initialValues: initialCourseMaterial,
    enableReinitialize: true,
    onSubmit: async (values, {setSubmitting, setStatus}) => {
      setStatus(null)
      let models = acceptedFiles

      setSubmitting(true)
      try {
        await updateCourseMaterial(id, models)
        setSubmitting(false)
        showSuccess('Data updated', 2000)
      } catch (ex: any) {
        console.error(ex)
        setStatus(ex.message)
      } finally {
      }
    },
  })

  const onDrop = useCallback((files, fileRejections) => {
    fileRejections.forEach((fileRejection) => {
      fileRejection.errors.forEach((err) => {
        if (err.code === 'file-too-large') {
          showToastError(
            `The file is too large (max: 
            ${(currentUser?.limitFileSizeMb ?? 0).toFixed(2)} MB)`
          )
        }
        if (err.code === 'file-invalid-type') {
          showToastError(`${err.message}`)
        }
      })
    })

    files.forEach((file) => {
      const guid = new Date().getTime()
      setAcceptedFiles((prev) => [
        ...prev,
        {
          name: file.name,
          duration: 0,
          size: file.size,
          guid: guid,
          isLoading: true,
        },
      ])

      try {
        uploadCourseMaterial(file, id).then((res) => {
          setAcceptedFiles((prev) => {
            let newState = [...prev]
            let idx = newState.findIndex((q) => q.guid === guid)
            newState[idx] = {
              ...res,
              name: res?.file?.originalFileName,
              duration: 0,
              isLoading: false,
            }
            return newState
          })
        })
      } catch (err: any) {
        showError(err.message)
      }
    })
  }, [])

  const onError = (error) => {
    console.log(error)
  }

  const materialDescChangeHandler = (index, type, value) => {
    setAcceptedFiles((prev) => {
      const newState = [...prev]
      newState[index] = {...newState[index], [type]: value}
      return newState
    })
  }

  const downloadHandler = async (file: CourseMaterial, idx) => {
    try {
      setLoadingIndex(idx)
      setDownload(true)
      await downloadFile('course-materials/download/' + file?.uniqueId, file?.name ?? '')
    } catch (err: any) {
      showError(err?.message)
    } finally {
      setLoadingIndex(null)
      setDownload(false)
    }
  }

  const handleView = async (file: CourseMaterial, idx: number) => {
    setSelectedIndex(idx)
    let type = file?.fileName?.split('.').pop()
    if (type !== 'pdf') {
      try {
        setView(true)
        setLoadingIndex(idx)
        await viewFile(`course-materials/download/${file.uniqueId}`)
      } catch (err) {
        // @ts-ignore
        showError(err?.message)
      } finally {
        setView(false)
        setLoadingIndex(null)
      }
    } else {
      setShowPreviewPdf(true)
    }
  }

  const [showDeleteMaterial, setShowDeleteMaterial] = useState(false)
  const [materialSelectedId, setMaterialSelectedId] = useState<number | null>(null)

  const handleCloseDelete = () => setShowDeleteMaterial(false)

  const handleDeleteAction = async () => {
    setAcceptedFiles((prev) => {
      let newState = [...prev]
      let idx = newState.findIndex((q) => q.id === materialSelectedId)
      newState[idx] = {...newState[idx], isLoading: true}
      return newState
    })

    try {
      await deleteMaterial.mutateAsync()
    } catch (err: any) {
      console.log(err)
    }
  }

  const deleteMaterial = useMutation(() => deleteCourseMaterial(materialSelectedId), {
    onSuccess: () => {
      setMaterialSelectedId(null)
      setAcceptedFiles((prev) => {
        return [...prev.filter((f) => f.id !== materialSelectedId)]
      })

      handleCloseDelete()
    },
    onError: (err: any) => {},
  })

  const handleOpenConfirmation = (id) => {
    setMaterialSelectedId(id)
    setShowDeleteMaterial(true)
  }

  return (
    <div className='row'>
      <div className='col-12'>
        <KTCard>
          <div className='card-body'>
            <form className='form' onSubmit={formik.handleSubmit} noValidate>
              {/* begin::Scroll */}
              <div className='d-flex flex-column me-n7 pe-7'>
                {formik.status ? (
                  <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                  </div>
                ) : (
                  ''
                )}

                <div className='row'>
                  <div className='col-12'>
                    <h4 className='mb-2 mt-10'>Materials</h4>
                    {menuAccess?.canCreate && (
                      <Dropzone
                        onDrop={onDrop}
                        accept={{
                          'video/*': [],
                          'audio/*': [],
                          'image/*': [],
                          'application/pdf': [],
                          'application/vnd.ms-powerpoint': [],
                          'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                            [],
                        }}
                        maxSize={currentUser?.limitFileSize}
                        maxFiles={5}
                        onError={onError}
                      >
                        {({getRootProps, getInputProps}) => (
                          <section>
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <div className='dropzone'>
                                <div className='dz-message needsclick'>
                                  <i className='ki-duotone ki-file-up fs-3x text-warning'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                  </i>
                                  <div className='ms-4'>
                                    <h3 className='fs-5 fw-bold text-gray-900 mb-1'>
                                      Drop files here or click to upload.
                                    </h3>
                                    <span className='fs-7 fw-semibold text-gray-500'>
                                      Only Accept PDF, PPT, Audio, Images and Videos
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    )}
                    <aside className='mt-8  dropzone dropzone-queue'>
                      <div className='dropzone-items'>
                        {acceptedFiles?.map((file, index) => (
                          <div key={index} className='dropzone-item dz-image-preview'>
                            <div className='dropzone-file'>
                              <div className='text-gray-700'>
                                <div className='row'>
                                  <div className='col-12 col-lg-5 mb-2'>
                                    <label>Name</label>
                                    <input
                                      type='text'
                                      className='form-control form-control-sm'
                                      value={file.name}
                                      onChange={(e) =>
                                        materialDescChangeHandler(index, 'name', e.target.value)
                                      }
                                      disabled={file.isLoading}
                                    />
                                  </div>
                                  <div className='col-12 col-lg-3 mb-2'>
                                    <label>Duration</label>
                                    <input
                                      type='number'
                                      className='form-control form-control-sm'
                                      value={file.duration}
                                      onChange={(e) =>
                                        materialDescChangeHandler(index, 'duration', e.target.value)
                                      }
                                      disabled={file.isLoading}
                                    />
                                  </div>
                                  <div className='col-12 col-lg-4 mb-2'>
                                    <label>File Info</label>
                                    {file.id ? (
                                      <div>
                                        <TruncateString
                                          maxLength={30}
                                          text={file.originFileName ?? ''}
                                        />
                                        <strong className='ms-2'>
                                          (<span data-dz-size>{bytesToSize(file.size)}</span>)
                                        </strong>
                                      </div>
                                    ) : (
                                      <div>
                                        <TruncateString maxLength={30} text={file?.name ?? ''} />
                                        <strong className='ms-2'>
                                          (<span data-dz-size>{bytesToSize(file.size)}</span>)
                                        </strong>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className='dropzone-error' data-dz-errormessage></div>
                            </div>

                            <div className='dropzone-progress'>
                              <div className='progress'>
                                <div className='progress-bar bg-primary'></div>
                              </div>
                            </div>

                            <div className='dropzone-toolbar'>
                              {isLoading ? (
                                <span className='dropzone-delete' data-dz-remove>
                                  <i className='fs-4 fas fa-spinner fa-spin p-0'></i>
                                </span>
                              ) : (
                                <></>
                              )}
                              {file.id && (
                                <>
                                  {isView && loadingIndex === index ? (
                                    <span
                                      className='spinner-border spinner-border-sm me-4'
                                      key={index}
                                    ></span>
                                  ) : (
                                    <span
                                      onClick={() => {
                                        if (!isView) {
                                          handleView(file, index)
                                        }
                                      }}
                                    >
                                      <i className='fas fa-eye me-4'></i>
                                    </span>
                                  )}
                                  <span
                                    onClick={() => {
                                      if (!isDownload) {
                                        downloadHandler(file, index)
                                      }
                                    }}
                                  >
                                    {isDownload && loadingIndex === index ? (
                                      <span
                                        className='spinner-border spinner-border-sm me-4'
                                        key={index}
                                      ></span>
                                    ) : (
                                      <i className='fas fa-download me-4' />
                                    )}
                                  </span>
                                  {menuAccess?.canDelete && (
                                    <span onClick={() => handleOpenConfirmation(file.id)}>
                                      <i className='fas fa-times me-4'></i>
                                    </span>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </aside>
                  </div>
                </div>

                {menuAccess?.canCreate && (
                  <div className='text-end pt-15'>
                    <LoadingButtonWrapper isLoading={formik.isSubmitting || isLoading}>
                      <button
                        type='submit'
                        className='btn btn-warning'
                        disabled={formik.isSubmitting || isLoading}
                      >
                        <span className='indicator-label'>Submit</span>
                      </button>
                    </LoadingButtonWrapper>
                  </div>
                )}
                {/* end::Actions */}
              </div>
            </form>
            <Confirm
              name={null}
              show={showDeleteMaterial}
              handleClose={handleCloseDelete}
              handleConfirm={handleDeleteAction}
            />

            <PreviewPdf
              name={acceptedFiles?.[selectedFileIndex]?.originFileName ?? ''}
              url={`course-materials/download/${acceptedFiles?.[selectedFileIndex]?.uniqueId}`}
              show={showPreviewPdf}
              handleClose={handleClosePreviewPdf}
            />

            {(formik.isSubmitting || isLoading) && <CourseListLoading />}
          </div>
        </KTCard>
      </div>
    </div>
  )
}

export default CourseFormMaterial
