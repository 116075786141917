import {useMemo, Fragment, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {KTCardBody} from '../../../../../../_metronic/helpers'
import {ReportLoading} from '../../../components/ReportLoading'
import {ResultQuizColumns} from './columns/_columns'
import {ResultQuizListPagination} from '../components/pagination/ResultQuizPagination'
import ResultQuizDetailModal from '../components/ResultQuizDetailModal'

const ResultQuizTable = () => {
  const d = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => d, [d])
  const columns = useMemo(() => ResultQuizColumns, [])

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data: data?.records ?? [],
  })
  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_attendance'
          className='table table-striped table-rounded dataTable align-middle table-row-dashed no-footer gs-7 fs-6 gy-5'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-center text-muted fw-bolder fs-7 text-uppercase gs-0'>
              <th rowSpan={2} className='text-start align-middle'>
                Rank
              </th>
              <th rowSpan={2} className='text-start align-middle'>
                NIM
              </th>
              <th rowSpan={2} className='text-start align-middle'>
                Name
              </th>
              <th rowSpan={2} className='align-middle'>
                Correct Answer
              </th>
              <th rowSpan={2} className='align-middle'>
                Score
              </th>
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<any>, i) => {
                return (
                  <tr key={i}>
                    <td>{row.original.rank}</td>
                    <td>{row.original.nim}</td>
                    <td>{row.original.studentName}</td>
                    <td className='text-center'>
                      {row.original.correct != null ? row.original.correct : '-'}
                    </td>
                    <td className='text-center'>
                      {row.original.correct != null ? row.original.score : '-'}
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={6}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    {isLoading ? '' : ' No matching records found'}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <ResultQuizListPagination
        totalPages={data.totalPages}
        currentPage={data?.pageNumber ?? 1}
        totalFiltered={data.totalRecordsFiltered}
        onPageChange={() => {}}
      />
    </KTCardBody>
  )
}

export {ResultQuizTable}
