import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import QRCode from 'react-qr-code'
import Skeleton from 'react-loading-skeleton'
import {generateToken} from '../../classes/core/_request'
import CountdownTimer from '../../../components/Countdown'
import {getQrToken} from '../core/_request'

const MyQrModal = ({show, onClose}) => {
  const [token, setToken] = useState<string>('')
  const [expDate, setExpDate] = useState<Date | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isFullscreen, setIsFullscreen] = useState<true | string | undefined>(undefined)
  const [isExpired, setIsExpired] = useState(false)

  const handleClose = () => {
    onClose()
  }

  const getToken = () => {
    setIsLoading(true)
    getQrToken().then(({code, message, data}) => {
      if (code === '0000') {
        setToken(data.token)
        setExpDate(new Date(data.expiredAt))
        setIsExpired(false)
      }

      setIsLoading(false)
    })
  }

  useEffect(() => {
    if (show) {
      setExpDate(null)
      setIsExpired(false)
      getToken()
    }
  }, [show])

  const reload = () => {
    setIsExpired(false)
    getToken()
  }

  const toggleFullscreen = () => {
    setIsFullscreen((prev) => (prev === undefined ? true : undefined))
  }

  const tokenExpired = () => {
    return <div className='d-flex fw-bold text-danger fs-3'>Expired</div>
  }

  const tokenExpiredHandler = () => {
    setIsExpired(true)
  }

  return (
    <Modal show={show} onHide={handleClose} fullscreen={isFullscreen} centered={true}>
      {isFullscreen}
      <Modal.Header closeButton>
        <div className='d-flex justify-content-between w-100'>
          <Modal.Title>
            <span>My QR</span>
          </Modal.Title>
          <button className='btn btn-default btn-sm' type='button' onClick={toggleFullscreen}>
            <i className={isFullscreen ? 'fas fa-compress-arrows-alt' : 'fas fa-expand'}></i>
          </button>
        </div>
      </Modal.Header>
      <Modal.Body className='p-10' id='modal-body'>
        <div className='d-flex flex-center mb-5 flex-column' style={{height: '80%'}}>
          {isLoading ? (
            <Skeleton height={250} width={250} />
          ) : (
            <div style={{height: '80%'}} className='position-relative'>
              <QRCode value={token} style={{height: '100%', width: 'auto'}} />
              {isExpired && (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(255,255,255,0.9)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {tokenExpired()}
                </div>
              )}
            </div>
          )}
          <div className='mt-6'>
            {isLoading ? (
              <Skeleton height={25} width={50} />
            ) : (
              expDate && (
                <CountdownTimer targetDate={expDate} onCountdownEnd={tokenExpiredHandler} />
                // <Countdown
                //   targetDate={expDate}
                //   showHours={false}
                //   onCountdownEnd={tokenExpiredHandler}
                //   className='fw-bold fs-1'
                // />
              )
            )}
          </div>
        </div>
        <div className='d-flex flex-center mt-12'>
          <button className='btn btn-primary' type='button' onClick={reload} disabled={isLoading}>
            Re-Generate
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default MyQrModal
