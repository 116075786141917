import {KTIcon} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import React, {useEffect, useState} from 'react'
import {CourseListFilter} from './CourseListFilter'
import {useLocation} from 'react-router-dom'
import {useAuth, MenuModel, getMenuAccess} from '../../../../auth'

const CourseListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddCourseModal = () => {
    setItemIdForUpdate(null)
  }
  const {pathname} = useLocation()
  const {currentMenu} = useAuth()
  const [menuAccess, setMenuAccess] = useState<MenuModel | undefined>(undefined)
  useEffect(() => {
    setMenuAccess(getMenuAccess(currentMenu ?? [], pathname))
  }, [currentMenu])

  return (
    <div className='d-flex justify-content-end' data-kt-course-table-toolbar='base'>
      <CourseListFilter />

      {/* begin::Export */}
      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTIcon iconName='exit-up' className='fs-2' />
        Export
      </button> */}
      {/* end::Export */}

      {/* begin::Add course */}
      {menuAccess?.canCreate && (
        <button onClick={openAddCourseModal} type='button' className='btn btn-warning'>
          <KTIcon iconName='plus' className='fs-2' />
          Add Course
        </button>
      )}
      {/* end::Add course */}
    </div>
  )
}

export {CourseListToolbar}
