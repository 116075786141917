import {FC, useState} from 'react'
import {KTIcon, QUERIES, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {downloadExternalLink} from '../core/_request'
import {toast} from 'react-toastify'
import {viewFile} from '../../../../utils/file'
import {createDownloadRequest} from '../../../download-request/download-request-list/core/_requests'
import {
  DownloadRequest,
  DownloadRequestStatus,
  DownloadRequestType,
} from '../../../download-request/download-request-list/core/_models'
import {useAuth} from '../../../auth'
import {Confirm} from '../../../../components/Confirm'
import {showSuccess} from '../../../../utils/toast'
import {useMutation, useQueryClient} from 'react-query'
import clsx from 'clsx'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import { PreviewPdf } from '../../../../components/PreviewPdf'

type Props = {
  title: string
  description: string
  filename: string
  unique: string
  abstractUnique: string
  tagsName: string[]
  publisher?: string
  publicationYear?: number
  fileId: number
  data?: DownloadRequest
}

const ReferenceCard: FC<Props> = ({
  title,
  description,
  unique,
  filename,
  tagsName,
  publisher,
  publicationYear,
  abstractUnique,
  fileId,
  data,
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const videos = ['mp4', 'mov', 'wvm', 'flv', 'avi', 'avchd', 'webm', 'mkv']
  const {currentUser} = useAuth()
  const queryClient = useQueryClient()
  const [showPreviewPdf, setShowPreviewPdf] = useState(false)
  const handleClosePreviewPdf = () => setShowPreviewPdf(false)

  const getIcon = (filename: string): string => {
    const extension = filename?.substring(filename.lastIndexOf('.') + 1).toLowerCase()

    if (extension === 'pdf') {
      return toAbsoluteUrl('/media/svg/files/pdf.svg')
    } else if (videos.includes(extension)) {
      return toAbsoluteUrl('/media/svg/files/mov.svg')
    } else if (extension === 'png') {
      return toAbsoluteUrl('/media/svg/files/png.svg')
    } else if (extension === 'jpeg' || extension === 'jpg') {
      return toAbsoluteUrl('/media/svg/files/jpg.svg')
    } else if (extension === 'ppt' || extension === 'pptx') {
      return toAbsoluteUrl('/media/svg/files/ppt.svg')
    }

    return toAbsoluteUrl('/media/svg/files/blank-image.svg')
  }

  const getCleanName = (filename: string): string => {
    const result = filename.substring(0, filename.lastIndexOf('.')) || filename
    return result.toUpperCase()
  }

  const showToastError = (message: string) => {
    toast.error(message, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
      theme: 'light',
    })
  }

  const handleDownload = async (unique: string, fileName: string) => {
    if (!data?.statusId) {
      setShowModal(true)
      return
    }

    if (data?.statusId === DownloadRequestStatus.approved) {
      try {
        setLoading(true)
        await downloadExternalLink(data?.downloadLink ?? '', fileName)
      } catch (error: any) {
        showToastError(error.message)
      } finally {
        setLoading(false)
      }
    }
  }

  const createRequest = useMutation(
    () => {
      setLoading(true)
      return createDownloadRequest({
        typeId: DownloadRequestType.bibliography,
        statusId: DownloadRequestStatus.requested,
        requestedBy: currentUser?.id,
        fileId: fileId,
      })
    },
    {
      onSuccess: () => {
        setLoading(false)
        setShowModal(false)
        showSuccess('File successfully requested!')
        queryClient.invalidateQueries([`${QUERIES.REFERENCE_LIST}`])
      },
      onError: (err: any) => {
        setLoading(false)
      },
    }
  )

  const handleView = async (unique: string) => {
    // try {
    //   setLoading(true)
    //   await viewFile(`bibliographies/download/${unique}`)
    // } catch (error: any) {
    //   showToastError(error.message)
    // } finally {
    //   setLoading(false)
    // }
    setShowPreviewPdf(true)
  }

  const getPublisherAndYear = (publisher?: string, year?: number) => {
    let result: string[] = []

    if (publisher) {
      result.push(publisher)
    }

    if (year) {
      result.push(year.toString())
    }

    return result.join(' | ')
  }

  const getTextButtonDownload = (): {
    text: string
    enabled: boolean
    color?: string
    icon?: string
  } => {
    if (data?.allowRequest) {
      return {
        text: 'Download Full Document',
        enabled: data.allowRequest,
      }
    }

    if (data?.statusId === DownloadRequestStatus.requested) {
      return {
        text: 'File on Request',
        enabled: (data.allowDownload ?? false) && (data.allowRequest ?? false),
        color: '#3e97ff',
      }
    }

    if (data?.allowDownload) {
      return {
        text: 'Download Full Document',
        enabled: data.allowDownload,
        color: 'success',
        icon: 'check-circle',
      }
    }

    return {
      text: 'Download Full Document',
      enabled: data?.allowRequest ?? true,
    }
  }

  return (
    <>
      <div className='card overlay h-100'>
        <div className='card-body d-flex justify-content-center text-center flex-column p-8 ribbon ribbon-top ribbon-vertical'>
          {data?.statusId && (
            <div className={clsx('ribbon-label', `bg-${getTextButtonDownload().color}`)}>
              {!getTextButtonDownload().icon && getTextButtonDownload().text}
              {getTextButtonDownload().icon && (
                <KTIcon iconName={'check-circle'} className='fs-3 text-white' iconType='solid' />
              )}
            </div>
          )}
          <div className='overlay-wrapper'>
            <div className='text-gray-800 d-flex flex-column'>
              <div className='symbol symbol-75px mb-6'>
                <img src={getIcon(filename)} alt={description} />
              </div>
              <div className='fs-5 fs-lg-7 fw-bolder'>{getCleanName(title)}</div>
            </div>
            <div className='fs-7 fs-lg-8 fw-bold text-gray-600 mt-auto mt-md-1'>{description}</div>
            <span className='fs-7 fs-lg-8 fw-bold text-gray-600 mt-auto mt-md-1'>
              {getPublisherAndYear(publisher, publicationYear)}
            </span>
            {/* {tagsName &&
              tagsName.map((tag) => {
                return (
                  <span
                    className='badge badge-xs badge-outline badge-primary'
                    style={{lineHeight: '1'}}
                  >
                    {tag}
                  </span>
                )
              })} */}
            {loading && (
              <div className='spinner-border text-primary' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            )}
          </div>
          <div className='overlay-layer bg-dark bg-opacity-20'>
            <span
              role='button'
              className='btn btn-shadow btn-sm'
              style={{backgroundColor: '#f1faff', color: '#3e97ff'}}
              onClick={() => {
                if (!loading) {
                  handleView(abstractUnique)
                }
              }}
            >
              <i className='fas fa-eye fs-5' style={{color: '#3e97ff'}} /> Preview
            </span>
            {getTextButtonDownload().enabled && (
              <button
                className='btn btn-shadow btn-sm ms-2'
                style={{backgroundColor: '#f1faff', color: '#3e97ff'}}
                onClick={() => {
                  if (!loading) {
                    handleDownload(unique, title)
                  }
                }}
              >
                <i className='fas fa-download fs-5' style={{color: '#3e97ff'}} />{' '}
                {getTextButtonDownload().text}
              </button>
            )}
          </div>
        </div>
      </div>
      <Confirm
        name={'Confirmation'}
        body={'Would you like to request to download full document?'}
        handleClose={() => setShowModal(false)}
        handleConfirm={() => createRequest.mutateAsync()}
        show={showModal}
        isLoading={loading}
      />
      <PreviewPdf 
        name={title}
        url={`bibliographies/download/${abstractUnique}`}
        show={showPreviewPdf}
        handleClose={handleClosePreviewPdf}
      />
    </>
  )
}

export {ReferenceCard}
