import {ID, Response} from '../../../../../_metronic/helpers'
import {
  ClassAssignment,
  ClassLecturer,
  ClassQuiz,
  ClassSession,
  ClassStudent,
} from '../../class-list/core/_models'
import axios from 'axios'

const API_URL = process.env.REACT_APP_BASE_API_URL
const CLASSES_URL = `${API_URL}/classes`

const getClassLecturers = (id: ID): Promise<Response<ClassLecturer[]>> => {
  return axios.get(`${CLASSES_URL}/${id}/lecturers`).then((resp) => resp.data)
}

const updateClassSessions = (id: ID, lecturers: ClassSession[]): Promise<Response<number>> => {
  return axios.put(`${CLASSES_URL}/${id}/sessions`, lecturers).then((resp) => resp.data)
}

const getClassSessions = (id: ID): Promise<Response<ClassSession[]>> => {
  return axios.get(`${CLASSES_URL}/${id}/sessions`).then((resp) => resp.data)
}

const updateClassLecturers = (id: ID, values: ClassLecturer[]): Promise<Response<number>> => {
  return axios.put(`${CLASSES_URL}/${id}/lecturers`, values).then((resp) => resp.data)
}

const getClassAssignments = (id: ID): Promise<Response<ClassAssignment[]>> => {
  return axios.get(`${CLASSES_URL}/${id}/assignments`).then((resp) => resp.data)
}

const updateClassAssignments = (id: ID, values: ClassAssignment[]): Promise<Response<number>> => {
  return axios.put(`${CLASSES_URL}/${id}/assignments`, values).then((resp) => resp.data)
}

const getClassQuiz = (id: ID): Promise<Response<ClassQuiz[]>> => {
  return axios.get(`${CLASSES_URL}/${id}/quizzes`).then((resp) => resp.data)
}

const updateClassQuiz = (id: ID, values: ClassQuiz[]): Promise<Response<number>> => {
  return axios.put(`${CLASSES_URL}/${id}/quizzes`, values).then((resp) => resp.data)
}

const getClassStudent = (id: ID, query: string = ''): Promise<Response<ClassStudent[]>> => {
  return axios.get(`${CLASSES_URL}/${id}/students?${query}`).then((resp) => resp.data)
}

const updateClassStudent = (id: ID, values: ClassStudent[]): Promise<Response<number>> => {
  return axios.put(`${CLASSES_URL}/${id}/students`, values).then((resp) => resp.data)
}

export {
  getClassLecturers,
  getClassSessions,
  getClassAssignments,
  getClassQuiz,
  getClassStudent,
  updateClassLecturers,
  updateClassSessions,
  updateClassAssignments,
  updateClassQuiz,
  updateClassStudent,
}
