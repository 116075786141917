import React, {useRef} from 'react'
import moment from 'moment'
import Flatpickr from 'react-flatpickr'

interface DatePickerProps {
  label: string
  name: string
  field: any
  form: any
  options?: any
  dateFormat?: string
  labelFormat?: string
  enableTime?: boolean
  dateOnly?: boolean
  isStatic?: boolean
  className?: string
  useFormik?: boolean
  onChange?: any
  value?: any
  closeOnSelect?: boolean
  disabled?: boolean
}

const DatePicker: React.FC<DatePickerProps> = ({
  field,
  form,
  label,
  name,
  options,
  className = '',
  dateFormat = 'Y-m-d',
  labelFormat = 'd/m/Y',
  enableTime = false,
  dateOnly,
  isStatic = true,
  useFormik = true,
  onChange,
  value,
  closeOnSelect,
  disabled = false,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  if (dateOnly === undefined) {
    dateOnly = false
  }

  // useEffect(() => {
  //   if (!inputRef.current) {
  //     return
  //   }
  //   const fp = flatpickr(inputRef.current, {
  //     altInput: true,
  //     altFormat: labelFormat,
  //     dateFormat: dateFormat,
  //     enableTime: enableTime,
  //     allowInput: true,
  //     time_24hr: true,
  //     ...options,
  //     static: isStatic,
  //     onReady: (e) => {
  //       console.log(e)
  //     },
  //     onClose: (selectedDates) => {
  //       if (!selectedDates || selectedDates.length === 0) {
  //         return
  //       }
  //       if (dateOnly) {
  //         form.setFieldValue(name, moment(selectedDates[0]).format('YYYY-MM-DD'))
  //       } else {
  //         form.setFieldValue(name, selectedDates[0].toISOString())
  //       }
  //     },
  //     onChange: (selectedDates) => {
  //       if (!selectedDates || selectedDates.length === 0) {
  //         return
  //       }

  //       console.log(selectedDates)

  //       if (dateOnly) {
  //         form.setFieldValue(name, moment(selectedDates[0]).format('YYYY-MM-DD'))
  //       } else {
  //         form.setFieldValue(name, selectedDates[0].toISOString())
  //       }
  //     },
  //   })

  //   return () => {
  //     fp.destroy()
  //   }
  // }, [])

  return (
    <>
      <Flatpickr
        {...field}
        options={{
          altInput: true,
          altFormat: labelFormat,
          dateFormat: dateFormat,
          enableTime: enableTime,
          allowInput: false,
          time_24hr: true,
          ...options,
          static: isStatic,
          closeOnSelect: closeOnSelect,
          clickOpens: !disabled
        }}
        value={value}
        onClose={(selectedDates) => {
          if (useFormik) {
            if (!selectedDates || selectedDates.length === 0) {
              return
            }
            if (dateOnly) {
              form.setFieldValue(name, moment(selectedDates[0]).format('YYYY-MM-DD'))
            } else {
              form.setFieldValue(name, selectedDates[0].toISOString())
            }
          } else {
            if (selectedDates) {
              onChange(selectedDates)
            }
          }
        }}
        onChange={(selectedDates) => {
          if (useFormik) {
            if (!selectedDates || selectedDates.length === 0) {
              return
            }
            if (dateOnly) {
              form.setFieldValue(name, moment(selectedDates[0]).format('YYYY-MM-DD'))
            } else {
              form.setFieldValue(name, selectedDates[0].toISOString())
            }
          } else {
            onChange(selectedDates)
          }
        }}
      />
    </>
  )
}

export default DatePicker
