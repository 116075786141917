/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import AnnouncementCard from '../../modules/dashboard/components/AnnouncementCard'
import UpcomingClassCard from '../../modules/dashboard/components/UpcomingClassCard'
import ClassesCard from '../../modules/dashboard/components/ClassesCard'
import TodoListCard from '../../modules/dashboard/components/TodoListCard'
import {AnnouncementProvider} from '../../modules/dashboard/components/announcement-modal/AnnouncementProvider'
import {useAuth} from '../../modules/auth'
import RoleEnum from '../../enums/RoleEnum'
import ActiveStudentCard from '../../modules/dashboard/components/ActiveStudentCard'
import AttendanceChartCard from '../../modules/dashboard/components/AttendanceChartCard'
import AverageGPAChartCard from '../../modules/dashboard/components/AverageGPAChartCard'
import OperatorClassList from '../../modules/dashboard/components/operator-class/OperatorClassList'
import SurveyNotificationModal from '../../modules/survey/components/SurveyNotificationModal'
import {getShowSurveyNotif} from '../../modules/survey/core/_requests'
import TodayActivity from '../../modules/dashboard/components/TodayActivity'
import SurveySummaryChartCard from '../../modules/dashboard/components/SurveySummaryChartCard'
import CourseScoreChartCard from '../../modules/dashboard/components/CourseScoreChartCard'
import LecturerAttendanceChartCard from '../../modules/dashboard/components/LecturerAttendanceChartCard'
import clsx from 'clsx'
import TodayClassCard from '../../modules/dashboard/components/TodayClassCard'

const DashboardStudent = () => {
  return (
    <>
      <div className='row g-8'>
        <div className='col-xl-8 col-sm-12'>
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12 mb-5'>
              <AnnouncementProvider>
                <AnnouncementCard />
              </AnnouncementProvider>
            </div>
          </div>
        </div>

        <div className='col-xl-4 col-sm-12'>
          <UpcomingClassCard />
        </div>
      </div>

      <div className='row'>
        <div className='col-xl-4 col-lg-6 order-lg-1'>
          <TodoListCard />
        </div>

        <div className='col-xl-8 col-lg-6 order-lg-0'>
          <ClassesCard />
        </div>
      </div>
    </>
  )
}

const DashboardLecturer = () => {
  return (
    <>
      <div className='row g-8'>
        <div className='col-xl-8 col-sm-12'>
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12 mb-5'>
              <AnnouncementProvider>
                <AnnouncementCard />
              </AnnouncementProvider>
            </div>
          </div>
        </div>

        <div className='col-xl-4 col-sm-12'>
          <UpcomingClassCard />
        </div>
      </div>

      <div className='row'>
        <div className='col-12'>
          <ClassesCard />
        </div>
      </div>
    </>
  )
}

const DashboardStaff = ({isAdvance = false, isPublic = false, isAdminOnly = false}) => {
  return (
    <>
      <div className='row g-8'>
        <div className={clsx({'col-xl-8 col-sm-12': !isPublic}, {'col-12': isPublic})}>
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12 mb-5'>
              <AnnouncementProvider>
                <AnnouncementCard />
              </AnnouncementProvider>
            </div>
          </div>
        </div>

        {!isPublic && (
          <div className='col-xl-4 col-sm-12'>
            <ActiveStudentCard />
          </div>
        )}
      </div>

      {!isPublic && isAdminOnly && (
        <div className='row mb-6'>
          <div className='col-lg-12'>
            <TodayActivity />
          </div>
        </div>
      )}

      {!isPublic && (
        <div className='row mb-6'>
          <div className='col-lg-12'>
            <TodayClassCard />
          </div>
        </div>
      )}

      {isAdvance && (
        <>
          <div className='row mb-6'>
            <div className='col-lg-12'>
              <AttendanceChartCard />
            </div>
          </div>

          <div className='row mb-6'>
            <div className='col-lg-12'>
              <LecturerAttendanceChartCard />
            </div>
          </div>

          <div className='row mb-6'>
            <div className='col-lg-12'>
              <CourseScoreChartCard />
            </div>
          </div>

          <div className='row'>
            <div className='col-lg-12'>
              <AverageGPAChartCard />
            </div>
          </div>

          <div className='row'>
            <div className='col-lg-12'>
              <SurveySummaryChartCard />
            </div>
          </div>
        </>
      )}
    </>
  )
}

const DashboardLoading = () => {
  return <p>loading</p>
}

const DashboardWrapper: FC = () => {
  const {currentUser} = useAuth()
  const [component, setComponent] = useState<JSX.Element>(<DashboardLoading />)

  const [showSurveyNotif, setShowSurveyNotif] = useState(false)
  const [image, setImage] = useState('')

  const capture = async () => {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    const video = document.createElement('video')

    try {
      const captureStream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
      })

      let settings: any = captureStream.getVideoTracks()[0].getSettings()
      let ds = settings.displaySurface
      console.log(ds)

      if (ds !== 'monitor') {
        throw new Error('Please Share Whole Screen')
      }

      video.srcObject = captureStream

      // Ensure the video is loaded before capturing
      video.onloadedmetadata = () => {
        video.play() // Start playing the video

        video.onplay = () => {
          // Set canvas size to match video
          canvas.width = video.videoWidth
          canvas.height = video.videoHeight

          // Draw the video frame to the canvas
          context?.drawImage(video, 0, 0, canvas.width, canvas.height)

          // Convert canvas to a data URL (screenshot in PNG format)
          const frame = canvas.toDataURL('image/png')

          // Stop all tracks to end the screen capture
          captureStream.getTracks().forEach((track) => track.stop())

          // Set the captured image (replace setImage with your method)
          setImage(frame)
        }
      }
    } catch (err) {
      console.error('Error: ' + err)
    }
  }

  useEffect(() => {
    if (currentUser?.roleId == RoleEnum.Student) {
      getShowSurveyNotif().then((resp) => {
        setShowSurveyNotif(resp.data ?? false)
      })
    }

    // try {
    //   capture().then((r) => {
    //     console.log('success')
    //   })
    // } catch (e) {
    //   console.log(e)
    // }

    // navigator.mediaDevices
    //   .getDisplayMedia()
    //   .then((strm) => {
    //     const settings: any = strm.getVideoTracks()[0].getSettings()
    //     let displaySurface = settings.displaySurface
    //     if (displaySurface !== 'monitor') {
    //       throw 'Selection of entire screen mandatory!'
    //     }
    //   })
    //   .catch((err) => console.error(err))
  }, [currentUser])

  useEffect(() => {
    const roleId = currentUser?.roleId

    if (roleId === RoleEnum.Student) {
      setComponent(<DashboardStudent />)
    } else if (roleId === RoleEnum.Lecturer) {
      setComponent(<DashboardLecturer />)
    } else if (roleId === RoleEnum.Staff) {
      setComponent(<DashboardStaff />)
    } else if (roleId === RoleEnum.Operator) {
      setComponent(<OperatorClassList />)
    } else if (roleId === RoleEnum.StaffAdmin || roleId === RoleEnum.Rectorate) {
      setComponent(<DashboardStaff isAdvance={true} />)
    } else if (roleId === RoleEnum.Admin) {
      setComponent(<DashboardStaff isAdvance={true} isAdminOnly={true} />)
    } else {
      setComponent(<DashboardStaff isAdvance={false} isPublic={true} />)
    }
  }, [currentUser?.roleId])

  return (
    <>
      {/* <img src={image} style={{width: '100%'}} /> */}
      {currentUser?.roleId !== RoleEnum.Operator && (
        <div className='d-flex flex-row justify-content-between mb-8'>
          <h1 className='page-heading text-dark fw-bold fs-3 my-0'>Dashboard</h1>
        </div>
      )}
      {component}
      <SurveyNotificationModal show={showSurveyNotif} onClose={() => setShowSurveyNotif(false)} />
    </>
  )
}

export {DashboardWrapper}
