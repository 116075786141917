import React, {useEffect, useState} from 'react'
import { Modal, Button } from 'react-bootstrap'
import moment from 'moment/moment'

function MeetConfirmModal({ startDate, duration,  show, backDate, endSoon, handleClose, handleConfirm}) {
  const finishDate = moment(startDate).add(duration, 'minutes').format('HH:mm')

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Warning</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {backDate &&
          <div>
            Start Date is before today.
          </div>
        }
        {endSoon &&
          <div>
            The lecture will end soon (at {finishDate})
          </div>
        }
        <div>Are you sure want to continue?</div>
        {/*<div>Are you sure want to input back time schedule meet?</div>*/}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleConfirm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export { MeetConfirmModal }