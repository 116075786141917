/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {initialQueryState, KTIcon, useDebounce} from '../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'

type SurveyStudentInfoSeachProps = {
  keyword: string
  setKeyword: any
}

const SurveyStudentInfoSeachComponent: React.FC<SurveyStudentInfoSeachProps> = ({
  keyword,
  setKeyword,
}) => {
  const {updateState} = useQueryRequest()

  const debouncedSearchTerm = useDebounce(keyword, 150)

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && keyword !== undefined) {
      updateState({...initialQueryState, keyword: debouncedSearchTerm})
    }
  }, [debouncedSearchTerm])

  return (
    <div className='card-title flex-column flex-md-row'>
      <div className='d-flex align-items-center position-relative my-1 w-100 w-md-auto'>
        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
        <input
          type='text'
          data-kt-lecturer-table-filter='search'
          className='form-control w-md-250px w-100 ps-14'
          placeholder='Search Student Name'
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
      </div>
    </div>
  )
}

export {SurveyStudentInfoSeachComponent}
