import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useCallback, useEffect, useMemo, useState} from 'react'
import {resetPasswordToken, validateUserToken} from '../core/_request'
import {ResetPasswordRequestType} from '../core/_model'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {InputGroup} from 'react-bootstrap'
import {useAuth} from '../../auth'
import {GoogleReCaptcha, GoogleReCaptchaProvider} from 'react-google-recaptcha-v3'
import {intervalRefreshRecaptcha} from '../../../../_metronic/helpers'
import LoadingButtonWrapper from '../../../components/LoadingButtonWrapper'

const regexWording = 'Min 5 characters and must contains one character and one number.'

const schema = Yup.object({
  newPassword: Yup.string()
    .required('New password is required')
    .matches(/^(?=.*[a-zA-Z])(?=.*\d).{5,}$/, regexWording),
  verifyPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), ''], 'Passwords must match')
    .required('Verify password is required')
    .matches(/^(?=.*[a-zA-Z])(?=.*\d).{5,}$/, regexWording),
})

const initial: ResetPasswordRequestType = {
  verifyPassword: '',
  newPassword: '',
  token: '',
}

const isUseCaptcha = process.env.REACT_APP_USE_CAPTCHA
const captchaKey = process.env.REACT_APP_CAPTCHA_TOKEN ?? ''

const ForgotPasswordReset: FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [visible1, setVisible1] = useState<boolean>(false)
  const [visible2, setVisible2] = useState<boolean>(false)
  const [isError, setError] = useState<boolean>(false)
  const [isErrorForm, setErrorForm] = useState<boolean>(false)
  const [isSuccess, setSuccess] = useState<boolean>(false)
  const [isInit, setIsInit] = useState(false)
  const [captchaToken, setCaptchaToken] = useState<string>('')
  const [refreshCaptcha, setRefreshCaptcha] = useState<boolean>(false)

  const onVerify = useCallback((token: string) => {
    setCaptchaToken(token)
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRefreshCaptcha((r) => !r)
    }, intervalRefreshRecaptcha)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const {search} = useLocation()
  const url = useMemo(() => new URLSearchParams(search), [search])
  const navigate = useNavigate()

  const {logout} = useAuth()

  useEffect(() => {
    logout()
    setLoading(true)
    const token = url.get('t')

    if (!token) {
      formik.setStatus('Invalid token.')
      setError(true)
      return
    }

    validateUserToken(token ?? '')
      .then((response) => {
        if (response.code === '1003') {
          setError(true)
          formik.setStatus(response.message)
        }
      })
      .catch((err: any) => {
        formik.setStatus(err.message)
        setError(true)
      })
      .finally(() => {
        setIsInit(true)
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url])

  const formik = useFormik({
    initialValues: initial,
    validationSchema: schema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        setLoading(true)
        setError(false)
        setStatus(undefined)

        const token = url.get('t') ?? ''

        let request: ResetPasswordRequestType = {
          ...values,
          token: token,
          captchaToken: '',
        }

        if (isUseCaptcha) {
          request = {
            ...values,
            token: token,
            captchaToken: captchaToken,
          }
        }

        const response = await resetPasswordToken(request)

        if (response.code === '1003') {
          setStatus(response.message)
          setErrorForm(true)
        } else {
          setSuccess(true)
          setStatus(
            'Reset password success. System will redirect you to login page and please login again.'
          )
          setErrorForm(false)

          setTimeout(() => {
            navigate('/auth ')
          }, 3000)
        }
      } catch (error: any) {
        setStatus(error.message)
        setErrorForm(true)
        setRefreshCaptcha(!refreshCaptcha)
      } finally {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return isError ? (
    <>
      <div className='alert alert-danger mb-10'>
        <div className='alert-text font-weight-bold'>{formik.status}</div>
      </div>
      <Link to='/auth'>Back to login</Link>
    </>
  ) : isSuccess && !isErrorForm && formik.status ? (
    <div className='mb-lg-15 alert alert-success'>
      <div className='alert-text font-weight-bold'>{formik.status}</div>
    </div>
  ) : isInit ? (
    <>
      <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
        <div className='text-center mb-8'>
          <h1 className='text-dark fw-bolder mb-3'>Forget Password</h1>
          <div className='text-gray-500 fw-semibold fs-6'>
            Choose a new, strong, and secure password.
          </div>
        </div>

        {formik.status && isErrorForm && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}

        {formik.status && !isErrorForm && (
          <div className='mb-lg-15 alert alert-success'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}

        <div className='fv-row mb-4'>
          <label className='form-label fw-bolder text-dark fs-6'>New Password</label>
          <div className='position-relative'>
            <InputGroup>
              <input
                placeholder='New Password'
                {...formik.getFieldProps('newPassword')}
                type={visible1 ? 'text' : 'password'}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.newPassword && formik.errors.newPassword,
                  },
                  {
                    'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
                  }
                )}
                tabIndex={1}
                autoComplete='off'
              />

              <InputGroup.Text role='button' onClick={() => setVisible1((e) => !e)}>
                <i className={!visible1 ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
              </InputGroup.Text>
            </InputGroup>

            {formik.touched.newPassword && formik.errors.newPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.newPassword}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='fv-row mb-3'>
          <label className='form-label fw-bolder text-dark fs-6'>Confirm New Password</label>
          <div className='position-relative'>
            <InputGroup>
              <input
                placeholder='Confirm New Password'
                {...formik.getFieldProps('verifyPassword')}
                type={visible2 ? 'text' : 'password'}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.verifyPassword && formik.errors.verifyPassword,
                  },
                  {
                    'is-valid': formik.touched.verifyPassword && !formik.errors.verifyPassword,
                  }
                )}
                tabIndex={2}
                autoComplete='off'
              />

              <InputGroup.Text role='button' onClick={() => setVisible2((e) => !e)}>
                <i className={!visible1 ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
              </InputGroup.Text>
            </InputGroup>

            {formik.touched.verifyPassword && formik.errors.verifyPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.verifyPassword}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-3'>
          <div />
          <Link to='/auth' className='link-primary'>
            Login instead
          </Link>
        </div>

        <div className='d-grid mb-3'>
          <LoadingButtonWrapper isLoading={loading}>
            <button
              type='submit'
              className='btn btn-danger'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              <span className='indicator-label'>Submit</span>
            </button>
          </LoadingButtonWrapper>
        </div>
      </form>
      {isUseCaptcha && (
        <GoogleReCaptchaProvider reCaptchaKey={captchaKey}>
          <GoogleReCaptcha
            onVerify={onVerify}
            refreshReCaptcha={refreshCaptcha}
            action='reset_password'
          />
        </GoogleReCaptchaProvider>
      )}
    </>
  ) : (
    <></>
  )
}

export default ForgotPasswordReset
