import {ID, Response} from '../../../../../_metronic/helpers'
import {Paging} from '../../../../models/Response'
export type Course = {
  id: number | undefined
  code: string
  description: string
  subjectId?: number
  semesterId?: number
  subjectName?: string
  subjectCode?: string
  degreeName?: string
  semesterMajorName?: string
  semesterName?: string
  attendanceScore?: number
  assignmentScore?: number
  quizScore?: number
  finalExamScore?: number
  courseSessions?: CourseSession[]
  courseAssignments?: CourseSession[]
  courseQuizzes?: any[]
  syllabusFile?: File
  rpsFileId?: number
  uniqueId?: string
  fileName?: string
  courseGradings?: CourseGrading[]
  showQuiz?: boolean
  degreeId?: number
  customMajorName?: string
  showSessionTab?: boolean
  showMaterialTab?: boolean
}

export type CourseQueryResponse = Response<Array<Course>>

export const initialCourse: Course = {
  code: '',
  description: '',
  subjectName: '',
  semesterName: '',
  subjectId: undefined,
  semesterId: undefined,
  id: undefined,
  attendanceScore: 25,
  assignmentScore: 25,
  quizScore: 25,
  finalExamScore: 25,
  courseSessions: [],
  courseGradings: [],
}

export type CourseSession = {
  id?: number | undefined
  courseId?: number | undefined
  name?: string
  description?: string
  sequence?: number | undefined
  materials?: CourseSessionMaterial[]
}

export type CourseGrading = {
  id?: number | undefined
  courseId?: number | undefined
  typeId?: number | undefined
  typeName?: string
  weight?: number | undefined
}

export const initialCourseSession: CourseSession = {
  courseId: undefined,
  name: '',
  description: '',
  sequence: undefined,
  materials: [],
}

export type CourseSessionMaterial = {
  sessionId?: number | undefined
  id?: number | undefined
  fileId?: number | undefined
  name?: string
  duration?: number | undefined
  file?: any
}

export type CourseAssignment = {
  id?: number | undefined
  courseId?: number | undefined
  name?: string
  description?: string
  fileName?: string
  assignmentFile?: File
}

export const initialCourseAssignment: CourseAssignment = {
  courseId: undefined,
  name: '',
  description: '',
  assignmentFile: undefined,
}

export type CourseAssignmentQueryResponse = Response<Paging<CourseAssignment>>

export type CourseQuiz = {
  id?: number | undefined
  courseId?: number | undefined
  name?: string
  description?: string
  duration?: number | undefined
  multipleChoicePortion?: number | undefined
  essayNumberPortion?: number | undefined
  essayTextPortion?: number | undefined
  isExam?: boolean | undefined
  autoPublish?: boolean | undefined
  autoSubmit?: boolean | undefined
  outputNumber?: boolean | undefined
  randomize?: boolean | undefined
  examTypeId?: number | undefined
  isCreateMeeting?: boolean | undefined
  isDurationOnStart?: boolean | undefined
  useCamera?: boolean | undefined
  maxFraud?: number | string | undefined
  dataStatusId?: number | undefined
  isPrevButtonDisabled?: boolean | undefined
  useTimerPerQuestion?: boolean | undefined
}

export const initialCourseQuiz: CourseQuiz = {
  courseId: undefined,
  name: '',
  description: '',
  duration: 0,
  multipleChoicePortion: 0,
  essayNumberPortion: 0,
  essayTextPortion: 0,
  isExam: false,
  autoPublish: true,
  autoSubmit: false,
  randomize: true,
  isCreateMeeting: false,
  isDurationOnStart: false,
  useCamera: true,
  maxFraud: 0,
  isPrevButtonDisabled: false,
  useTimerPerQuestion: false,
}

export type CourseQuizQueryResponse = Response<Paging<CourseQuiz>>

export type CourseQuizQuestion = {
  id?: number
  quizId?: number
  typeId?: number
  seq?: number
  questionText?: string
  typeName?: string
  option1?: string
  option2?: string
  option3?: string
  option4?: string
  option5?: string
  option6?: string
  correctAnswer?: number
  correctAnswerNumber?: number
  correctAnswerEssay?: string
  correctScore?: number
  dataStatusId?: number
  randomOptions?: number[]
  totalQuestion?: number
}

export const initialCourseQuizQuestion: CourseQuizQuestion = {
  quizId: undefined,
  typeId: 1,
  questionText: '',
  option1: '',
  option2: '',
  option3: '',
  option4: '',
  option5: '',
  option6: '',
  correctScore: 1,
}

export type CourseQuizQuestionQueryResponse = Response<Paging<CourseQuizQuestion>>

export type CourseDuplicate = {
  id: ID
  semesterId?: number | null
}

export const initialCourseDuplicate: CourseDuplicate = {
  id: 0,
  semesterId: null,
}

export type CourseMaterial = {
  id?: number
  fileName?: string
  originFileName?: string
  courseId?: number
  name?: string
  duration: number
  dataStatusId?: number
  fielId?: number
  size?: number
  uniqueId?: string
  guid?: number
  isLoading: boolean
}

export const initialCourseMaterial: CourseMaterial = {
  duration: 0,
  isLoading: false,
}
