// CustomSelect.js
import {FC, useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import Select from 'react-select'
import clsx from 'clsx'
import {stringifyRequestQuery, useDebounce} from '../../../../../_metronic/helpers'
import {
  getStudentMajor,
  getStudentMajorSelect,
} from '../../../students/student-major-modal/core/_requests'
import {useField, useFormikContext} from 'formik'

interface StudentMajorSelectProps {
  id: any
  name: any
  nim: any
  email: any
  majorId: number
  className?: string
  initialOptions?: any[]
  disabled?: boolean
  excludedIds?: number[]
  majorStatusId?: number,
}

const ClassFormStudentSelect: FC<StudentMajorSelectProps> = ({
  id,
  name,
  nim,
  email,
  majorId,
  className = '',
  initialOptions = [],
  disabled = false,
  excludedIds = [],
  majorStatusId,
  ...rest
}) => {
  const [keyword, setKeyword] = useState<string | undefined>(undefined)
  const debouncedSearchTerm = useDebounce(keyword, 150)
  const {setFieldValue} = useFormikContext()
  const [field] = useField(id)

  const {isLoading, data, refetch} = useQuery(
    ['student-major-select', debouncedSearchTerm],
    () =>
      getStudentMajorSelect(
        stringifyRequestQuery({
          keyword: debouncedSearchTerm,
          filter: {
            majorId: majorId,
            majorStatusId: majorStatusId || null,
          },
        }),
        excludedIds,
      ),
    {enabled: false, select: (data) => data.data}
  )

  const handleChange = (selectedOption) => {
    setFieldValue(id, selectedOption.value)
    let student = data?.records?.find((option) => option.id === selectedOption.value)
    // setFieldValue(name, student?.name)
    setFieldValue(nim, student?.nim)
    setFieldValue(email, student?.email)
  }

  const options = data?.records?.some((item) => item.id !== null)
    ? data?.records?.map((option) => ({
        value: option.id,
        label: option.name,
      }))
    : initialOptions

  const handleInputChange = (value) => {
    setKeyword(value)
  }

  const handleOnOpen = () => {
    handleInputChange('')
    refetch()
  }

  useEffect(() => {
    if (debouncedSearchTerm === undefined) return
    refetch()
  }, [debouncedSearchTerm])

  return (
    <Select
      {...field}
      //   name={field.name}
      isLoading={isLoading}
      options={options}
      onChange={handleChange}
      value={options.find((option) => option.value === field.value)} // Set the initial selected value
      classNamePrefix='react-select'
      className={clsx(
        'react-select',
        className
        // {'is-invalid': form.touched[field.name] && form.errors[field.name]},
        // {'is-valid': form.touched[field.name] && !form.errors[field.name]}
      )}
      onInputChange={handleInputChange}
      onMenuOpen={handleOnOpen}
      styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
      menuPortalTarget={document.body}
      menuShouldScrollIntoView={false}
      isDisabled={disabled}
      placeholder="Type to search..."
    />
  )
}

export default ClassFormStudentSelect
