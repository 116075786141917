// CustomSelect.js
import {FC, useEffect, useState} from 'react'
import {useInfiniteQuery} from 'react-query'
import Select from 'react-select'
import {getSubject} from '../../subjects/subject-list/core/_requests'
import clsx from 'clsx'
import {stringifyRequestQuery, useDebounce} from '../../../../_metronic/helpers'
import {SelectWithMoreWrapper} from '../../../components/LoadMore'

interface SubjectSelectProps {
  field: any
  form: any
  className?: string
  defaultOption?: any
  initialOptions?: any[]
  isClearable?: boolean
  isMulti?: boolean
  disabled?: boolean
  onChange?: (data) => void
}

const SubjectSelect: FC<SubjectSelectProps> = ({
  field,
  form,
  isClearable = false,
  className = '',
  defaultOption,
  initialOptions = [],
  isMulti,
  disabled = false,
  onChange,
  ...rest
}) => {
  const {setFieldValue} = form
  const [keyword, setKeyword] = useState<string | undefined>(undefined)
  const debouncedSearchTerm = useDebounce(keyword, 150)
  const [selectedValues, setSelectedValues] = useState<any[]>(initialOptions ?? [])
  const [options, setOptions] = useState<any[]>([...initialOptions])

  const fetchData = async ({pageParam = 1}) => {
    try {
      const response = await getSubject(
        stringifyRequestQuery({
          keyword: debouncedSearchTerm,
          pageSize: 10,
          pageNumber: pageParam,
        })
      )
      return response.data
    } catch (ex) {
      console.log(ex)
      return undefined
    }
  }

  const {isLoading, data, refetch, fetchNextPage, hasNextPage, isFetching} = useInfiniteQuery(
    'subject-select',
    fetchData,
    {
      enabled: false,
      getNextPageParam: (lastPage) => {
        if (!lastPage?.totalPages) return undefined

        const hasNext = lastPage.totalPages > lastPage.pageNumber
        if (hasNext) {
          return lastPage.pageNumber + 1
        } else {
          return undefined
        }
      },
      getPreviousPageParam: (lastPage) => {
        if (!lastPage) return undefined

        const prev = lastPage.pageNumber - 1
        return prev < 1 ? undefined : prev
      },
      cacheTime: 0,
    }
  )

  const handleChange = (selectedOption) => {
    console.log(selectedOption)
    if (isMulti) {
      const data = selectedOption.map((q) => {
        return {
          subjectId: q.value,
          subjectName: q.label,
          subjectCode: q.code,
          degreeName: q.degree,
          degreeId: q.degreeId,
        }
      })
      setFieldValue(field.name, data)
      // setSelectedValues(selectedOption)
    } else {
      setFieldValue(field.name, selectedOption.value)
    }

    if (onChange) {
      onChange(selectedOption)
    }
  }

  useEffect(() => {
    let list = data?.pages?.flatMap((q) => q?.records) || []
    list = list.filter((q) => q !== undefined)
    const result = list.map((q) => {
      return {
        value: q?.id,
        label: q?.name,
        code: q?.code,
        degree: q?.degreeName,
        degreeId: q?.degreeId,
      }
    })

    setOptions(result)
  }, [data])

  const handleInputChange = (value) => {
    setKeyword(value)
  }

  useEffect(() => {
    if (debouncedSearchTerm === undefined) return
    refetch()
  }, [debouncedSearchTerm])

  const handleScrollToBottom = () => {
    if (hasNextPage) {
      fetchNextPage()
    }
  }

  const handleLabel = (option: any) => {
    return `${option.degree} - ${option.label} (${option.code})`
  }

  // const SubjectOptions = props => {
  //   const { innerProps, innerRef } = props;
  //
  //   return (
  //     <article ref={innerRef} {...innerProps} className="custom-option">
  //       <h4>{props.data.label}</h4>
  //       <div className="sub">{props.data.code} </div>
  //     </article>
  //   );
  // }

  const formatLabel = (option: any) => {
    return (
      <article className='custom-option text-wrap' style={{lineHeight: 1.1}}>
        <div>
          {option.degree} - {option.label} <small className='sub'>({option.code})</small>
        </div>
      </article>
    )
  }

  // useEffect(() => {
  //     setOptions(initialOptions)
  // }, [initialOptions])

  useEffect(() => {
    if (isMulti && field.value) {
      console.log(field.value)
      const result = field.value.map((q) => {
        return {
          value: q?.subjectId,
          label: q?.subjectName,
          code: q?.subjectCode,
          degree: q?.degreeName,
          degreeId: q?.degreeId,
        }
      })

      setSelectedValues(result)
    }
  }, [field])

  return (
    <SelectWithMoreWrapper hasMore={hasNextPage || false} isLoading={isFetching}>
      <Select
        {...rest}
        name={field.name}
        isLoading={isLoading}
        options={options}
        onChange={handleChange}
        value={isMulti ? selectedValues : field.value ? options.find((option) => option.value === field.value) : null} // Set the initial selected value
        classNamePrefix="react-select"
        isMulti={isMulti}
        className={clsx(
          'react-select',
          className,
          {'is-invalid': form.touched[field.name] && form.errors[field.name]},
          {'is-valid': form.touched[field.name] && !form.errors[field.name]},
        )}
        isClearable={isClearable}
        onInputChange={handleInputChange}
        onMenuOpen={() => handleInputChange('')}
        styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
        menuPortalTarget={document.body}
        menuShouldScrollIntoView={false}
        onMenuScrollToBottom={handleScrollToBottom}
        getOptionLabel={(option) => handleLabel(option)}
        formatOptionLabel={(data) => formatLabel(data)}
        placeholder="Type to search..."
        isDisabled={disabled}
      />
    </SelectWithMoreWrapper>
  )
}

export default SubjectSelect
