import {useEffect, useRef, useState} from 'react'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {initialQueryState, KTIcon} from '../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {Dropdown} from 'react-bootstrap'
import Select from 'react-select'
import moment from 'moment'

const GraduatedStudentListFilter = () => {
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const [year, setYear] = useState<number | null>(null)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [totalFilter, setTotalFilter] = useState(0)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const startYear = moment().subtract(4, 'y').year()
  const endYear = moment().add(1, 'y').year()

  const options: {label: string; value: string}[] = []

  for (let i = startYear; i <= endYear; i++) {
    options.push({
      label: i.toString(),
      value: i.toString(),
    })
  }

  const resetData = () => {
    setYear(null)
    updateState({filter: undefined, ...initialQueryState})
    setDropdownOpen(false)
    setTotalFilter(0)
  }

  const filterData = () => {
    updateState({
      filter: {year},
      ...initialQueryState,
    })
    setDropdownOpen(false)
    setTotalFilter(year === null ? 0 : 1)
  }

  const changeHandler = (selected) => {
    setYear(selected?.value)
  }

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen)
  }

  const dropdownRef = useRef<any>(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <Dropdown
      className='me-3'
      show={dropdownOpen}
      onToggle={toggleDropdown}
      autoClose={false}
      ref={dropdownRef}
    >
      <Dropdown.Toggle id='dropdown-custom-components' className='w-md-auto w-100' variant='light'>
        <KTIcon iconName='filter' className='fs-2' />
        <span className='pe-3'>Filter</span>
        {totalFilter > 0 && (
          <span className='badge badge-square badge-dark text-bg-dark' style={{lineHeight: '1'}}>
            {totalFilter}
          </span>
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu className='p-8' style={{width: 300}} align='end'>
        <div className='mb-10'>
          <label className='form-label fs-6 fw-bold mb-2'>Year:</label>
          <Select
            isClearable={false}
            placeholder='Select Year'
            isLoading={isLoading}
            options={options}
            onChange={changeHandler}
            value={year ? options.find((option) => option.value === year.toString()) : null}
            classNamePrefix='react-select'
            className={'react-select'}
            onInputChange={() => console.log('on input change')}
            onMenuOpen={() => console.log('on menu open')}
            styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
            menuPortalTarget={document.body}
            menuShouldScrollIntoView={false}
          />
        </div>
        <div className='d-flex justify-content-end'>
          <button
            type='button'
            disabled={isLoading}
            onClick={resetData}
            className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
            data-kt-menu-dismiss='true'
            data-kt-student-table-filter='reset'
          >
            Reset
          </button>
          <button className='btn btn-warning' type='button' onClick={filterData}>
            Apply
          </button>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export {GraduatedStudentListFilter}
