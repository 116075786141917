import React, {useEffect, useState} from 'react'
import {ISemester} from '../../../../models/ISemester'
import {getClasses} from '../../core/_request'
import {IClass} from '../../../../models/IClass'
import {KTIcon, stringifyRequestQuery, useDebounce} from '../../../../../_metronic/helpers'
import SubjectSelect from '../../../subjects/components/SubjectSelect'
import {ISubject} from '../../../../models/ISubject'
import {showError} from '../../../../utils/toast'
import {getCurrentClasses, getCurrentClassesByOperator} from '../../../classes/core/_request'
import {ClassWrapper} from '../../../classes/class-list/ClassWrapper'
import OperatorClassWrapper from './OperatorClassWrapper'
import moment from 'moment'
import DegreeSelect from '../../../degrees/components/DegreeSelect'

const OperatorClassList = () => {
  const [selectedSemester, setSelectedSemester] = useState<ISemester | undefined>()
  const [selectedSubject, setSelectedSubject] = useState<ISubject>()
  const [initialSemesters, setInitialOptions] = useState<any[]>([])
  const [initialSubjects, setInitialSubjects] = useState<any[]>([])
  const [classes, setClasses] = useState<IClass[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [nextPage, setNextPage] = useState<number>()
  const [keyword, setKeyword] = useState('')
  const [selectedDegreeId, setSelectedDegreeId] = useState<number>()
  const startOfDay = moment().startOf('days').utc().toISOString()
  const endOfDay = moment().endOf('days').utc().toISOString()

  const debouncedSearchTerm = useDebounce(keyword, 475)

  useEffect(() => {
    getData(true)
  }, [selectedSubject, selectedDegreeId, debouncedSearchTerm])

  const getData = (reset: boolean = false) => {
    const page = reset ? 1 : nextPage

    if (page) {
      setIsLoading(true)
      let query = stringifyRequestQuery({
        pageSize: 30,
        pageNumber: page,
        keyword: debouncedSearchTerm,
        filter: {
          startDate: startOfDay,
          endDate: endOfDay,
          degreeId: selectedDegreeId,
        }
      })
      if (selectedSubject?.id) {
        query += `&subjectId=${selectedSubject.id}`
      }
      getCurrentClassesByOperator(query)
        .then((resp) => {
          if (resp.data) {
            setClasses((prev) => {
              if (resp.data) {
                let newInstance: any = []
                if (page == 1) {
                  newInstance = [...resp.data.records]
                } else {
                  newInstance = [...prev, ...resp.data.records]
                }

                return newInstance || []
              }
            })
          } else {
            setClasses([])
          }
          setIsLoading(false)
        })
        .catch((err: any) => {
          showError(err.message)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const handleLoadMore = () => {
    getData()
  }

  return (
    <>
      <div className='d-flex flex-lg-row flex-column justify-content-lg-between mb-5 align-items-lg-center'>
        {/*<h1 className='page-heading text-dark fw-bold fs-3 my-0'>Classes</h1>*/}
        <h1 className='page-heading d-flex text-dark fw-bold fs-3 my-0 flex-column justify-content-center'>
          Classes
          {selectedSemester && (
            <span className='page-desc text-muted fs-7 fw-semibold pt-2'>
              {selectedSemester.name}
            </span>
          )}
        </h1>
        <div className='d-flex flex-lg-row flex-column'>
          <div className='d-flex align-items-center position-relative w-100 w-lg-250px mt-lg-0 mt-5'>
            <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
            <input
              type='text'
              id='kt_filter_search'
              className='form-control form-control-white w-100 form-control-xl fs-6 fw-normal ps-9'
              placeholder='Search'
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
          </div>
          {/*<SubjectSelect*/}
          {/*  className='w-lg-350px w-100 mt-lg-0 mt-5 ms-lg-2'*/}
          {/*  selected={selectedSubject}*/}
          {/*  setSelected={setSelectedSubject}*/}
          {/*  isClearable={true}*/}
          {/*  initialOptions={initialSubjects}*/}
          {/*/>*/}
          <DegreeSelect
            className='w-lg-350px w-100 mt-lg-0 mt-5 ms-lg-2'
            selectedValue={selectedDegreeId}
            onChange={(selected) => setSelectedDegreeId(selected?.value)}
            isUseFormik={false}
            isClearable={true}
          />
        </div>
      </div>
      <OperatorClassWrapper
        classes={classes}
        handleLoadMore={handleLoadMore}
        isLoading={isLoading}
      />
    </>
  )
}

export default OperatorClassList
