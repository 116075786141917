import {StudentSemesterListSearch} from './StudentSemesterListSearch'
import {StudentSemesterListToolbar} from './StudentSemesterListToolbar'

const StudentSemesterListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <StudentSemesterListSearch />
      <div className='card-toolbar'>
        <StudentSemesterListToolbar />
      </div>
    </div>
  )
}

export {StudentSemesterListHeader}
