// @ts-nocheck
import clsx from 'clsx'
import {FC} from 'react'
import {Row} from 'react-table'
import {GraduatedStudent} from '../../core/_model'

type Props = {
  row: Row<GraduatedStudent>
}

const CustomRow: FC<Props> = ({row}) => (
    <tr {...row.getRowProps()}>
      {row.cells.map((cell, i) => {
        if (row.original.questionId === -1 && cell.column.id !== 'question' && cell.column.id !== 'average') {
          return ''
        }
        return (
          <td
            colSpan={row.original.questionId === -1 && cell.column.id === 'question' ? 8 : undefined}
            {...cell.getCellProps()}
            className={clsx(
              {'text-end min-w-100px': cell.column.id === 'actions'},
              {'text-end': row.original.questionId === -1 && cell.column.id === 'question'},
              {'fs-4': row.original.questionId === -1},
              cell.column.className
            )}
          >
            {cell.render('Cell')}
          </td>
        )
      })}
    </tr>
  )

export {CustomRow}
