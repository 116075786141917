import axios, {AxiosResponse} from 'axios'
import {IBaseResponse, IInfiteScrollData} from '../../../auth'
import {ReferenceType} from './_model'

const API_URL = process.env.REACT_APP_BASE_API_URL
const URL = `${API_URL}/references`

const getReferences = (query: string): Promise<IBaseResponse<IInfiteScrollData<ReferenceType>>> => {
  return axios
    .get(`${URL}?${query}`)
    .then((d: AxiosResponse<IBaseResponse<IInfiteScrollData<ReferenceType>>>) => d.data)
}

const download = async (unique: string, fileName: string): Promise<any> => {
  try {
    var response = await axios.get(`${URL}/download/${unique}`, {
      responseType: 'blob',
    })

    const blob = new Blob([response.data], {type: response.headers['content-type']})
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  } catch (err: any) {
    if (err.type === 'application/json') {
      const json: IBaseResponse<any> = JSON.parse(await err.text())

      if (json.code !== '0000') {
        throw new Error(json.message)
      }
    }
    throw err
  }
}

const downloadExternalLink = async (link: string, fileName: string): Promise<any> => {
  try {
    var response = await axios.get(link, {
      responseType: 'blob',
    })

    const blob = new Blob([response.data], {type: response.headers['content-type']})
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  } catch (err: any) {
    if (err.type === 'application/json') {
      const json: IBaseResponse<any> = JSON.parse(await err.text())

      if (json.code !== '0000') {
        throw new Error(json.message)
      }
    }
    throw err
  }
}

export {getReferences, download, downloadExternalLink}
