import {FC} from 'react'
import {IClassStudentScore} from '../../../models/IClassStudent'
import clsx from 'clsx'

type IProp = {
  data: IClassStudentScore | undefined
}

const DetailScoreTable: FC<IProp> = ({data}) => {
  return (
    <table className='table table-bordered'>
      <thead>
        <tr className='text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0'>
          <th className='text-center w-75px' rowSpan={2}>
            No
          </th>
          <th className='min-w-100px' rowSpan={2}>
            Course Name
          </th>
          <th className='text-center min-w-100px' colSpan={data?.gradingTypes?.length}>
            Score
          </th>
          <th className='text-center min-w-50px' rowSpan={2}>
            Total Score
          </th>
          <th className='text-center min-w-65px' rowSpan={2}>
            Grade
          </th>
          <th className='text-center min-w-65px' rowSpan={2}>
            SKS
          </th>
          <th className='text-center min-w-65px' rowSpan={2}>
            Score SKS
          </th>
        </tr>
        <tr>
          {data?.gradingTypes?.map((m, index) => (
            <th
              className='bg-white sticky-top text-center text-uppercase'
              style={{minWidth: '125px'}}
              key={index}
            >
              {m.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className=''>
        {data?.details?.map((item, index) => {
          return (
            <tr key={index}>
              <td className='text-center'>{index + 1}</td>
              <td>{item.courseName}</td>
              {/* <td className='text-end'>{item.attendanceScore}</td>
            <td className='text-end'>{item.assignmentScore}</td>
            <td className='text-end'>{item.quizScore}</td>
            <td className='text-end'>{item.finalExamScore}</td> */}
              {item.scoring?.map((m, cindex) => (
                <td
                  key={cindex}
                  className={clsx('text-center', {
                    'bg-light-danger': m.skip,
                  })}
                >
                  {m.skip ? '-' : m.score}
                </td>
              ))}
              <td className='text-end'>{item.totalScore ?? '-'}</td>
              <td className='text-center'>{item.grade ?? '-'}</td>
              <td className='text-center'>{item.sks}</td>
              <td className='text-center'>{item.scoreSks ?? '-'}</td>
            </tr>
          )
        })}
      </tbody>
      <tfoot className='fw-semibold'>
        <tr>
          <td className={'text-end'} colSpan={(data?.gradingTypes?.length ?? 0) + 4}>
            TOTAL
          </td>
          <td className={'text-center fw-bold'}>{data?.totalSks}</td>
          <td className={'text-center fw-bold'}>{data?.totalScoreSks}</td>
        </tr>
        <tr>
          <td className={'text-end'} colSpan={(data?.gradingTypes?.length ?? 0) + 4}>
            IP SEMESTER
          </td>
          <td className={'text-center'}>{data?.gpa ?? '-'}</td>
          <td></td>
        </tr>
      </tfoot>
    </table>
  )
}

export default DetailScoreTable
