import {FC, useEffect, useRef, useState} from 'react'
import {KTIcon, initialQueryState} from '../../../../../../../_metronic/helpers'
import moment from 'moment'
import {ISemester} from '../../../../../../models/ISemester'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import SemesterSelect from '../../../../../classes/components/SemesterSelect'
import DatePicker from '../../../../../../components/DatePicker'
import {PendingFilter} from './PendingFilter'
import {Dropdown} from 'react-bootstrap'

type SurveyStudentInfoFilterProps = {
  keyword: string
  setKeyword: any
}

const SurveyStudentInfoFilter: FC<SurveyStudentInfoFilterProps> = ({keyword, setKeyword}) => {
  const sDate = moment().startOf('month').startOf('day').toISOString()
  const eDate = moment().endOf('month').endOf('day').toISOString()
  const {updateState} = useQueryRequest()
  const [selectedSemester, setSelectedSemester] = useState<ISemester>()
  const [startDate, setStartDate] = useState<string>(sDate)
  const [endDate, setEndDate] = useState<string>(eDate)
  const [status, setStatus] = useState<number>()
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [totalFilter, setTotalFilter] = useState(3)

  useEffect(() => {
    setSelectedSemester(undefined)
    setStatus(-1)
    filter()

    function handleClickOutside(event) {
      const classListString = event.target.className
      const notClosed =
        /flatpickr|arrowDown|arrowUp|numInput cur-year|react-select|form-control|dropdown/

      if (typeof classListString === 'object') {
        setDropdownOpen(true)
      } else if (
        !notClosed.test(classListString) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setDropdownOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const filter = () => {
    updateState({
      ...initialQueryState,
      filter: {
        semesterId: selectedSemester?.id || null,
        startDate: startDate,
        endDate: endDate,
        status: status,
      },
    })
    setDropdownOpen(false)
  }

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen)
  }

  const resetData = () => {
    setSelectedSemester(undefined)
    setStatus(-1)
    setTotalFilter(3)
    setDropdownOpen(false)
    setStartDate(sDate)
    setEndDate(eDate)
    setKeyword('')

    updateState({
      ...initialQueryState,
      filter: {
        semesterId: selectedSemester?.id || null,
        startDate: sDate,
        endDate: eDate,
        status: status,
      },
    })
    setDropdownOpen(false)
  }

  const dropdownRef = useRef<any>(null)

  return (
    <Dropdown
      className='me-3'
      show={dropdownOpen}
      onToggle={toggleDropdown}
      autoClose={false}
      ref={dropdownRef}
    >
      <Dropdown.Toggle id='dropdown-custom-components' className='w-md-auto w-100' variant='light'>
        <KTIcon iconName='filter' className='fs-2' />
        <span className='pe-3'>Filter</span>
        {totalFilter > 0 && (
          <span className='badge badge-square badge-dark text-bg-dark' style={{lineHeight: '1'}}>
            {totalFilter}
          </span>
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu className='p-8' align='end'>
        <div className='mb-10'>
          <label className='form-label fs-6 fw-bold mb-2'>Semester:</label>
          <SemesterSelect
            className='col-xl-4 w-xxl-350px'
            selected={selectedSemester}
            setSelected={(e) => {
              if (e) {
                setTotalFilter(4)
              } else {
                setTotalFilter(3)
              }
              setSelectedSemester(e)
            }}
            isClearable={true}
          />
        </div>
        <div className='mb-10'>
          <label className='form-label fs-6 fw-bold mb-2'>Start:</label>
          <DatePicker
            dateOnly={true}
            useFormik={false}
            onChange={(e: Date[]) => {
              setStartDate(moment(e[0]).startOf('day').toISOString())
            }}
            field={''}
            form={''}
            label='Select Start Date'
            name='startDate'
            isStatic={false}
            value={startDate}
            closeOnSelect={true}
          />
        </div>
        <div className='mb-10'>
          <label className='form-label fs-6 fw-bold mb-2'>End:</label>
          <DatePicker
            dateOnly={true}
            useFormik={false}
            onChange={(e: Date[]) => setEndDate(moment(e[0]).endOf('day').toISOString())}
            field={''}
            form={''}
            label='Select End Date'
            name='endDate'
            isStatic={false}
            value={endDate}
            closeOnSelect={true}
          />
        </div>
        <div className='mb-10'>
          <label className='form-label fs-6 fw-bold mb-2'>Pending?</label>
          <PendingFilter
            selectedValue={status}
            allowClear={false}
            isUseFormik={false}
            onChange={(selected) => {
              setStatus(selected?.value)
            }}
            className='w-100'
          />
        </div>

        <div className='d-flex justify-content-end'>
          <button
            type='button'
            onClick={resetData}
            className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
            data-kt-menu-dismiss='true'
            data-kt-student-table-filter='reset'
          >
            Reset
          </button>
          <button className='btn btn-warning' type='button' onClick={filter}>
            Apply
          </button>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export {SurveyStudentInfoFilter}
