interface TruncateStringProps {
  text: string
  maxLength: number
}

const TruncateString: React.FC<TruncateStringProps> = ({text, maxLength}) => {
  if (text.length <= maxLength) {
    return <span>{text}</span>
  } else {
    const truncatedText = text.substring(0, maxLength) + '...'
    return <span title={text}>{truncatedText}</span>
  }
}

const pascalCaseToSpace = (str) => {
  var words = str.match(/[A-Z]?[a-z]+|[0-9]+/g)
  if (!words) return '' // If no words found, return empty string
  return words.join(' ')
}

const splitString = (str, maxLength) => {
  let chunks: string[] = []
  let currentChunk = ''

  for (var i = 0; i < str.length; i++) {
    currentChunk += str[i]

    if (currentChunk.length === maxLength) {
      if (str[i] === ' ') {
        chunks.push(currentChunk)
        currentChunk = ''
      } else {
        var lastSpaceIndex = currentChunk.lastIndexOf(' ')
        if (lastSpaceIndex !== -1) {
          chunks.push(currentChunk.substring(0, lastSpaceIndex))
          currentChunk = currentChunk.substring(lastSpaceIndex + 1)
        } else {
          chunks.push(currentChunk)
          currentChunk = ''
        }
      }
    }
  }

  if (currentChunk.length > 0) {
    chunks.push(currentChunk)
  }

  return chunks
}

const getInitialString = (inputString) => {
  let words = inputString.split(/\s+/)
  let firstChars = words.map((word) => word.charAt(0))

  return firstChars.join('').toUpperCase()
}

const generateGUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

const getWordsWithBrackets = (str) => {
  // Use regular expression to match words containing [ or ]
  const regex = /\b\w*[\[\]]\w*\b/g
  return str.match(regex) || []
}

const isContainsBrackets = (str: string): boolean => {
  const regex = /\b\w*[\[\]]\w*\b/g
  return regex.test(str)
}

export {
  TruncateString,
  pascalCaseToSpace,
  splitString,
  getInitialString,
  generateGUID,
  getWordsWithBrackets,
  isContainsBrackets,
}
