// @ts-nocheck
import {Column} from 'react-table'
import {ClassMeetType} from '../../core/_mode'
import {ClassmeetSemesterCustomHeader} from './ClassmeetSemesterCustomHeader'

const classMeetColumns: ReadonlyArray<Column<ClassMeetType>> = [
  {
    Header: (props) => (
      <ClassmeetSemesterCustomHeader
        tableProps={props}
        title='Semester'
        className='min-w-125px'
        enableSort={true}
      />
    ),
    accessor: 'name',
    id: 'name',
  },
  {
    Header: (props) => (
      <ClassmeetSemesterCustomHeader
        tableProps={props}
        title='Meet Type'
        className='min-w-125px'
      />
    ),
    accessor: 'typeName',
    id: 'typeId',
  },
  {
    Header: (props) => (
      <ClassmeetSemesterCustomHeader
        tableProps={props}
        title='Total'
        className='min-w-125px'
        enableSort={true}
      />
    ),
    accessor: 'total',
    id: 'total',
  },
]

export {classMeetColumns}
