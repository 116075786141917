import {KTCard} from '../../../../../_metronic/helpers'
import {StudentExamListHeader} from './components/header/StudentExamListHeader'
import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {StudentExamTable} from './table/StudentExamTable'

const StudentExamList = () => {
  return (
    <>
      <div className='d-flex flex-row justify-content-between mb-8'>
        <h1 className='page-heading text-dark fw-bold fs-3 my-0'>Report Student Exam</h1>
      </div>
      <KTCard>
        <StudentExamListHeader />
        <StudentExamTable />
      </KTCard>
    </>
  )
}

const StudentExamListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <StudentExamList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {StudentExamListWrapper}
