// @ts-nocheck
import {Column} from 'react-table'
import {ClassMeetType} from '../../core/_mode'
import {LecturerSubjectCustomHeader} from './LecturerSubjectCustomHeader'

const lecturerSubjectColumns: ReadonlyArray<Column<ClassMeetType>> = [
  {
    Header: (props) => (
      <LecturerSubjectCustomHeader
        tableProps={props}
        title='Subject'
        className='min-w-125px'
        enableSort={true}
      />
    ),
    accessor: 'subjectName',
    id: 'subjectName',
  },
  {
    Header: (props) => (
      <LecturerSubjectCustomHeader
        tableProps={props}
        title='Major'
        className='min-w-125px'
        enableSort={true}
      />
    ),
    accessor: 'majorName',
    id: 'majorName',
    Cell: ({...props}) =>
      `${props.data[props.row.index].degreeName} - ${props.data[props.row.index].majorName}`,
  },
  {
    Header: (props) => (
      <LecturerSubjectCustomHeader
        tableProps={props}
        title='Total All Lecturers'
        className='min-w-125px'
        enableSort={true}
      />
    ),
    accessor: 'totalLecturer',
    id: 'totalLecturer',
  },
  {
    Header: (props) => (
      <LecturerSubjectCustomHeader
        tableProps={props}
        title='Total Permanent Lecturers'
        className='min-w-125px'
        enableSort={true}
      />
    ),
    accessor: 'totalPermanentLecturer',
    id: 'totalPermanentLecturer',
  },
  {
    Header: (props) => (
      <LecturerSubjectCustomHeader
        tableProps={props}
        title='Total Non-Permanent Lecturers'
        className='min-w-125px'
        enableSort={true}
      />
    ),
    accessor: 'totalNonPermanentLecturer',
    id: 'totalNonPermanentLecturer',
  },
]

export {lecturerSubjectColumns}
