const PreventAutoComplete = () => {
  return <>
    <input
      type='email'
      name='email'
      id='email'
      className="form-control"
      style={{width: 0, height: 0, padding: 0, margin: 0, border: 0}}
      autoComplete="email"
      value=""
      readOnly
    />
    <input
      type='password'
      name='password'
      className="form-control"
      style={{width: 0, height: 0, padding: 0, margin: 0, border: 0}}
      id='password'
      autoComplete="password"
      value=""
      readOnly
    />
  </>
}

export default PreventAutoComplete