// import React from 'react';
// import * as DOMPurify from 'dompurify';
// import './SafeHtmlRenderer.scss'

// function SafeHTMLRenderer({ htmlString }) {
//     // Sanitize the HTML string
//     const sanitizedHTML = DOMPurify.sanitize(htmlString);

//     return (
//         <div className="html-renderer" dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
//     );
// }

// export default SafeHTMLRenderer;

import React from 'react'
import DOMPurify from 'dompurify'
import clsx from 'clsx'

// Define the Props interface for the SanitizedHTML component
interface SanitizedHTMLProps {
  htmlString: string | undefined
  className?: string
}

// Define the SanitizedHTML component
const SafeHTMLRenderer: React.FC<SanitizedHTMLProps> = ({htmlString, className = ''}) => {
  // Sanitize HTML content while allowing specific styling
  const sanitizedHTML = () => {
    const cleanHTML = DOMPurify.sanitize(htmlString, {
      ADD_ATTR: ['style'], // Allow style attributes
      ADD_TAGS: ['style'], // Allow style tags
    })
    return {__html: cleanHTML}
  }

  // Return JSX to render the SanitizedHTML component
  return <div className={clsx('html-renderer', className)} dangerouslySetInnerHTML={sanitizedHTML()} />
}

export default SafeHTMLRenderer
