import axios from 'axios'
import {IBaseResponse} from '../../../../auth'
import {ResultQuizType} from './_model'

const API_URL = process.env.REACT_APP_BASE_API_URL
const RESULT_QUIZ = `${API_URL}/reports/result-quiz`

const getResultQuiz = async (query: string): Promise<IBaseResponse<ResultQuizType[]>> => {
  const response = await axios.get(`${RESULT_QUIZ}?${query}`)
  return response.data
}

const getResultQuizDetail = async (
  courseQuizId: number,
  query: string
): Promise<IBaseResponse<ResultQuizType[]>> => {
  const response = await axios.get(`${RESULT_QUIZ}/${courseQuizId}?${query}`)
  return response.data
}

export {getResultQuiz, getResultQuizDetail}
