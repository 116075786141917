import {useEffect, useState} from 'react'
import SemesterSelect from '../../../../../classes/components/SemesterSelect'
import {ResultQuizListToolbar} from './ResultQuizListToolbar'
import {ISemester} from '../../../../../../models/ISemester'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {initialQueryState} from '../../../../../../../_metronic/helpers'
import CourseQuizSelect from '../select/CourseQuizSelect'
import {CourseQuiz} from '../../../../../courses/course-list/core/_models'

const ResultQuizListHeader = () => {
  const [selectedSemester, setSelectedSemester] = useState<ISemester>()
  const {refetch} = useQueryResponse()
  const {updateState} = useQueryRequest()
  const [selectedCourseQuiz, setSelectedCourseQuiz] = useState<CourseQuiz | undefined>()
  const [studentName, setStudentName] = useState<string>('')

  useEffect(() => {
    setSelectedCourseQuiz(undefined)
  }, [selectedSemester])

  const filter = () => {
    if (selectedCourseQuiz && selectedSemester) {
      updateState({
        ...initialQueryState,
        keyword: studentName,
        filter: {
          courseQuizId: selectedCourseQuiz.id,
          courseQuizName: selectedCourseQuiz.name,
        },
      })
    }
  }

  return (
    <div className='card-header border-0 pt-6'>
      <div className='d-flex align-items-center position-relative my-1 flex-column flex-xl-row w-100 w-xl-auto'>
        <SemesterSelect
          selected={selectedSemester}
          setSelected={setSelectedSemester}
          className='w-100 w-xl-350px'
        />
        <CourseQuizSelect
          className='w-100 w-xl-350px ms-xl-2 ms-0 mt-2 mt-xl-0'
          selected={selectedCourseQuiz}
          setSelected={setSelectedCourseQuiz}
          semesterId={selectedSemester?.id}
          disabled={!selectedSemester}
        ></CourseQuizSelect>
        <input
          type='text'
          data-kt-lecturer-table-filter='studentName'
          className='form-control w-100 w-xl-250px ms-xl-2 ms-0 mt-2 mt-xl-0'
          placeholder='Search By Name'
          value={studentName}
          onChange={(e) => setStudentName(e.target.value)}
          disabled={!selectedSemester}
        />
        <button
          onClick={filter}
          disabled={!selectedCourseQuiz}
          type='button'
          className='btn btn-primary w-100 w-xl-auto ms-xl-2 ms-0 mt-2 mt-xl-0'
          style={{height: '43px'}}
        >
          Go
        </button>
      </div>
      <div className='card-toolbar'>
        <ResultQuizListToolbar />
      </div>
    </div>
  )
}

export {ResultQuizListHeader}
