import React, {FC} from 'react'
import OperatorClassCard from './OperatorClassCard'
import {IClass} from '../../../../models/IClass'
import {ClassesLoading} from '../../../classes/components/ClassesLoading'
import {KTIcon} from '../../../../../_metronic/helpers'

type Props = {
  classes: IClass[]
  nextPage?: number
  handleLoadMore(): void
  isLoading: boolean
}

const OperatorClassWrapper: FC<Props> = ({classes = [], nextPage, handleLoadMore, isLoading}) => {
  return (
    <>
      <div className='row g-3'>
        {classes.map((c, i) => {
          return (
            <div className='col-md-6 col-xl-4' key={'class-' + i}>
              <OperatorClassCard data={c} />
            </div>
          )
        })}
        {classes.length == 0 && (
          <div className='col-12'>
            <div
              className='card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end'
              style={{
                backgroundColor: 'white',
              }}
            >
              <div className='card-body d-flex align-items-center'>
                <div className='d-flex align-items-center flex-column mt-3 w-100 py-5'>
                  <KTIcon iconName='book-open' iconType='duotone' className='text-primary fs-3x' />
                  <div className='text-muted fs-3 mt-2'>No Classes</div>
                </div>
              </div>
            </div>
          </div>
        )}
        {nextPage && (
          <div className='col-12 float-end'>
            <button type='button' className='btn btn-warning' onClick={handleLoadMore}>
              Load More
            </button>
          </div>
        )}
        {isLoading && <ClassesLoading />}
      </div>
    </>
  )
}

export default OperatorClassWrapper
