import AppPagination from '../../../../../../components/Pagination'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponseLoading, useQueryResponsePagination} from '../../core/QueryResponseProvider'

const GraduatedStudentListPagination = ({totalPages, currentPage, onPageChange}) => {
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const {updateState} = useQueryRequest()
  const updatePage = (page: number | undefined | null) => {
    if (!page || isLoading || pagination.pageNumber === page) {
      return
    }
    const newState = {pageNumber: page, page, pageSize: pagination.pageSize || 10}
    updateState(newState)
  }

  const startRow =
    pagination.totalRecordsFiltered === 0 ? 0 : (currentPage - 1) * (pagination.pageSize ?? 10) + 1
  const endRow =
    pagination.totalRecordsFiltered === 0 ? 0 : currentPage * (pagination.pageSize ?? 10)

  return (
    <div className='row'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
        <span className='fw-bold text-gray-600'>
          {startRow === 0
            ? 'No records'
            : `Showing ${startRow}-${
                endRow > (pagination.totalRecordsFiltered ?? 0)
                  ? pagination.totalRecordsFiltered
                  : endRow
              } of ${pagination.totalRecordsFiltered} records`}
        </span>
      </div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_student_paginate'>
          <AppPagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={updatePage}
          />
        </div>
      </div>
    </div>
  )
}

export {GraduatedStudentListPagination}
