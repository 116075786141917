/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate, useLocation, Location} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth, UserModel} from '../modules/auth'
import {App} from '../App'
import {ForgotPasswordPage} from '../modules/forgot-password/ForgotPasswordPage'
import AttendancePage from '../modules/attendance/AttendancePage'
import { SurveyPage } from '../modules/survey/SurveyPage'
import {Registration} from '../modules/auth/components/Registration'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentUser, isOtpValid} = useAuth()

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          <Route path='attendance/*' element={<AttendancePage />}></Route>
          <Route path='survey/*' element={<SurveyPage />}></Route>
          {authRoute(currentUser, isOtpValid)}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

const authRoute = (currentUser: UserModel | undefined, isOtpValid: boolean): JSX.Element => {
  const pathName = window.location.pathname

  if (currentUser && pathName !== '/auth/forgot-password/reset') {
    return (
      <>
        <Route path='/*' element={<PrivateRoutes />} />
        <Route index element={<Navigate to='/dashboard' />} />
      </>
    )
  }

  return (
    <>
      <Route path='auth/*' element={<AuthPage />} />
      <Route path='/register' element={<Registration />} />
      <Route path='/auth/forgot-password/*' element={<ForgotPasswordPage />} />
      <Route path='*' element={<Navigate to='/auth' />} />
    </>
  )
}

export {AppRoutes}
