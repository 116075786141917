import {FC, useEffect, useState} from 'react'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import LecturerSelect from '../select/LecturerSelect'
import ClassMeetSelect from '../select/ClassMeetSelect'
import {initialQueryState} from '../../../../../../../_metronic/helpers'
import ClassSelect from '../select/ClassSelect'

const SurveyResultFilter: FC = () => {
  const {updateState} = useQueryRequest()
  const [lecturerId, setLecturerId] = useState<number>()
  const [classId, setClassId] = useState<number>()
  const [classMeetId, setClassMeetId] = useState<number>()

  useEffect(() => {
    setClassId(undefined)
    setClassMeetId(undefined)
  }, [lecturerId])

  useEffect(() => {
    setClassMeetId(undefined)
  }, [classId])

  const filter = () => {
    if (lecturerId) {
      updateState({
        ...initialQueryState,
        filter: {
          classMeetId: classMeetId || null,
          classId: classId || null,
          lecturerId: lecturerId,
        },
      })
    }
  }

  return (
    <div className='d-flex align-items-center position-relative my-1 flex-column flex-xl-row w-100 w-xl-auto'>
      <LecturerSelect
        className='w-100 w-xl-250px'
        selected={lecturerId}
        placeholder='Select Lecturer'
        setSelected={setLecturerId}
      ></LecturerSelect>
      <ClassSelect
        className='w-100 w-xl-350px ms-xl-2 ms-0 mt-2 mt-xl-0'
        selected={classId}
        placeholder='Select Class'
        setSelected={setClassId}
        disabled={!lecturerId}
        isClearable={true}
        lecturerId={lecturerId}
      ></ClassSelect>
      <ClassMeetSelect
        className='w-100 w-xl-250px ms-xl-2 ms-0 mt-2 mt-xl-0'
        selected={classMeetId}
        placeholder='Select Meet'
        setSelected={setClassMeetId}
        disabled={!lecturerId || !classId}
        lecturerId={lecturerId}
        isClearable={true}
        classId={classId}
      ></ClassMeetSelect>
      <button
        onClick={filter}
        disabled={!lecturerId}
        type='button'
        className='btn btn-primary w-100 w-xl-auto ms-xl-2 ms-0 mt-2 mt-xl-0'
        style={{height: '43px'}}
      >
        Go
      </button>
    </div>
  )
}

export {SurveyResultFilter}
