import {ClassmeetSemesterListSearch} from './ClassmeetSemesterListSearch'
import {ClassmeetSemesterListToolbar} from './ClassmeetSemesterListToolbar'

const ClassmeetSemesterListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <ClassmeetSemesterListSearch />
      <div className='card-toolbar'>
        <ClassmeetSemesterListToolbar />
      </div>
    </div>
  )
}

export {ClassmeetSemesterListHeader}
