import {useMemo, Fragment, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {KTCardBody} from '../../../../../../_metronic/helpers'
import {StudentExamType} from '../core/_model'
import {ReportLoading} from '../../../components/ReportLoading'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {studentExamColumns} from './columns/_columns'
import {StudentExamListPagination} from '../components/pagination/StudentExamPagination'
import clsx from 'clsx'
import {formatDateTime} from '../../../../../utils/date'
import StudentExamDetailModal from '../components/StudentExamDetailModal'

const StudentExamTable = () => {
  const d = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => d, [d])
  const columns = useMemo(() => studentExamColumns, [])
  const [showModal, setShowModal] = useState(false)
  const [selectedClassId, setSelectedClassId] = useState<number | null>(null)
  const {state} = useQueryRequest()

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data: data?.records ?? [],
  })

  const showModalHandler = (id) => {
    setSelectedClassId(id)
    setShowModal(true)
  }

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_exam'
          className='table table-striped table-rounded dataTable align-middle table-row-dashed no-footer gs-7 fs-6 gy-5'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-center text-muted fw-bolder fs-7 text-uppercase gs-0'>
              <th rowSpan={2} className='text-start align-middle'>
                Name
              </th>
              <th rowSpan={2} className='text-start align-middle'>
                Class
              </th>
              <th rowSpan={2} className='align-middle'>
                Participant
              </th>
              {data?.header?.map((m, i) => (
                <th className='text-center' key={i} colSpan={2}>
                  {m} Scoring
                </th>
              ))}
              <th rowSpan={2} className='align-middle'>
                Action
              </th>
            </tr>
            <tr className='text-center text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {data?.header?.map((m, i) => (
                <Fragment key={i}>
                  <th>Date</th>
                  <th>Scoring(%)</th>
                </Fragment>
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<any>, i) => {
                return (
                  <tr>
                    <td>{row.original.courseName}</td>
                    <td>{row.original.classCode}</td>
                    <td className='text-center'>{row.original.totalStudent}</td>
                    {row.original.scores.map((m, i) => (
                      <Fragment key={i}>
                        <td className='text-center'>
                          {m.isEnabled ? (
                            <>
                              {m.isFinished ? (
                                formatDateTime(m.startDate)
                              ) : m.startDate ? (
                                formatDateTime(m.startDate)
                              ) : (
                                <span className='bg-light-warning py-1 px-2 fs-7'>Not Set</span>
                              )}
                            </>
                          ) : (
                            <span className='bg-light-danger py-1 px-2 fs-7'>N/A</span>
                          )}
                        </td>
                        <td className='text-center'>
                          {m.isEnabled ? (
                            <>
                              {m.isFinished ? (
                                m.percentageGraded + '%'
                              ) : (
                                <span className='bg-light-warning py-1 px-2 fs-7'>Not Yet</span>
                              )}
                            </>
                          ) : (
                            <span className='bg-light-danger py-1 px-2 fs-7'>N/A</span>
                          )}
                        </td>
                      </Fragment>
                    ))}
                    <td className='text-center'>
                      <button
                        type='button'
                        onClick={() => showModalHandler(row.original.classId)}
                        className='btn btn-outline btn-outline-primary btn-active-light-primary btn-sm'
                      >
                        Detail
                      </button>
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={(data?.header?.length ?? 1) * 2 + 4}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    {isLoading ? '' : ' No matching records found'}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* <StudentExamListPagination
        totalPages={data.totalPages}
        currentPage={data?.pageNumber ?? 1}
        onPageChange={() => {}}
      /> */}

      {showModal && (
        <StudentExamDetailModal
          classId={selectedClassId ?? 0}
          semesterId={state.filter?.semesterId}
          show={showModal}
          onClose={() => setShowModal(false)}
        />
      )}
      {isLoading && <ReportLoading />}
    </KTCardBody>
  )
}

export {StudentExamTable}
