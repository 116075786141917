import {FC, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {UpcomingClass} from '../core/_model'
import {formatMonthAndYear, getDate, formatTime, getDateFormatted} from '../../../utils/date'
import {useQuery} from 'react-query'
import {getUpcomingClass} from '../core/_request'
import Skeleton from 'react-loading-skeleton'
import clsx from 'clsx'
import {ImageViewer} from '../../../components/ImagePicker'
import {getLecturerImageUrl} from '../../../utils/img'
import {Link} from 'react-router-dom'

const UpcomingClassCard = () => {
  const {data, isError, isLoading, error} = useQuery(
    ['get-upcoming-class'],
    () => getUpcomingClass(),
    {
      select: (d) => d.data,
    }
  )

  if (isError) {
    // @ts-ignore
    return <p>{error?.message}</p>
  }

  if (isLoading) {
    return (
      <>
        <div className='row'>
          <div className='col-12 col-lg-12 mb-4'>
            <Skeleton count={1} className='upcoming-wrapper' />
          </div>
        </div>
      </>
    )
  }

  if (data?.length === 0) {
    return (
      <div>
        <UpcomingClassNotFound />
      </div>
    )
  }

  return (
    <>
      <Header />
      <div className='card'>
        <div
          className={clsx('card-body pe-4 active-student-wrapper')}
          // style={{
          //   height: isLecturer ? 'calc(100vh - 209px)' : 'calc(50vh - 128px)',
          //   overflowY: 'auto',
          //   overflowX: 'hidden',
          // }}
        >
          {data?.map((v, i) => {
            return <Item data={v} key={i} index={i} isLast={i === data?.length - 1} />
          })}
        </div>
      </div>
    </>
  )
}

const Header: FC = () => {
  return (
    <div className='d-flex justify-content-center flex-column me-3 mb-3'>
      <p className='fs-5 fw-bolder text-gray-700 text-hover-primary me-1 mb-2 mt-1 lh-1'>
        Upcoming Class
      </p>
    </div>
  )
}

const UpcomingClassNotFound = () => {
  return (
    <>
      <Header />
      <div className='card text-center active-student-wrapper'>
        <div className='card-body d-flex flex-column justify-content-center align-items-center'>
          <h5 className='card-title'>
            <KTIcon iconName='calendar' iconType='solid' className='fs-4x' />
          </h5>
          <p className='card-text text-muted'>No Upcoming Class</p>
        </div>
      </div>
    </>
  )
}

type ItemsProps = {
  data: UpcomingClass
  index: number
  isLast: boolean
}

const Item: FC<ItemsProps> = ({data, index, isLast}) => {
  const [show, setShow] = useState(false)
  return (
    <Link to={`/classes/${data.classId}`}>
      <div>
        <div className={clsx('card-upcoming d-flex', {'border-bottom': !isLast})}>
          <div className='p-2 pt-4 d-flex flex-column align-items-center mb-5' style={{width: 60}}>
            <div className='symbol symbol-65px me-2'>
              <i className='fas fa-calendar pt-2' style={{fontSize: 64, color: '#f1f1f1'}}></i>
              <div className='calendar-icon-text'>
                <div>{getDateFormatted(data.startDate, 'ddd')}</div>
                <div>
                  <h2>{getDate(data.startDate)}</h2>
                </div>
              </div>
            </div>
          </div>
          <div className={clsx('flex-grow-1 px-5 py-5')}>
            <h4>{data.name}</h4>
            <div className='text-gray-500 fw-semibold d-flex fs-6 mb-4 align-items-center'>
              <div className='d-flex'>
                <span className='pe-5'>
                  <KTIcon iconName='time' iconType='solid' className='me-1 fs-4' />{' '}
                  {formatTime(data.startDate)} - {formatTime(data.endDate)}
                </span>
                <span className='text-muted fw-bold'>
                  <i className='fas fa-user-friends pe-1'></i> {data.totalActiveStudent}
                </span>
              </div>
            </div>
            <div className='mb-5'>
              <div className='badge badge-primary fs-7 px-4 me-2 mt-2'>
                {data.classMeetTypeName}
              </div>
              {data.roomName && (
                <div className='badge badge-success fs-7 px-4 mt-2'>{data.roomName}</div>
              )}
            </div>

            <div className={`${show ? 'show' : 'collapse'}`}>
              {data.primaryLecturer && (
                <div className='d-flex align-items-center mb-2'>
                  <div data-bs-toggle='tooltip' title={data.primaryLecturer.name}>
                    <div style={{width: 32, borderRadius: '50%', overflow: 'hidden'}}>
                      <ImageViewer
                        initialValue={getLecturerImageUrl(data.primaryLecturer?.id)}
                        width={25}
                        height={25}
                        className='symbol symbol-25px symbol-circle me-2'
                      />
                    </div>
                  </div>
                  <div className='fs-7 text-gray-700' style={{fontWeight: 500}}>
                    {data.primaryLecturer && <span>{data.primaryLecturer?.name}</span>}
                  </div>
                </div>
              )}

              {data.secondaryLecturer && (
                <div className='d-flex align-items-center'>
                  <div
                    className='symbol symbol-25px symbol-circle me-2'
                    data-bs-toggle='tooltip'
                    title={data.secondaryLecturer.name}
                  >
                    <div style={{width: 32, borderRadius: '50%', overflow: 'hidden'}}>
                      <ImageViewer
                        width={25}
                        height={25}
                        initialValue={getLecturerImageUrl(data.secondaryLecturer?.id)}
                        className='symbol-25px'
                      />
                    </div>
                  </div>
                  <div className='fs-7 text-gray-700' style={{fontWeight: 500}}>
                    {data.secondaryLecturer && <span>{data.secondaryLecturer?.name}</span>}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='d-flex align-items-center'>
            <button onClick={() => setShow(!show)} type='button' className='btn btn-light btn-xs'>
              <i className={`fas fa-chevron-${show ? 'up' : 'down'}`}></i>
            </button>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default UpcomingClassCard
