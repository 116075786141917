import {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import BlockLoading from '../../../components/BlockLoading'
import {showError} from '../../../utils/toast'
import {geSurveySummaryDetail} from '../core/_request'
import {ISurveySummaryDetail} from '../../../models/ISurveySummaryChart'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

type Props = {
  show: boolean
  id: number | null
  question: string | null
  lecturerName: string | null
  params: string
  attendance: number | null
  onClose(): void
}

const SurveySummaryDetailModal: FC<Props> = ({
  show,
  id,
  question,
  lecturerName,
  params,
  attendance,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<ISurveySummaryDetail[]>([])

  const handleClose = () => {
    onClose()
  }

  const getData = async (id) => {
    setIsLoading(true)
    try {
      const result = await geSurveySummaryDetail(id, params)
      setIsLoading(false)
      if (result.code === '0000') {
        setData(result.data)
      } else {
        showError(result.message)
      }
    } catch (e: any) {
      showError(e)
    }
  }

  useEffect(() => {
    if (show && id) {
      getData(id)
    } else {
      setData([])
    }
  }, [show, id])

  return (
    <Modal show={show} onHide={handleClose} size='lg' className='modal-notif-survey'>
      <Modal.Header closeButton>
        <Modal.Title>
          <div>{question}</div>
          <div className='text-muted fs-6'>{lecturerName}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading && <BlockLoading />}
        {/* <p>
          Total Attendance : <strong>{attendance}</strong>
        </p> */}
        <div className='table-responsive'>
          <table className='table table-bordered table-rounded '>
            <thead>
              <tr className='text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0'>
                <th className='text-center w-75px'>No</th>
                <th className='min-w-200px'>Category</th>
                <th className='min-w-200px'>Value</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => {
                if (item.id > 0)
                  return (
                    <tr key={index}>
                      <td className='text-center'>{index + 1}</td>
                      <td className='rating-column'>
                        {item.id == 1 && (
                          <>
                            <img src={toAbsoluteUrl('/media/emoticons/star.png')} />
                          </>
                        )}

                        {item.id == 2 && (
                          <>
                            <img src={toAbsoluteUrl('/media/emoticons/star.png')} />
                            <img src={toAbsoluteUrl('/media/emoticons/star.png')} />
                          </>
                        )}
                        {item.id == 3 && (
                          <>
                            <img src={toAbsoluteUrl('/media/emoticons/star.png')} />
                            <img src={toAbsoluteUrl('/media/emoticons/star.png')} />
                            <img src={toAbsoluteUrl('/media/emoticons/star.png')} />
                          </>
                        )}
                        {item.id == 4 && (
                          <>
                            <img src={toAbsoluteUrl('/media/emoticons/star.png')} />
                            <img src={toAbsoluteUrl('/media/emoticons/star.png')} />
                            <img src={toAbsoluteUrl('/media/emoticons/star.png')} />
                            <img src={toAbsoluteUrl('/media/emoticons/star.png')} />
                          </>
                        )}
                        {item.id == 5 && (
                          <>
                            <img src={toAbsoluteUrl('/media/emoticons/star.png')} />
                            <img src={toAbsoluteUrl('/media/emoticons/star.png')} />
                            <img src={toAbsoluteUrl('/media/emoticons/star.png')} />
                            <img src={toAbsoluteUrl('/media/emoticons/star.png')} />
                            <img src={toAbsoluteUrl('/media/emoticons/star.png')} />
                          </>
                        )}
                      </td>
                      <td>{item.count}</td>
                    </tr>
                  )
                else
                  return (
                    <tr key={index}>
                      <td className='text-center  fw-bold fs-7 text-uppercase gs-0' colSpan={2}>
                        Total Attendance
                      </td>
                      <td className='fw-bold '>{item.count}</td>
                    </tr>
                  )
              })}
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default SurveySummaryDetailModal
