// @ts-nocheck
import {Column} from 'react-table'
import {LecturerNotAttendReport} from '../../core/_model'
import {LecturerNotAttendCustomHeader} from './LecturerNotAttendCustomHeader'
import {formatDateTime} from '../../../../../../utils/date'

const lecturerNotAttendColumn: ReadonlyArray<Column<LecturerNotAttendReport>> = [
  {
    Header: (props) => (
      <LecturerNotAttendCustomHeader
        tableProps={props}
        title='Class'
        className='min-w-125px'
        enableSort={true}
      />
    ),
    accessor: 'subjectName',
    id: 'name',
    Cell: ({...props}) =>
      `${props.data[props.row.index].subjectName} (${props.data[props.row.index].classCode})`,
  },
  {
    Header: (props) => (
      <LecturerNotAttendCustomHeader
        tableProps={props}
        title='Class Meet Date'
        className='min-w-125px'
        enableSort={true}
      />
    ),
    accessor: 'classMeetDate',
    id: 'classMeetDate',
    Cell: ({...props}) =>
      formatDateTime(
        props.data[props.row.index].classMeetDate
          ? new Date(props.data[props.row.index].classMeetDate)
          : null
      ),
  },
]

export {lecturerNotAttendColumn}
