import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {classColumns} from './columns/_columns'
import {Class} from '../core/_models'
import {ClassListLoading} from '../components/loading/ClassListLoading'
import {ClassListPagination} from '../components/pagination/ClassListPagination'
import {KTCardBody} from '../../../../../_metronic/helpers'

const ClassTable = () => {
  const _class = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => _class, [_class])
  const columns = useMemo(() => classColumns, [])

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data: data?.records ?? [],
  })

  return (
    <div className='p-0'>
      <div className='table-responsive'>
        <table
          id='kt_table_class'
          className='table table-striped table-rounded dataTable align-middle table-row-dashed no-footer gs-7 fs-6 gy-5'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Class>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Class>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    {isLoading ? '' : ' No matching records found'}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <ClassListPagination
        totalPages={data.totalPages}
        currentPage={data?.pageNumber ?? 1}
        onPageChange={() => console.log('a')}
      />
      {isLoading && <ClassListLoading />}
    </div>
  )
}

export {ClassTable}
