import {useQuery} from 'react-query'
import {useLocation, useParams} from 'react-router-dom'
import {getClassById} from '../class-list/core/_requests'
import ClassFormSession from './components/ClassFormSession'
import ClassFormAssignment from './components/ClassFormAssignment'
import ClassFormQuiz from './components/ClassFormQuiz'
import ClassFormStudent from './components/ClassFormStudent'
import {useEffect, useState} from 'react'
import ClassFormMeet from './components/ClassFormMeet'
import {PageTitle} from '../../../../_metronic/layout/core'
import ClassFormLecturer from './components/ClassFormLecturer'
import ClassFormCodeModal from './components/ClassFormCodeModal'
import BlockLoading from '../../../components/BlockLoading'
import clsx from 'clsx'

function ClassForm() {
  let {id} = useParams()
  const location = useLocation()
  const [showEditModal, setShowEditModal] = useState(false)
  const [selectedTab, setSelectedTab] = useState(0)

  const {isLoading, data, refetch} = useQuery(
    'class-by-id',
    async () => {
      try {
        const response = await getClassById(id)
        return response
      } catch (ex) {
        console.log(ex)
      }
    },
    {enabled: true, refetchOnWindowFocus: false}
  )

  // useEffect(() => {
  //   const params = new URLSearchParams(location.search)
  //   const tab = params.get('tab')
  //   if (tab) {
  //     console.log(tab)
  //   }
  // }, [location.search])

  const subMenuClickHandler = (idx, name) => {
    // window.location.hash = name
    window.history.replaceState({}, '', '#' + name)
    setSelectedTab(idx)
  }

  const navMenus = [
    {
      code: 'lecturers',
      name: 'Lecturers',
    },
    {
      code: 'sessions',
      name: 'Sessions',
    },
    {
      code: 'assignments',
      name: 'Assignments',
    },
    {
      code: 'quizzes',
      name: 'Quizzes',
    },
    {
      code: 'students',
      name: 'Students',
    },
    {
      code: 'meets',
      name: 'Meets',
    },
  ]

  useEffect(() => {
    const handleHashChange = () => {
      const newHash = window.location.hash
      if (newHash) {
        const _hash = newHash.replace('#', '')
        const itemIdx = navMenus.findIndex((f) => f.code === _hash)
        console.log(itemIdx)

        setSelectedTab(itemIdx < 0 ? 0 : itemIdx)
      }
    }

    handleHashChange()
  }, [])

  const onEditCodeClick = () => {
    setShowEditModal(true)
  }

  const handleCodeChange = (reload: boolean) => {
    setShowEditModal(false)
    if (reload) {
      refetch()
    }
  }

  return (
    <>
      {isLoading && <BlockLoading />}
      {data ? (
        <PageTitle
          showBackButton={true}
          description={data?.semesterName ?? '-'}
          actionButton={{icon: 'pencil', onClick: () => onEditCodeClick()}}
        >
          {`${data?.name !== null && data?.name?.trim() !== '' ? data.name : data?.courseName} (${
            data?.code
          })`}
        </PageTitle>
      ) : (
        <PageTitle showBackButton={true} description={'-'}>
          {`-`}
        </PageTitle>
      )}

      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            <div className='card-body pb-3 pt-3'>
              <div className='tab-nav-header'>
                <ul
                  className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-5 fw-semibold mb-n2'
                  role='tablist'
                >
                  {/*<li className='nav-item' role='presentation'>*/}
                  {/*  <a*/}
                  {/*    className='nav-link text-active-danger pb-4 active'*/}
                  {/*    data-bs-toggle='tab'*/}
                  {/*    href='#tab_general'*/}
                  {/*    aria-selected='true'*/}
                  {/*    role='tab'*/}
                  {/*  >*/}
                  {/*    General*/}
                  {/*  </a>*/}
                  {/*</li>*/}

                  {navMenus.map((m, idx) => {
                    return (
                      <li key={idx} className='nav-item' role='presentation'>
                        <a
                          className={clsx(
                            'nav-link text-active-danger pb-4 border-active-danger border-hover-danger',
                            selectedTab === idx && ' active'
                          )}
                          onClick={() => subMenuClickHandler(idx, m.code)}
                          data-bs-toggle='tab'
                          href={`#tab_${m.code}`}
                          aria-selected='false'
                          tabIndex={-1}
                          role='tab'
                        >
                          {m.name}
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-12'>
          <div className='tab-content mt-5' id='myTabContent'>
            {/*<div className='tab-pane fade active show' id='tab_general' role='tabpanel'>*/}
            {/*  <KTCard>{id && <ClassFormGeneral data={data} />}</KTCard>*/}
            {/*</div>*/}
            <div
              className={'tab-pane fade ' + (selectedTab === 0 && 'show active')}
              id='tab_lecturers'
              role='tabpanel'
            >
              {id && <ClassFormLecturer id={+id} data={data} />}
            </div>
            <div
              className={'tab-pane fade' + (selectedTab === 1 && 'show active')}
              id='tab_sessions'
              role='tabpanel'
            >
              {id && <ClassFormSession id={+id} data={data} />}
            </div>
            <div
              className={'tab-pane fade' + (selectedTab === 2 && 'show active')}
              id='tab_assignments'
              role='tabpanel'
            >
              {id && <ClassFormAssignment id={+id} data={data} />}
            </div>
            <div
              className={'tab-pane fade' + (selectedTab === 3 && 'show active')}
              id='tab_quizzes'
              role='tabpanel'
            >
              {id && <ClassFormQuiz id={+id} data={data} />}
            </div>
            <div
              className={'tab-pane fade' + (selectedTab === 4 && 'show active')}
              id='tab_students'
              role='tabpanel'
            >
              {id && <ClassFormStudent id={+id} data={data} />}
            </div>
            {/* <div className='tab-pane fade' id='tab_schedule' role='tabpanel'>
              {id && <ClassFormSchedule id={+id} initialClassSchedules={data?.classSchedules} />}
            </div> */}
            <div
              className={'tab-pane fade' + (selectedTab === 5 && 'show active')}
              id='tab_meets'
              role='tabpanel'
            >
              {id && <ClassFormMeet id={+id} initialClassMeets={data?.classMeets} />}
            </div>
          </div>
        </div>
      </div>
      <div className='modal'>
        <ClassFormCodeModal
          data={data}
          show={showEditModal}
          onClose={(reload) => handleCodeChange(reload)}
        />
      </div>
    </>
  )
}

export default ClassForm
