// @ts-nocheck
import {Column} from 'react-table'
import {LecturerAttendanceType} from '../../core/_model'
import {LecturerAttendanceCustomHeader} from './LecturerAttendanceCustomHeader'

const lecturerAttendanceColumns: ReadonlyArray<Column<LecturerAttendanceType>> = [
  {
    Header: (props) => (
      <LecturerAttendanceCustomHeader
        tableProps={props}
        title='Name'
        className='min-w-125px'
        enableSort={true}
      />
    ),
    accessor: 'name',
    id: 'name',
  },
  {
    Header: (props) => (
      <LecturerAttendanceCustomHeader
        tableProps={props}
        title='Total Lecturer'
        className='min-w-125px'
        enableSort={true}
      />
    ),
    accessor: 'total',
    id: 'total',
  },
]

export {lecturerAttendanceColumns}
