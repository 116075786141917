import {FC} from 'preact/compat'
import {Link, useLocation} from 'react-router-dom'

const StudentReportTab: FC = () => {
  const location = useLocation()

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap'>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center'>
                  <div className='d-flex flex-row justify-content-between mb-5'>
                    <h1 className='page-heading text-dark fw-bold fs-3 my-0'>Student Reports</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-danger text-nowrap me-6 ` +
                  (location.pathname === '/reports/students/student-semester' && 'active')
                }
                to='/reports/students/student-semester'
              >
                Total Student Semester
              </Link>
            </li>

            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-danger text-nowrap me-6 ` +
                  (location.pathname === '/reports/students/graduated-students' && 'active')
                }
                to='/reports/students/graduated-students'
              >
                Graduated Students
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-danger text-nowrap me-6 ` +
                  (location.pathname === '/reports/students/student-exam' && 'active')
                }
                to='/reports/students/student-exam'
              >
                Student Exam
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-danger text-nowrap me-6 ` +
                  (location.pathname === '/reports/students/survey-result' && 'active')
                }
                to='/reports/students/survey-result'
              >
                Survey Result
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-danger text-nowrap me-6 ` +
                  (location.pathname === '/reports/students/student-attendance' && 'active')
                }
                to='/reports/students/student-attendance'
              >
                Student Attendance
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-danger text-nowrap me-6 ` +
                  (location.pathname === '/reports/students/survey-student-info' && 'active')
                }
                to='/reports/students/survey-student-info'
              >
                Survey Student Info
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-danger text-nowrap me-6 ` +
                  (location.pathname === '/reports/students/result-quiz' && 'active')
                }
                to='/reports/students/result-quiz'
              >
                Result Quiz
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-danger text-nowrap me-6 ` +
                  (location.pathname === '/reports/students/result-quiz-by-group' && 'active')
                }
                to='/reports/students/result-quiz-by-group'
              >
                Result Quiz by Group
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-danger text-nowrap me-6 ` +
                  (location.pathname === '/reports/students/student-attendance-summary' && 'active')
                }
                to='/reports/students/student-attendance-summary'
              >
                Student Attendance Summary
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default StudentReportTab
