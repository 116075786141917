import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import StudentReportTab from './components/StudentReportTab'
import {GraduatedStudentListWrapper} from './graduated-student-list/GraduatedStudentList'
import {QuizResultByGroupListWrapper} from './quiz-result-by-group-list/QuizResultByGroupList'
import {ResultQuizListWrapper} from './result-quiz-list/ResultQuizListList'
import {StudentAttendanceListWrapper} from './student-attendance-list/StudentAttendanceList'
import {StudentExamListWrapper} from './student-exam-list/StudentExamList'
import {SurveyResultListWrapper} from './survey-result/SurveyResultList'
import {SurveyStudentInfoWrapper} from './survey-student-info/SurveyStudentInfo'
import {StudentSemesterListWrapper} from './student-semester-list/StudentSemesterList'
import {StudentAttendanceSummaryListWrapper} from './student-attendance-summary/StudentAttendanceSummaryList'

const StudentReportPage = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <StudentReportTab />
            <Outlet />
          </>
        }
      >
        <Route index element={<Navigate to='/reports/students/student-semester' />} />
        <Route path='student-semester' element={<StudentSemesterListWrapper />} />
        <Route path='graduated-students' element={<GraduatedStudentListWrapper />} />
        <Route path='student-exam' element={<StudentExamListWrapper />} />
        <Route path='student-attendance' element={<StudentAttendanceListWrapper />} />
        <Route path='survey-result' element={<SurveyResultListWrapper />} />
        <Route path='survey-student-info' element={<SurveyStudentInfoWrapper />} />
        <Route path='result-quiz' element={<ResultQuizListWrapper />} />
        <Route path='result-quiz-by-group' element={<QuizResultByGroupListWrapper />} />
        <Route
          path='student-attendance-summary'
          element={<StudentAttendanceSummaryListWrapper />}
        />
      </Route>
    </Routes>
  )
}

export default StudentReportPage
