import axios from 'axios'
import {IBaseResponse} from '../../../../auth'
import {GraduatedStudent} from './_model'

const API_URL = process.env.REACT_APP_BASE_API_URL
const URL = `${API_URL}/reports/graduated-student`

const getGraduatedStudent = async (query: string): Promise<IBaseResponse<GraduatedStudent[]>> => {
  const response = await axios.get(`${URL}?${query}`)
  return response.data
}

export {getGraduatedStudent}
