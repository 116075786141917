import {useState} from 'react'
import ReferenceViewScroll from './components/ReferenceViewScroll'
import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {PageTitle} from '../../../../_metronic/layout/core'
import {KTIcon} from '../../../../_metronic/helpers'
import {ReferenceListSearchArchive} from './components/ReferenceListSearchArchive'
import clsx from 'clsx'
import {ReferenceTable} from './table/ReferenceTable'
import {QueryResponseProviderTable} from './core/QueryResponseProviderTable'

const ReferenceListWrapper = () => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [majorId, setMajorId] = useState<number | undefined>()
  const [courseId, setCourseId] = useState<number | undefined>()
  const [listType, setListType] = useState<'list' | 'grid'>('list')

  return (
    <>
      <PageTitle>Reference</PageTitle>
      <div className='card' id='kt_profile_details_view'>
        <div className='card-body pt-3 pb-3'>
          <div className='d-flex flex-xxl-row-reverse flex-column justify-content-xxl-between'>
            <div className='row justify-content-xl-center gy-5 gx-2'>
              <div className='d-flex align-items-center position-relative col-xl-4 w-xxl-700px '>
                <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                <input
                  type='text'
                  id='kt_filter_search'
                  className='form-control form-control-white w-100 form-control-xl fs-6 fw-normal ps-9'
                  placeholder='Search'
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            <div className='btn-group border border-warning-subtle border-1 mt-xxl-0 mt-4'>
              <button
                type='button'
                className={clsx('btn btn-active-light-warning btn-text-primary', {
                  'btn-light-warning': listType === 'list',
                })}
                onClick={() => setListType('list')}
              >
                <KTIcon iconName='abstract-14' className='p-0 mx-8 text-dark'></KTIcon>
              </button>
              <button
                type='button'
                className={clsx('btn btn-active-light-warning', {
                  'btn-light-warning': listType === 'grid',
                })}
                onClick={() => setListType('grid')}
              >
                <KTIcon iconName='element-11' className='p-0 mx-8 text-dark'></KTIcon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12 mb-5 mt-5'>
          <QueryRequestProvider isTable={listType === 'list'}>
            {listType === 'grid' && (
              <QueryResponseProvider>
                <ListViewProvider>
                  <ReferenceListSearchArchive
                    searchTerm={searchTerm}
                    courseId={courseId}
                    majorId={majorId}
                  />
                  <ReferenceViewScroll />
                </ListViewProvider>
              </QueryResponseProvider>
            )}
            {listType === 'list' && (
              <QueryResponseProviderTable>
                <ListViewProvider>
                  <ReferenceListSearchArchive
                    searchTerm={searchTerm}
                    courseId={courseId}
                    majorId={majorId}
                  />
                  <div className='card'>
                    <ReferenceTable />
                  </div>
                </ListViewProvider>
              </QueryResponseProviderTable>
            )}
          </QueryRequestProvider>
        </div>
      </div>
    </>
  )
}

export {ReferenceListWrapper}
