import {FC, useState} from 'react'
import {downloadFile} from '../utils/file'
import {showError} from '../utils/toast'

type Props = {
  path: string
  fileName: string
  children: any
  className?: string
}

const DownloadButton: FC<Props> = ({path, fileName, children, className}) => {
  const [isLoading, setIsLoading] = useState(false)

  const downloadHandler = async () => {
    setIsLoading(true)
    try {
      await downloadFile(path, fileName)
    } catch (e) {
      showError('File cannot be downloaded')
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div style={{position: 'relative'}}>
      {isLoading && (
        <div
          className={`d-flex align-items-center justify-content-center w-100 h-100`}
          style={{position: 'absolute'}}
        >
          <i className='fas fa-spinner fa-spin'></i>
        </div>
      )}
      <button
        type='button'
        disabled={isLoading}
        style={{opacity: isLoading ? 0.4 : 1}}
        className={`${className} btn btn-default`}
        onClick={downloadHandler}
      >
        {children}
      </button>
    </div>
  )
}

export {DownloadButton}
