import {FC, useEffect, useRef, useState} from 'react'
import {KTIcon, stringifyRequestQuery} from '../../../../_metronic/helpers'
import {getAttendances, getAverageGPA, getLecturerTodayMeets} from '../core/_request'
import Skeleton from 'react-loading-skeleton'
import clsx from 'clsx'
import {getCSS, getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import ApexCharts, {ApexOptions} from 'apexcharts'
import ReactApexChart from 'react-apexcharts'
import {showError} from '../../../utils/toast'
import MajorSelect from '../../majors/components/MajorSelect'
import BlockLoading from '../../../components/BlockLoading'
import {ISemester} from '../../../models/ISemester'
import SemesterSelect from '../../classes/components/SemesterSelect'
import AverageGpaDetailModal from './AverageGpaDetailModal'
import {splitString} from '../../../utils/string'
import {downloadFile} from '../../../utils/file'
import {Dropdown} from 'react-bootstrap'
import LoadingButtonWrapper from '../../../components/LoadingButtonWrapper'

const noDataOpt: any = {
  text: 'Please Choose Major First',
  align: 'center',
  verticalAlign: 'middle',
  offsetX: 0,
  offsetY: 0,
  style: {
    color: '#99a1b7',
    fontSize: '14px',
    fontFamily: 'Helvetica',
  },
}

const AverageGPAChartCard = () => {
  const [chartOptions, setChartOptions] = useState<ApexOptions>(getChartOptions(300))
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [series, setSeries] = useState<any[]>([])
  const [majorId, setMajorId] = useState<number | null>(null)
  const [semester, setSemester] = useState<ISemester | undefined>(undefined)
  const [chartWidth, setChartWidth] = useState<number | undefined>(undefined)
  const seriesRef = useRef<any>([])
  const [showModal, setshowModal] = useState(false)
  const [selectedId, setSelectedId] = useState<number | null>(null)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const barSize = 200
  const url = 'dashboards/average-gpa-excel'
  const [loadingExport, setLoadingExport] = useState(false)
  const [loadingExportRaw, setLoadingExportRaw] = useState(false)

  const setNoDataLabel = (text) => {
    checkChartWidth(1)
    setChartOptions((prev) => ({
      ...prev,
      noData: {...noDataOpt, text},
      xaxis: {
        ...prev.xaxis,
        labels: {
          show: false,
        },
      },
    }))
  }

  const getData = async () => {
    try {
      const params = stringifyRequestQuery({
        filter: {
          majorId,
          semesterId: semester ? semester.id : null,
        },
      })

      setIsLoading(true)

      const result = await getAverageGPA(params)
      if (result.code === '0000') {
        seriesRef.current = result.data
        setSeriesData(result.data)
        setIsLoading(false)
      } else {
        setNoDataLabel('Error: ' + result.message)
        setIsLoading(false)
        // showError(result.message)
      }
    } catch (ex: any) {
      // showError(ex.message)
      setNoDataLabel('Error: ' + ex.message)
      setIsLoading(false)
    }
  }

  const setSeriesData = (items: any[]) => {
    const data = items?.map((m) => m.gpa)
    const categories = items?.map((m) => m.name)

    checkChartWidth(categories?.length ?? 1)
    setSeries([
      {
        name: 'GPA',
        data: data.length > 0 ? data : [0],
      },
    ])

    const opt = {
      ...chartOptions,
      xaxis: {
        ...chartOptions.xaxis,
        categories: categories.length > 0 ? categories : ['-'],
        labels: {
          show: categories.length > 0,
        },
      },
    }

    setChartOptions(opt)
    if (categories.length === 0) {
      setNoDataLabel('No Data Displayed')
    }
  }

  useEffect(() => {
    if (majorId) {
      getData()
    }
  }, [majorId, semester])

  const Header: FC = () => {
    return (
      <div className='d-flex justify-content-center flex-column me-3 mb-3'>
        <p className='fs-5 fw-bolder text-gray-700 text-hover-primary me-1 mb-2 mt-4 lh-1'>
          Highest GPA
        </p>
      </div>
    )
  }

  const majorChangeHandler = (e) => {
    setMajorId(e.value)
    setSemester(undefined)
  }

  const semesterChangeHandler = (e) => {
    setSemester(e)
  }

  const closeModalHandler = () => {
    setshowModal(false)
    setSelectedId(null)
  }

  const checkChartWidth = (length?: number) => {
    const maxCategory = window.innerWidth <= 720 ? 2 : 5
    const categoriesCount = length ?? chartOptions.xaxis?.categories?.length ?? 1
    if (categoriesCount > maxCategory) {
      setChartWidth(categoriesCount * barSize)
    } else {
      setChartWidth(undefined)
    }
  }

  useEffect(() => {
    setChartOptions((prev) => {
      let newItem = {...prev}
      if (newItem.chart != null) {
        newItem.chart.events = {
          dataPointSelection: (event, chartContext, config) => {
            const selectedDataPointIndex = config.dataPointIndex
            setshowModal(true)
            setSelectedId(seriesRef.current[selectedDataPointIndex].id)
          },
        }
      }

      return newItem
    })

    checkChartWidth()

    function handleResize() {
      checkChartWidth()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen)
  }

  const dropdownRef = useRef<any>(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const excel = async () => {
    try {
      setIsLoading(true)
      setLoadingExport(true)
      const params = stringifyRequestQuery({
        filter: {
          majorId,
        },
      })
      await downloadFile(`${url}/summary?${params}`, 'HighestGPASummary.xlsx')
    } catch (err: any) {
      showError(err.message)
    } finally {
      setIsLoading(false)
      setLoadingExport(false)
    }
  }

  const excelRaw = async () => {
    try {
      setIsLoading(true)
      setLoadingExportRaw(true)
      const params = stringifyRequestQuery({
        filter: {
          majorId,
        },
      })
      await downloadFile(`${url}/raw?${params}`, 'HighestGPARaw.xlsx')
    } catch (err: any) {
      showError(err.message)
    } finally {
      setIsLoading(false)
      setLoadingExportRaw(false)
    }
  }

  return (
    <>
      <Header />
      <div className='card'>
        <div className={clsx('card-body pe-4')}>
          <div className='dashboard-filter-container d-flex justify-content-between w-100'>
            <MajorSelect
              isUseFormik={false}
              className='w-250px pe-4'
              onChange={majorChangeHandler}
              selectedValue={majorId}
            />

            <div className='d-flex flex-column flex-md-row'>
              <div className='d-flex flex-column flex-md-row'>
                <LoadingButtonWrapper isLoading={loadingExport}>
                <button
                  type='button'
                  className='btn btn-light-success d-none d-md-flex mb-3 mb-md-0 me-0 me-md-3'
                  onClick={() => excel()}
                >
                  <KTIcon iconName='exit-down' className='fs-2' />
                  Export
                </button>
                </LoadingButtonWrapper>
                <LoadingButtonWrapper isLoading={loadingExportRaw}>
                <button
                  type='button'
                  className='btn btn-light-success d-none d-md-flex mb-3 mb-md-0'
                  onClick={() => excelRaw()}
                >
                  <KTIcon iconName='exit-down' className='fs-2' />
                  Export Raw
                </button>
                </LoadingButtonWrapper>
                <Dropdown
                  className='me-3 d-md-none d-sm-block w-100'
                  show={dropdownOpen}
                  onToggle={toggleDropdown}
                  autoClose={false}
                  ref={dropdownRef}
                >
                  <Dropdown.Toggle
                    id='dropdown-custom-components'
                    className='w-100'
                    variant='success'
                  >
                    <KTIcon iconName='exit-down' className='fs-2' />
                    <span className='pe-3'>Export</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='p-8' style={{width: 300}} align='end'>
                    <Dropdown.Item onClick={() => excel()}>Export</Dropdown.Item>
                    <Dropdown.Item onClick={() => excelRaw()}>Export Raw</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          {isLoading && <BlockLoading />}
          <div style={{overflow: 'auto'}}>
            <ReactApexChart
              options={chartOptions}
              series={series}
              type='bar'
              height={300}
              width={chartWidth}
            />
          </div>
        </div>
      </div>
      <AverageGpaDetailModal id={selectedId} show={showModal} onClose={closeModalHandler} />
    </>
  )
}

export default AverageGPAChartCard

function getChartOptions(height: number): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-primary')
  const secondaryColor = getCSSVariableValue('--bs-gray-300')

  return {
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: 200,
      toolbar: {
        show: true,
        tools: {
          zoom: true,
          download: false,
        },
      },
      zoom: {
        enabled: true,
        type: 'x',
        autoScaleYaxis: false,
        zoomedArea: {
          fill: {
            color: '#90CAF9',
            opacity: 0.4,
          },
          stroke: {
            color: '#0D47A1',
            opacity: 0.4,
            width: 1,
          },
        },
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
        horizontal: false,
        borderRadius: 5,
        dataLabels: {
          position: 'top',
        },
      },
    },

    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#4b5675'],
      },
      offsetY: -20,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    noData: noDataOpt,
    xaxis: {
      categories: [],
      labels: {
        rotate: 0,
        show: true,
        formatter: (val) => {
          return splitString(val, 25)
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        formatter: function (val, opt) {
          const ticks = [0, 1, 2, 3, 4]
          if (ticks.includes(val)) {
            return val + ''
          }
          return '-'
        },
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      max: 5,
      min: 0,
      tickAmount: 5,
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val + ''
        },
      },
    },
    colors: [
      '#1f77b4', // blue
      '#ff7f0e', // orange
      '#2ca02c', // green
      '#d62728', // red
      '#9467bd', // purple
      '#8c564b', // brown
      '#e377c2', // pink
      '#7f7f7f', // gray
      '#bcbd22', // yellow
      '#17becf', // cyan
    ],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          height: 800,
          plotOptions: {
            bar: {
              // horizontal: true,
            },
          },
        },
      },
    ],
  }
}
