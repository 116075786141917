import {useEffect, useRef, useState} from 'react'
import {ID, toAbsoluteUrl} from '../../_metronic/helpers'

const API_URL = process.env.REACT_APP_BASE_API_URL

const AvatarImage = ({avatar, className, loading, onAvatarChange, showEdit}: any) => {
  const [imgSrc, setImgSrc] = useState(toAbsoluteUrl('/media/svg/avatars/blank.svg'))
  const fileInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (avatar) {
      setImgSrc(`${API_URL}/avatar/${avatar}`)
    }
  }, [avatar])

  const clickEditHandler = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  return (
    <div className={`symbol ${className}`}>
      {loading && (
        <div
          className='position-absolute d-flex align-items-center justify-content-center w-100 h-100'
          style={{backgroundColor: 'rgba(255, 255, 255, 0.7)', borderRadius: '50%'}}
        >
          <div className='spinner-border' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      )}
      <img src={imgSrc} className='rounded-circle' style={{objectFit: 'cover'}} alt='avatar' />
      {showEdit && (
        <button
          type='button'
          className='btn btn-circle btn-primary p-0 d-flex align-items-center justify-content-center text-center position-absolute'
          style={{
            border: '3px solid #fff',
            width: '36px',
            height: '36px',
            right: 0,
            bottom: 4,
            borderRadius: '50%',
          }}
          onClick={clickEditHandler}
        >
          <i className='bi bi-pencil-square p-0'></i>
        </button>
      )}
      <input
        type='file'
        ref={fileInputRef}
        onChange={onAvatarChange}
        style={{display: 'none'}}
        accept='image/jpeg, image/png, application/pdf'
      />
    </div>
  )
}

const getStudentImageUrl = (id: ID) => {
  if (!id) return null

  return `${API_URL}/students/image/${id}`
}

const getStudentImageThumbnailUrl = (id: ID) => {
  if (!id) return null

  return `${API_URL}/students/image/thumbnail/${id}`
}

const getUserImageUrl = (id: ID) => {
  if (!id) return null

  return `${API_URL}/users/image/${id}`
}

const getUserImageThumbnailUrl = (id: ID) => {
  if (!id) return null

  return `${API_URL}/users/image/thumbnail/${id}`
}

const getLecturerImageUrl = (id: ID) => {
  if (!id) return null

  return `${API_URL}/lecturers/image/${id}`
}

const getLecturerImageThumbnailUrl = (id: ID) => {
  if (!id) return null

  return `${API_URL}/lecturers/image/thumbnail/${id}`
}

const getAnnouncementImageUrl = (uniqueId: string) => {
  if (!uniqueId) return null

  return `${API_URL}/announcements/file/cover/${uniqueId}`
}

const getClassAnnouncementImageUrl = (uniqueId: string) => {
  if (!uniqueId) return null

  return `${API_URL}/class-announcements/file/cover/${uniqueId}`
}

const getStaffImageUrl = (id: ID) => {
  if (!id) return null

  return `${API_URL}/staffs/image/${id}`
}

const getStaffImageThumbnailUrl = (id: ID) => {
  if (!id) return null

  return `${API_URL}/staffs/image/thumbnail/${id}`
}

export {
  AvatarImage,
  getStudentImageUrl,
  getStudentImageThumbnailUrl,
  getLecturerImageUrl,
  getLecturerImageThumbnailUrl,
  getUserImageUrl,
  getUserImageThumbnailUrl,
  getAnnouncementImageUrl,
  getClassAnnouncementImageUrl,
  getStaffImageUrl,
  getStaffImageThumbnailUrl,
}
