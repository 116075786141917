import clsx from 'clsx'
import {FC, Fragment, useContext, useEffect, useRef, useState} from 'react'
import {
  Accordion,
  AccordionContext,
  Card,
  Dropdown,
  Modal,
  useAccordionButton,
} from 'react-bootstrap'
import {useMutation} from 'react-query'
import {useLocation} from 'react-router-dom'
import {PaginationState, stringifyRequestQuery} from '../../../../../_metronic/helpers'
import {Confirm} from '../../../../components/Confirm'
import AppPagination from '../../../../components/Pagination'
import SafeHTMLRenderer from '../../../../components/SafeHtmlRenderer'
import {showError, showSuccess} from '../../../../utils/toast'
import {MenuModel, getMenuAccess, useAuth} from '../../../auth'
import {ClassListLoading} from '../../class-list/components/loading/ClassListLoading'
import {Class} from '../../class-list/core/_models'
import {getByQuizId, getClass} from '../../class-list/core/_requests'
import {CourseQuiz, CourseQuizQuestion} from '../../course-list/core/_models'
import {
  deleteCourseQuizQuestion,
  getCourseQuizQuestion,
  recalculateQuiz,
} from '../../course-list/core/_requests'
import {CourseFormQuizQuestionModal} from './CourseFormQuizQuestionModal'
import ClassQuizAnswerModalForm from '../../../classes/class-detail/components/ClassQuizAnswerModalForm'
import LoadingButtonWrapper from '../../../../components/LoadingButtonWrapper'

type Prop = {
  quiz: CourseQuiz
  onEditQuiz: any
}

const CourseFormQuizDetail: FC<Prop> = ({quiz, onEditQuiz}) => {
  const [questions, setQuestions] = useState<CourseQuizQuestion[]>([])
  const [showModal, setShowModal] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [sortDir, setSortDir] = useState<'asc' | 'desc' | undefined>()
  const [sortName, setSortName] = useState<string>()
  const [pagination, setPagination] = useState<PaginationState>({
    totalRecordsFiltered: 0,
    pageNumber: 1,
    totalPages: 0,
    pageSize: 10,
  })
  const [classToRecalculate, setClassToRecalculate] = useState<number[]>([])
  const [classList, setClassList] = useState<Class[]>([])
  const [showRegenerateModal, setShowRegenerateModal] = useState(false)
  const allCheckboxRef = useRef<any>(null)
  const {pathname} = useLocation()
  const {currentMenu} = useAuth()
  const [menuAccess, setMenuAccess] = useState<MenuModel | undefined>(undefined)

  useEffect(() => {
    setMenuAccess(getMenuAccess(currentMenu ?? [], pathname))
  }, [currentMenu])

  const closeHandler = () => {
    setShowModal(false)
    setSelectedIndex(null)
    fetchData(pagination.pageNumber, pagination.pageSize)
  }

  const openModalHandler = () => {
    setSelectedIndex(null)
    setShowModal(true)
  }

  const fetchData = async (pageNumber, pageSize) => {
    const filter = stringifyRequestQuery({
      pageNumber: pageNumber,
      pageSize: pageSize,
      // keyword: debouncedSearchTerm,
      // sortName: sortName,
      // sortDir: sortDir,
      filter: {
        quizId: quiz?.id,
      },
    })
    setIsLoading(true)
    const response = await getCourseQuizQuestion(filter)
    setIsLoading(false)
    if (response.code === '0000') {
      setPagination({
        pageNumber: response.data?.pageNumber ?? 0,
        // pageSize: response.data?.pageSize ?? 0,
        pageSize: pageSize,
        totalRecordsFiltered: response.data?.totalRecordsFiltered ?? 0,
        totalPages: response.data?.totalPages ?? 0,
      })
      setQuestions(response.data?.records ?? [])
    }
  }

  useEffect(() => {
    fetchData(1, pagination.pageSize)
  }, [quiz])

  const editHandler = (index: number) => {
    setShowModal(true)
    setSelectedIndex(index)
  }

  const [showDeleteQuestion, setShowDeleteQuestion] = useState(false)
  const [questionSelectedId, setQuestionSelectedId] = useState<number | null>(null)

  const handleCloseDelete = () => setShowDeleteQuestion(false)

  const handleDeleteAction = async () => {
    try {
      await deleteQuestion.mutateAsync()
    } catch (err: any) {
      console.log(err)
    }
  }

  const deleteQuestion = useMutation(() => deleteCourseQuizQuestion(questionSelectedId), {
    onSuccess: () => {
      setQuestionSelectedId(null)
      handleCloseDelete()
      fetchData(pagination.pageNumber, pagination.pageSize)
    },
    onError: (err: any) => {},
  })

  const handleOpenConfirmation = (id) => {
    setQuestionSelectedId(id)
    setShowDeleteQuestion(true)
  }

  function CustomToggle({children, eventKey, callback}) {
    const {activeEventKey} = useContext(AccordionContext)

    const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey))

    const isCurrentEventKey = activeEventKey === eventKey

    return (
      <button type='button' className='btn btn-default' onClick={decoratedOnClick}>
        {isCurrentEventKey ? (
          <i className='fa-solid fa-chevron-up'></i>
        ) : (
          <i className='fa-solid fa-chevron-down'></i>
        )}
        {children}
      </button>
    )
  }

  const pageChangeHandler = (state) => {
    setPagination((prev) => ({...prev, pageNumber: state.pageNumber, pageSize: state.pageSize}))
    fetchData(state.pageNumber, state.pageSize)
  }

  const [showAnswer, setShowAnswer] = useState(false)

  const recalculate = () => {
    setIsLoading(true)
    getByQuizId(quiz.id)
      .then((resp: any) => {
        if (resp.code === '0000' && resp.data.length > 0) {
          setClassList(resp.data!)
          setShowRegenerateModal(true)
        } else if (resp.data && resp.data.length === 0) {
          showError('There is no class for regenerate')
        }
      })
      .finally(() => setIsLoading(false))
  }

  const onAllCheckChange = (target: EventTarget & HTMLInputElement) => {
    if (target.checked) {
      const allIds = classList.map((q) => q.id || 0).filter((q) => q)
      setClassToRecalculate(allIds)
    } else {
      setClassToRecalculate([])
    }
  }

  const handleCheckClass = (item: Class, checked: boolean) => {
    if (checked) {
      setClassToRecalculate((prev) => {
        const newInstance = [...prev]
        if (!newInstance.includes(item.id || 0)) {
          newInstance.push(item.id || 0)
        }

        return newInstance
      })
    } else {
      setClassToRecalculate((prev) => {
        const newInstance = [...prev]
        const index = newInstance.indexOf(item.id || 0)
        if (index >= 0) {
          newInstance.splice(index, 1)
        }

        return newInstance
      })
    }
  }

  useEffect(() => {
    checkIndeterminate()
  }, [classToRecalculate])

  const checkIndeterminate = () => {
    if (allCheckboxRef.current) {
      if (classToRecalculate.length === 0 || classToRecalculate.length === classList.length) {
        allCheckboxRef.current.indeterminate = false
        if (classToRecalculate.length === 0) {
          allCheckboxRef.current.checked = false
        } else {
          allCheckboxRef.current.checked = true
        }
      } else {
        allCheckboxRef.current.checked = false
        allCheckboxRef.current.indeterminate = true
      }
    }
  }

  const handleRecalculate = () => {
    setIsLoading(true)
    recalculateQuiz(quiz.id, classToRecalculate)
      .then((resp) => {
        if (resp.code === '0000') {
          showSuccess('Recalculate complete')
        } else if (resp.message) {
          showError(resp.message)
        } else {
          showError('Recalculate failed')
        }
      })
      .finally(() => {
        setIsLoading(false)
        setShowRegenerateModal(false)
      })
  }

  return (
    <>
      {selectedIndex}
      <Accordion defaultActiveKey={undefined} className='mb-8'>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='d-flex justify-content-between align-items-center'>
            <h3 className='m-0'>{quiz?.name}</h3>
            <button type='button' className='btn btn-default' onClick={() => onEditQuiz(quiz?.id)}>
              <i className='fas fa-edit'></i>
            </button>
            {menuAccess?.canCreate && (
              <button type='button' className='btn btn-warning' onClick={recalculate}>
                Recalculate
              </button>
            )}
          </div>
          <CustomToggle eventKey='0' callback={null}>
            &nbsp;
          </CustomToggle>
        </div>
        <Accordion.Collapse eventKey='0'>
          <Card.Body>
            <SafeHTMLRenderer htmlString={quiz?.description} />
          </Card.Body>
        </Accordion.Collapse>
      </Accordion>

      <CourseFormQuizQuestionModal
        quizId={quiz?.id ?? 0}
        show={showModal}
        question={selectedIndex !== null ? questions[selectedIndex] : null}
        totalRecord={
          selectedIndex !== null
            ? ((pagination.pageNumber ?? 0) - 1) * (pagination.pageSize ?? 10) + selectedIndex 
            : (pagination.totalRecordsFiltered ?? 0) 
        }
        onClose={closeHandler}
      />

      <div className='d-flex justify-content-between align-items-center mb-8'>
        <div>
          <h4 className='text-gray-600 mb-6'>Questions</h4>
          <div className='d-flex'>
            <div className='form-check form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                type='checkbox'
                data-kt-check={showAnswer}
                data-kt-check-target='#kt_table_academiccalendar .form-check-input'
                checked={showAnswer}
                onChange={(e) => setShowAnswer((prev) => !prev)}
              />
            </div>
            <span className='ps-3 text-gray-600 fw-bold'>Show Answer</span>
          </div>
        </div>
        <div>
          {menuAccess?.canCreate && (
            <button type='button' className='btn btn-warning' onClick={openModalHandler}>
              Add Question
            </button>
          )}
        </div>
      </div>
      <div className='table-responsive'>
        <table className='table table-striped align-middle fs-6 gy-5 dataTable no-footer'>
          <thead>
            <tr>
              <th
                style={{minWidth: 70}}
                className='text-center text-muted fw-bolder fs-7 text-uppercase gs-0'
              >
                No
              </th>
              <th
                style={{minWidth: 120, width: '100%'}}
                className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'
              >
                Question
              </th>
              <th
                style={{minWidth: 120}}
                className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'
              >
                Type
              </th>
              <th
                style={{minWidth: 80}}
                className='text-center text-muted fw-bolder fs-7 text-uppercase gs-0'
              >
                Score
              </th>
              <th
                style={{minWidth: 120}}
                className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'
              >
                Actions
              </th>
            </tr>
          </thead>

          {questions?.length > 0 ? (
            <tbody className='text-gray-600 fw-bold'>
              {questions?.map((m, index) => (
                <Fragment key={index}>
                  <tr>
                    <td className='align-top text-center'>
                      {((pagination.pageNumber ?? 0) - 1) * (pagination.pageSize ?? 0) +
                        (index + 1)}
                    </td>
                    <td>
                      <SafeHTMLRenderer htmlString={m.questionText} />
                    </td>
                    <td>{m.typeName}</td>
                    <td className='text-center'>{m.correctScore}</td>
                    <td className='text-center'>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant='light'
                          id='dropdown-basic'
                          className='btn btn-outline btn-outline-primary btn-active-light-primary btn-sm'
                        >
                          Actions
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                          popperConfig={{
                            strategy: 'fixed',
                            onFirstUpdate: () => window.dispatchEvent(new CustomEvent('scroll')),
                          }}
                          className='menu-gray-600 menu-state-bg-light-warning fw-bold fs-7 w-125px py-4'
                        >
                          <Dropdown.Item onClick={() => editHandler(index)}>
                            {menuAccess?.canCreate ? 'Edit' : 'View'}
                          </Dropdown.Item>
                          {menuAccess?.canCreate && (
                            <Dropdown.Item onClick={() => handleOpenConfirmation(m.id)}>
                              Delete
                            </Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                  {showAnswer && (
                    <tr>
                      <td></td>
                      <td colSpan={4}>
                        <span className='d-block'>
                          <h5 className='text-gray-600'>Answer</h5>
                          {m.typeId === 3 && <p>{m.correctAnswerNumber}</p>}
                          {m.typeId === 2 && (
                            <p>
                              <SafeHTMLRenderer htmlString={m.correctAnswerEssay} />
                            </p>
                          )}
                          {m.typeId === 1 && (
                            <ol>
                              {m.option1 && (
                                <li className={clsx(m.correctAnswer === 1 && 'text-success')}>
                                  <SafeHTMLRenderer htmlString={m.option1} />
                                </li>
                              )}
                              {m.option2 && (
                                <li className={clsx(m.correctAnswer === 2 && 'text-success')}>
                                  <SafeHTMLRenderer htmlString={m.option2} />
                                </li>
                              )}
                              {m.option3 && (
                                <li className={clsx(m.correctAnswer === 3 && 'text-success')}>
                                  <SafeHTMLRenderer htmlString={m.option3} />
                                </li>
                              )}
                              {m.option4 && (
                                <li className={clsx(m.correctAnswer === 4 && 'text-success')}>
                                  <SafeHTMLRenderer htmlString={m.option4} />
                                </li>
                              )}
                              {m.option5 && (
                                <li className={clsx(m.correctAnswer === 5 && 'text-success')}>
                                  <SafeHTMLRenderer htmlString={m.option5} />
                                </li>
                              )}

                              {m.option6 && (
                                <li className={clsx(m.correctAnswer === 6 && 'text-success')}>
                                  <SafeHTMLRenderer htmlString={m.option6} />
                                </li>
                              )}
                            </ol>
                          )}
                        </span>
                      </td>
                    </tr>
                  )}
                </Fragment>
              ))}
            </tbody>
          ) : (
            <tbody className='text-gray-600 fw-bold'>
              <tr>
                <td colSpan={5}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    {isLoading ? '' : ' No matching records found'}
                  </div>
                </td>
              </tr>
            </tbody>
          )}
        </table>
        <Confirm
          name={null}
          show={showDeleteQuestion}
          handleClose={handleCloseDelete}
          handleConfirm={handleDeleteAction}
        />
      </div>
      <Pagination
        pagination={pagination}
        totalPages={pagination.totalPages}
        currentPage={pagination?.pageNumber ?? 1}
        onPageChange={pageChangeHandler}
      />

      <Modal show={showRegenerateModal} onHide={() => setShowRegenerateModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Recalculate Quiz</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mh-500px overflow-y-auto'>
            Please select class to recalculate
            <div className='table-responsive'>
              <table className='table table-striped gy-7 gs-7'>
                <thead>
                  <tr className='fw-bolder fs-6 text-gray-800'>
                    <th>
                      <div className='form-check form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          ref={allCheckboxRef}
                          onChange={(e) => onAllCheckChange(e.target)}
                        />
                      </div>
                    </th>
                    <th>Class Code</th>
                    <th>Class Name</th>
                  </tr>
                </thead>
                <tbody>
                  {classList.map((item, i) => (
                    <tr key={i}>
                      <td>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          checked={classToRecalculate.includes(item.id || 0)}
                          onChange={(e) => handleCheckClass(item, e.target.checked)}
                        />
                      </td>
                      <td>{item.code}</td>
                      <td>{item.name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className='text-end pt-15'>
            <button
              type='reset'
              onClick={() => setShowRegenerateModal(false)}
              className='btn btn-light me-3'
              data-kt-students-modal-action='cancel'
              disabled={isLoading}
            >
              Discard
            </button>

            <LoadingButtonWrapper isLoading={isLoading}>
              <button
                type='button'
                className='btn btn-warning ms-2'
                onClick={handleRecalculate}
                disabled={isLoading}
              >
                <span className='indicator-label'>Recalculate</span>
              </button>
            </LoadingButtonWrapper>
          </div>
          {isLoading && <ClassListLoading />}
        </Modal.Body>
      </Modal>
    </>
  )
}

export {CourseFormQuizDetail}

const Pagination = ({pagination, totalPages, currentPage, onPageChange}) => {
  const updatePage = (page: number | undefined | null) => {
    if (!page || pagination.pageNumber === page) {
      return
    }
    const newState = {pageNumber: page, page, pageSize: pagination.pageSize || 10}
    onPageChange(newState)
  }

  const startRow =
    pagination.totalRecordsFiltered === 0 ? 0 : (currentPage - 1) * (pagination.pageSize ?? 10) + 1
  const endRow =
    pagination.totalRecordsFiltered === 0 ? 0 : currentPage * (pagination.pageSize ?? 10)

  const setPageSize = (pageSize) => {
    const newState = {pageNumber: 1, pageSize: pageSize}
    onPageChange(newState)
  }

  return (
    <div className='row pagination-row'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
        <span className='fw-bold text-gray-600'>
          {startRow === 0
            ? 'No records'
            : `Showing ${startRow}-${
                endRow > (pagination.totalRecordsFiltered ?? 0)
                  ? pagination.totalRecordsFiltered
                  : endRow
              } of ${pagination.totalRecordsFiltered} records`}
        </span>
        <span className='ps-2' style={{width: 85}}>
          <select
            className='form-select form-select-solid fw-bolder'
            data-kt-select2='true'
            data-placeholder='Select page total'
            data-allow-clear='true'
            data-kt-lecturer-table-filter='degree'
            data-hide-search='true'
            onChange={(e) => setPageSize(+e.target.value)}
            value={pagination.pageSize}
          >
            <option value='10'>10</option>
            <option value='30'>30</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
          </select>
        </span>
      </div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_student_paginate'>
          <AppPagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={updatePage}
          />
        </div>
      </div>
    </div>
  )
}
