import React, {FC, useEffect} from 'react'
import {useInfiniteQuery} from 'react-query'
import {getReferences} from '../core/_request'
import {ReferenceType} from '../core/_model'
import {ReferenceCard} from './ReferenceCard'
import {ReferenceCardLoading} from './ReferenceCardLoading'
import {useQueryResponse} from '../core/QueryResponseProvider'
import IconLoader from './icon-loader/IconLoader'
import ReferenceNotFound from './ReferenceNotFound'
import {QUERIES, stringifyMaterialRequestQuery} from '../../../../../_metronic/helpers'

const ReferenceViewScroll: FC = () => {
  const handleScroll = () => {
    const windowHeight = window.innerHeight
    const documentHeight = document.documentElement.scrollHeight
    const scrollPosition = window.scrollY

    if (windowHeight + scrollPosition >= documentHeight) {
      fetchNextPage()
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  const {query} = useQueryResponse()
  const params = Object.fromEntries(new URLSearchParams(query))

  const fetchReferences = async ({pageParam = 1}) => {
    let qs = `pageNumber=${pageParam}&pageSize=16`

    if (params.keyword) {
      qs = qs + `&keyword=${params.keyword}`
    }

    if (params.majorid) {
      qs = qs + `&majorId=${params.majorid}`
    }

    if (params.courseid) {
      qs = qs + `&courseid=${params.courseid}`
    }

    const {data} = await getReferences(qs)
    return data
  }
  const chunk = (array: ReferenceType[]): ReferenceType[][] => {
    const size = 4
    const result: ReferenceType[][] = []
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size))
    }
    return result
  }

  const {data, error, fetchNextPage, isFetching, isFetchingNextPage, status} = useInfiniteQuery({
    queryKey: [QUERIES.REFERENCE_LIST, stringifyMaterialRequestQuery(params)],
    queryFn: fetchReferences,
    getNextPageParam: (lastPage) => {
      if (lastPage.nextPage === null) return undefined

      return lastPage.nextPage
    },
    cacheTime: 0,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  if (status === 'loading') {
    return <ReferenceCardLoading />
  }

  if (status === 'error') {
    // @ts-ignore
    return <p>{error.message}</p>
  }

  if (data?.pages[0].totalRecords === 0) {
    return <ReferenceNotFound />
  }

  return (
    <>
      {data?.pages?.map((group, i) => (
        <React.Fragment key={i}>
          {chunk(group.records).map((data, j) => (
            <Parent key={j}>
              {data.map((item, k) => (
                <div className='col-12 col-sm-12 col-md-3 col-lg-3' key={k}>
                  <ReferenceCard
                    title={item.title}
                    filename={item.fileName}
                    description={`${item.author}`}
                    unique={item.uniqueId}
                    tagsName={item.tagsName ?? []}
                    abstractUnique={item.abstractUniqueId}
                    publicationYear={item.publicationYear}
                    publisher={item.publisher}
                    fileId={item.fileId}
                    data={item}
                  />
                </div>
              ))}
            </Parent>
          ))}
        </React.Fragment>
      ))}
      <div className='text-center'>
        {/* {hasNextPage && (
          <button
            className='btn btn-primary btn-sm'
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}
          >
            {isFetchingNextPage ? 'Loading more...' : hasNextPage ? 'Load More' : null}
          </button>
        )} */}
        <div>{isFetching && !isFetchingNextPage ? <IconLoader /> : null}</div>
      </div>
    </>
  )
}

type ParentProps = {
  children?: React.ReactNode
}

const Parent: FC<ParentProps> = ({children}) => {
  return <div className='row g-6 g-xl-9 mb-6 mb-xl-9'>{children}</div>
}

export default ReferenceViewScroll
