import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_BASE_API_URL
const WP_URL = `${API_URL}/wp`

const getUrlWithToken = (): Promise<Response<string>> => {
  return axios.get(`${WP_URL}`).then((d: AxiosResponse<Response<string>>) => d.data)
}

export {getUrlWithToken}
