import {useQuery} from 'react-query'
import {KTCard} from '../../../../_metronic/helpers'
import {CourseFormGeneral} from './components/CourseFormGeneral'
import {CourseFormSession} from './components/CourseFormSession'
import CourseFormQuiz from './components/CourseFormQuiz'
import {getCourseById} from '../course-list/core/_requests'
import {useLocation, useParams} from 'react-router-dom'
import {useEffect, useState} from 'react'
import CourseFormAssignment from './components/CourseFormAssignment'
import {PageTitle} from '../../../../_metronic/layout/core'
import {showToastError} from '../../auth'
import clsx from 'clsx'
import usePreventScrollOnNumberInput from '../../../utils/input'
import CourseFormMaterial from './components/CourseFormMaterial'

function CourseForm() {
  usePreventScrollOnNumberInput()
  let {id} = useParams()
  const location = useLocation()
  const [selectedTab, setSelectedTab] = useState(0)

  const {isLoading, data, refetch} = useQuery(
    'course-by-id',
    async () => {
      try {
        const response = await getCourseById(id)
        return response
      } catch (ex: any) {
        showToastError(ex.message)
      }
    },
    {enabled: true, refetchOnWindowFocus: false}
  )

  // useEffect(() => {
  //   const params = new URLSearchParams(location.search)
  //   const tab = params.get('tab')
  //   if (tab) {
  //     console.log(tab)
  //   }
  // }, [location.search]) // Add location.search to the dependency array

  const subMenuClickHandler = (idx, name) => {
    // window.location.hash = name
    window.history.replaceState({}, '', '#' + name)
    setSelectedTab(idx)
  }

  const navMenus = [
    {
      code: 'general',
      name: 'General',
    },
    {
      code: 'sessions',
      name: 'Sessions',
      hide: !data?.showSessionTab,
    },
    {
      code: 'assignments',
      name: 'Assignments',
    },
    {
      code: 'quizzes',
      name: 'Quizzes',
      hide: !data?.showQuiz,
    },
    {
      code: 'materials',
      name: 'Materials',
      hide: !data?.showMaterialTab,
    },
  ]

  useEffect(() => {
    const handleHashChange = () => {
      const newHash = window.location.hash
      if (newHash) {
        const _hash = newHash.replace('#', '')
        const itemIdx = navMenus.findIndex((f) => f.code === _hash)
        console.log(itemIdx)

        setSelectedTab(itemIdx < 0 ? 0 : itemIdx)
      }
    }

    handleHashChange()
  }, [])

  return (
    <>
      {data ? (
        <PageTitle showBackButton={true} description={data?.semesterName ?? '-'}>
          {`${data.degreeName}-${data.semesterMajorName} | ${data?.subjectName}`}
        </PageTitle>
      ) : (
        <PageTitle showBackButton={true} description={'-'}>
          {`-`}
        </PageTitle>
      )}

      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            <div className='card-body pb-3 pt-3'>
              <div className='tab-nav-header'>
                <ul
                  className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-5 fw-semibold mb-n2 scroller-navbar '
                  role='tablist'
                >
                  {navMenus.map((m, idx) => {
                    return (
                      !m.hide && (
                        <li key={idx} className='nav-item' role='presentation'>
                          <a
                            className={clsx(
                              'nav-link text-active-danger pb-4 border-active-danger border-hover-danger',
                              selectedTab === idx && ' active'
                            )}
                            onClick={() => subMenuClickHandler(idx, m.code)}
                            data-bs-toggle='tab'
                            href={`#tab_${m.code}`}
                            aria-selected='false'
                            tabIndex={-1}
                            role='tab'
                          >
                            {m.name}
                          </a>
                        </li>
                      )
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className='tab-content mt-5' id='myTabContent'>
            <div
              className={'tab-pane fade' + (selectedTab === 0 && 'show active')}
              id='tab_general'
              role='tabpanel'
            >
              <KTCard>
                <CourseFormGeneral course={data} refetch={refetch} />
              </KTCard>
            </div>
            <div
              className={'tab-pane fade' + (selectedTab === 1 && 'show active')}
              id='tab_sessions'
              role='tabpanel'
            >
              {id && <CourseFormSession id={+id} initialSessions={data?.courseSessions} />}
            </div>
            <div
              className={'tab-pane fade' + (selectedTab === 2 && 'show active')}
              id='tab_assignments'
              role='tabpanel'
            >
              {id && <CourseFormAssignment id={+id} initialAssignments={data?.courseAssignments} />}
            </div>
            <div
              className={'tab-pane fade' + (selectedTab === 3 && 'show active')}
              id='tab_quizzes'
              role='tabpanel'
            >
              {id && <CourseFormQuiz id={+id} initialQuizzes={data?.courseQuizzes} />}
            </div>
            <div
              className={'tab-pane fade' + (selectedTab === 4 && 'show active')}
              id='tab_materials'
              role='tabpanel'
            >
              {id && <CourseFormMaterial id={+id} />}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CourseForm
