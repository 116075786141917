import React, {FC, useState} from 'react'
import {Modal, Button} from 'react-bootstrap'
import LoadingButtonWrapper from './LoadingButtonWrapper'

type Prop = {
  show: boolean
  handleClose: any
  handleConfirm: any
  body?: string | any | null
  name: string | null
  isLoading?: boolean
  error?: any
}

const Confirm: FC<Prop> = ({
  show,
  handleClose,
  handleConfirm,
  name = null,
  body = null,
  isLoading = false,
  error = null,
}) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{name ?? 'Confirm Delete'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error ? (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{error}</div>
          </div>
        ) : (
          ''
        )}
        <div>{body ?? 'Are you sure you want to delete item?'}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose} disabled={isLoading}>
          Cancel
        </Button>
        <LoadingButtonWrapper isLoading={isLoading}>
          <button className='btn btn-warning' onClick={handleConfirm} disabled={isLoading}>
            {isLoading && <i className='fa-solid fa-spinner fa-spin-pulse'></i>} Confirm
          </button>
        </LoadingButtonWrapper>
      </Modal.Footer>
    </Modal>
  )
}

export {Confirm}
