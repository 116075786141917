import {useEffect, useState} from 'react'
import SemesterSelect from '../../../../../classes/components/SemesterSelect'
import {StudentAttendanceListSearch} from './StudentAttendanceListSearch'
import {StudentAttendanceListToolbar} from './StudentAttendanceListToolbar'
import {ISemester} from '../../../../../../models/ISemester'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {initialQueryState} from '../../../../../../../_metronic/helpers'

const StudentAttendanceListHeader = () => {
  const [selectedSemester, setSelectedSemester] = useState<ISemester>()
  const {refetch} = useQueryResponse()
  const {updateState} = useQueryRequest()

  useEffect(() => {
    updateState({
      ...initialQueryState,
      filter: {
        semesterId: selectedSemester?.id,
        semesterName: selectedSemester?.name,
      },
    })
    setTimeout(() => {
      refetch()
    }, 500)
  }, [selectedSemester])

  return (
    <div className='card-header border-0 pt-6'>
      <div className='d-flex align-items-center'>
        <SemesterSelect
          selected={selectedSemester}
          setSelected={setSelectedSemester}
          className='w-250px me-4'
        />
        <StudentAttendanceListSearch />
      </div>
      <div className='card-toolbar'>
        <StudentAttendanceListToolbar />
      </div>
    </div>
  )
}

export {StudentAttendanceListHeader}
